import { createSelector } from "reselect";

import * as _ from "lodash";

const getToast = (state) => state.client.toast;
const getDataAccount = (state) => state.client.dataAccount;
const getSetStepRes = (state) => state.client.setStepRes;

/*****************************************  MAKE GET *************************************************/

export const makeGetToast = () => createSelector([getToast], (toast) => toast);
export const makeGetDataAccount = () =>
  createSelector([getDataAccount], (checkAccount) => checkAccount);
export const makeGetSetStepRes = () =>
  createSelector([getSetStepRes], (setStepRes) => setStepRes);
