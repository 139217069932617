import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import { useLocation } from "react-router-dom";
import queryString from "query-string";

import Logo from "../../assets/img/casc_logo.png";
import { formatDate, formatSoChungTu, formatTime } from "../../util";

function PreviewPage(props) {
  const location = useLocation();

  const [paramSearch, setParamSearch] = React.useState(null);
  const [accountCode, setAccountCode] = React.useState([]);

  React.useEffect(() => {
    let params = queryString.parse(location.search);
    console.log(params);
    setParamSearch(params);
    const _accCode = ("000000" + (params?.accCode || "")).substr(-6);
    setAccountCode([..._accCode]);
  }, []);

  console.log(accountCode);

  return (
    <div className="app-body">
      <main className="main">
        <Container fluid>
          <Container>
            <Row>
              <Col lg="10" className="offset-lg-1 px-0">
                <div className="content pt180 fz-13">
                  <div
                    className="preview-header"
                    style={{ borderBottom: "1px solid #000" }}
                  >
                    <a href="https://casc.vn/" target="_blank">
                      <img src={Logo} alt="" />
                    </a>
                    <div className="d-grid w-100">
                      <span className="header-first">
                        Hợp đồng mở tài khoản chứng khoán
                      </span>
                      <span className="header-second font-weight-bold">
                        (Kiêm Giấy yêu cầu mở tài khoản giao dịch chứng
                        khoán/giao dịch ký quỹ chứng khoán bằng phương tiện điện
                        tử tại CASC)
                      </span>
                    </div>
                  </div>
                  <div className="yt-online-form-step mt-0 mb-5 w-100">
                    <div className="w-100 d-flex mb-3 mt-4 pr-2 align-items-center justify-content-end">
                      <span className="font-weight-bold">
                        Số Tài Khoản Chứng Khoán:
                      </span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <div className="group-number">
                        <span>1</span>
                        <span>1</span>
                        <span>9</span>
                        <span>C</span>
                        <span>{accountCode[0]}</span>
                        <span>{accountCode[1]}</span>
                        <span>{accountCode[2]}</span>
                        <span>{accountCode[3]}</span>
                        <span>{accountCode[4]}</span>
                        <span>{accountCode[5]}</span>
                      </div>
                    </div>
                    <p className="fs-i">
                      Vào ngày {formatDate(new Date(), "/", "dmy")}, Khách Hàng
                      và CASC giao kết Hợp Đồng Mở Tài Khoản Chứng Khoán và chịu
                      sự ràng buộc của Bộ Điều Khoản Và Điều Kiện Của Hợp Đồng
                      Mở Tài Khoản Chứng Khoán đính kèm Hợp Đồng này và là một
                      phần không thể tách rời của Hợp Đồng Mở Tài Khoản Chứng
                      Khoán này.
                    </p>
                    <div className="d-flex flex-column account-form mt-0">
                      <p className="my-0 fz-16">
                        <b>
                          I.
                          <span style={{ marginLeft: "15px" }}>KHÁCH HÀNG</span>
                        </b>{" "}
                        (sau đây gọi tắt là "<b>Khách hàng</b>")
                      </p>
                      <div
                        className="d-grid w-100"
                        style={{
                          gridTemplateColumns: "auto auto auto auto",
                          gap: "5px",
                          marginLeft: "25px",
                        }}
                      >
                        <div
                          className="text-left"
                          style={{ gridColumn: "1/3" }}
                        >
                          <span>Tên Khách Hàng: </span>
                          <span>{paramSearch?.fullName}</span>
                        </div>
                        <div
                          className="text-left"
                          style={{ gridColumn: "3/5" }}
                        >
                          <span>Loại hình: </span>
                          <label>
                            <input
                              type="checkbox"
                              className="mr-2"
                              checked={paramSearch?.personal === "1"}
                            />
                            Cá nhân;
                          </label>
                          <label className="ml-5">
                            <input
                              type="checkbox"
                              className="mr-2"
                              checked={paramSearch?.personal !== "1"}
                            />
                            Tổ chức;
                          </label>
                        </div>
                        <div
                          className="text-left"
                          style={{ gridColumn: "1/3" }}
                        >
                          <span>Giấy tờ chứng thực cá nhân: </span>
                          <label className="mr-5">
                            <input
                              type="checkbox"
                              className="mr-2"
                              checked={paramSearch?.personal === "1"}
                            />
                            Giấy CMND/ Thẻ CCCD
                          </label>
                        </div>
                        <div
                          className="text-left"
                          style={{ gridColumn: "3/5" }}
                        >
                          <label>
                            <input
                              type="checkbox"
                              className="mr-2"
                              checked={paramSearch?.personal === "0"}
                            />
                            Giấy chứng nhận ĐKDN
                          </label>
                          <label className="ml-5">
                            <input
                              type="checkbox"
                              className="mr-2"
                              checked={paramSearch?.personal === "2"}
                            />
                            Khác
                          </label>
                        </div>
                        <div className="text-left">
                          <span>Số: </span>
                          <span>{paramSearch?.cardId}</span>
                        </div>
                        <div className="text-left">
                          <span>Ngày cấp: </span>
                          <span>{paramSearch?.issueDate}</span>
                        </div>
                        <div className="text-left">
                          <span>Nơi cấp: </span>
                          <span>{paramSearch?.issuePlace}</span>
                        </div>
                        <div className="text-left">
                          <span>Ngày hết hạn: </span>
                          <span>{paramSearch?.issueExpire}</span>
                        </div>
                        <div className="text-left">
                          <span>Ngày sinh: </span>
                          <span>{paramSearch?.birthday}</span>
                        </div>
                        <div className="text-left">
                          <span>Nơi sinh: </span>
                          <span>{paramSearch?.birthPlace}</span>
                        </div>
                        <div className="text-left">
                          <span>Giới tính: </span>
                          <label>
                            <input
                              type="checkbox"
                              className="mr-2"
                              checked={paramSearch?.gender === "M"}
                            />
                            Nam;
                          </label>
                          <label className="ml-5">
                            <input
                              type="checkbox"
                              className="mr-2"
                              checked={paramSearch?.gender === "F"}
                            />
                            Nữ;
                          </label>
                        </div>
                        <div className="text-left">
                          <span>Quốc tịch: </span>
                          <span>VIET NAM</span>
                        </div>
                        <div
                          className="text-left"
                          style={{ gridColumn: "1/5" }}
                        >
                          <span>Địa chỉ thường trú/Trụ sở chính: </span>
                          <span>{paramSearch?.address}</span>
                        </div>
                        <div
                          className="text-left"
                          style={{ gridColumn: "1/5" }}
                        >
                          <span>Địa chỉ liên hệ: </span>
                          <span>{paramSearch?.contactAddress}</span>
                        </div>
                        <div className="text-left">
                          <span>Điện thoại cố định: </span>
                          <span></span>
                        </div>
                        <div className="text-left">
                          <span>Fax: </span>
                          <span></span>
                        </div>
                        <div className="text-left">
                          <span>Mã số thuế: </span>
                          <span>{paramSearch?.numTax}</span>
                        </div>
                        <span />
                        <div className="text-left">
                          <span>Di động: </span>
                          <span>{paramSearch?.mobile}</span>
                        </div>
                        <div className="text-left">
                          <span>Email: </span>
                          <span>{paramSearch?.email}</span>
                        </div>
                        <span />
                        <span />
                        <div
                          className="text-left"
                          style={{ gridColumn: "1/3" }}
                        >
                          <span>Người đại diện theo pháp luật: </span>
                          <span></span>
                        </div>
                        <div className="text-left">
                          <span>Chức vụ: </span>
                          <span></span>
                        </div>
                        <span />
                        <div
                          className="text-left"
                          style={{ gridColumn: "1/3" }}
                        >
                          <span>Số CMND/CCCD/Hộ chiếu: </span>
                          <span></span>
                        </div>
                        <div className="text-left">
                          <span>Ngày cấp: </span>
                          <span></span>
                        </div>
                        <div className="text-left">
                          <span>Nơi cấp: </span>
                          <span></span>
                        </div>
                      </div>
                      <p className="mb-0 fz-16 mt-3">
                        <b>
                          II.
                          <span style={{ marginLeft: "15px" }}>
                            CÔNG TY CỔ PHẦN CHỨNG KHOÁN THỦ ĐÔ
                          </span>
                        </b>{" "}
                        (sau đây gọi tắt là "<b>CASC</b>")
                      </p>
                      <p className="mb-0 mt-1">
                        Trụ sở chính: Số 2A Đại Cồ Việt, phường Lê Đại Hành,
                        quận Hai Bà Trưng, thành phố Hà Nội.
                      </p>
                      <p className="my-0">
                        <span>
                          Điện thoại: <a href="tel:1900633059">1900633059</a>
                        </span>
                        <span className="ml-5">Fax: +84 (0) 243 5771741</span>
                        <span className="ml-5">
                          Website:{" "}
                          <a href="https://casc.vn" target={"_blank"}>
                            https://casc.vn
                          </a>
                        </span>
                      </p>
                      <p className="my-0">
                        Giấy phép hoạt động kinh doanh chứng khoán số:{" "}
                        31/UBCK-GPHĐKD do Ủy ban Chứng khoán Nhà nước cấp ngày
                        21/12/2006
                      </p>
                      <p className="my-0">
                        Giấy chứng nhận đăng ký doanh nghiệp số: 0102116483 do
                        Sở Kế hoạch và Đầu tư Thành phố Hà Nội cấp ngày
                        19/12/2006, đăng ký thay đổi lần thứ 11 ngày 12/11/2021
                      </p>
                      <p className="my-0">
                        <span>
                          Người đại diện: (Bà) <b>Trần Thanh Hương</b>
                        </span>
                        <span className="ml-2">
                          CCCD: 001185038333 Cấp ngày: 20/04/2021 Nơi cấp: Cục
                          CS QLHC về TTXH
                        </span>
                      </p>
                      <p className="my-0">
                        <span className="">
                          Chức vụ: <b>Phó Giám đốc Tài chính</b>
                        </span>
                        <span className="ml-2">
                          Văn bản ủy quyền số: 10/2024/GUQ-CASC ngày 16/05/2024
                        </span>
                      </p>
                      <p className="mt-1 mb-0">
                        Khách Hàng đăng ký và đề nghị CASC mở các tài khoản giao
                        dịch chứng khoán nêu tại Mục A của Hợp Đồng Mở Tài Khoản
                        Chứng Khoán này bằng phương tiện điện tử tại CASC. Khách
                        Hàng xác nhận đã được tư vấn, hiểu rõ và đồng ý với nội
                        dung của Hợp Đồng Mở Tài Khoản Chứng Khoán này, đã tìm
                        hiểu đầy đủ về các hình thức giao dịch chứng khoán và
                        đồng ý với{" "}
                        <span className="fs-i">
                          “Bộ Điều Khoản Và Điều Kiện Của Hợp Đồng Mở Tài Khoản
                          Chứng Khoán”
                        </span>{" "}
                        (Bộ T&C) đính kèm Hợp Đồng Mở Tài Khoản Chứng Khoán này.
                      </p>
                      <p className="mt-1 mb-0">
                        Khách Hàng hoàn toàn ý thức được những rủi ro có thể
                        phát sinh từ việc giao dịch chứng khoán và đồng ý chịu
                        mọi rủi ro liên quan đến hình thức đầu tư và các giao
                        dịch chứng khoán mà mình lựa chọn.
                      </p>
                      <p className="mt-1 mb-0">
                        Hợp Đồng Mở Tài Khoản Chứng Khoán này là hợp đồng mở tài
                        khoản chứng khoán điện tử, được Khách Hàng ký kết bằng
                        Chữ ký điện tử. Khách Hàng đồng ý rằng Chữ ký điện tử
                        của Khách Hàng được tạo lập dưới dạng từ, chữ, số, ký
                        hiệu … được CASC xác nhận thông qua việc Khách Hàng sử
                        dụng các Yếu Tố Bảo Mật (tùy từng trường hợp là Tên Đăng
                        Nhập, Mật Khẩu, mã PIN của Khách Hàng) để đăng nhập vào
                        Hệ thống giao dịch điện tử của CASC.
                      </p>
                      <p className="mt-1 mb-0">
                        Hợp Đồng Mở Tài Khoản Chứng Khoán này được coi là đã ký
                        kết bởi Khách Hàng khi CASC xác thực Khách Hàng và xác
                        nhận sự chấp thuận của Khách Hàng với Hợp Đồng Mở Tài
                        Khoản Chứng Khoán này thông qua xác thực các Yếu Tố Bảo
                        Mật và quá trình Khách Hàng thực hiện các hành động để
                        hoàn tất Hợp Đồng Mở Tài Khoản Chứng Khoán này.
                      </p>
                      <p className="mt-1 mb-0">
                        Hợp Đồng Mở Tài Khoản Chứng Khoán này có hiệu lực kể từ
                        ngày ký và thay thế các văn bản, thỏa thuận về việc mở
                        tài khoản chứng khoán trước đây giữa Khách Hàng và CASC,
                        ngoại trừ các dịch vụ Khách Hàng đăng ký giao dịch điện
                        tử trước đó vẫn giữ nguyên hiệu lực.
                      </p>
                      <div
                        className="d-grid w-100 mt-3"
                        style={{
                          gridTemplateColumns: "auto auto",
                          gap: "5px",
                          marginBottom: "50px",
                        }}
                      >
                        <div className="text-center">
                          <p className="my-0 font-weight-bold">KHÁCH HÀNG</p>
                          <p
                            className="fs-i fz-12 my-0"
                            style={{ lineHeight: "24px" }}
                          >
                            Ký Hợp Đồng Mở Tài Khoản Chứng Khoán; số chứng từ{" "}
                            <br />
                            {formatSoChungTu(paramSearch?.accCode)} thời gian{" "}
                            {formatTime()} ngày{" "}
                            {formatDate(new Date(), "/", "dmy")}
                          </p>
                          <p className="mt-5">{paramSearch?.fullName}</p>
                        </div>
                        <div className="text-center">
                          <p className="my-0 font-weight-bold">ĐẠI DIỆN CASC</p>
                          <p
                            className="my-0 font-weight-bold"
                            style={{ lineHeight: "24px" }}
                          >
                            PHÓ GIÁM ĐỐC TÀI CHÍNH
                          </p>
                          <p
                            className="fs-i fz-12 my-0 font-weight-bold"
                            style={{ lineHeight: "24px" }}
                          >
                            (Đã ký)
                          </p>
                          <p className="mt-5">Trần Thanh Hương</p>
                        </div>
                      </div>
                    </div>
                    {/* <table className="table table-bordered table-fix">
                      <tbody>
                        <tr>
                          <td
                            colSpan={2}
                            className=" text-center fz-13 font-weight-bold"
                          >
                            Chữ ký mẫu của Khách Hàng
                          </td>
                          <td className=" text-center fz-13">
                            <b>Nhân viên tiếp nhận</b>
                            <br />
                            <small className="fs-i">(ký, ghi rõ họ tên)</small>
                          </td>
                          <td className="text-center fz-13">
                            <b>Nhân viên mở tài khoản</b>
                            <br />
                            <small className="fs-i">(ký, ghi rõ họ tên)</small>
                          </td>
                          <td className="text-center fz-13">
                            <b>Kiểm soát</b>
                            <br />
                            <small className="fs-i">(ký, ghi rõ họ tên)</small>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-13 font-weight-bold">
                            <span style={{ textDecoration: "underline" }}>
                              Chữ ký 1
                            </span>
                          </td>
                          <td className="text-center fz-13 font-weight-bold">
                            <span style={{ textDecoration: "underline" }}>
                              Chữ ký 2
                            </span>
                          </td>
                          <td className="text-center pb-5">
                            <small className="fs-i">
                              Tôi xác nhận đã đối chiếu thông tin của Khách
                              Hàng. Khách Hàng trực tiếp ký Hợp đồng này và các
                              giấy tờ liên quan trước sự chứng kiến của tôi.
                            </small>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table> */}

                    <table className="table table-bordered mt-3">
                      <tbody>
                        {/* TODO: A. */}
                        <tr>
                          <td className="bg-casc py-1">A.</td>
                          <td className="bg-casc py-1">
                            ĐĂNG KÝ MỞ TÀI KHOẢN CHỨNG KHOÁN
                          </td>
                        </tr>
                        <tr>
                          <td rowSpan={2}></td>
                          <td>
                            <span className="fz-12 fonr-weight-bold">
                              Khách Hàng tại đây đăng ký và đề nghị CASC mở bổ
                              sung các tài khoản chứng khoán như sau:
                            </span>{" "}
                            <br />
                            <small className="fs-i">
                              (Tài Khoản Chứng Khoán số 119C
                              {accountCode.join("")} - 1 được tự động kích hoạt
                              trên cơ sở Hợp Đồng Mở Tài Khoản Chứng Khoán của
                              Khách Hàng)
                            </small>
                          </td>
                        </tr>
                        <tr>
                          <div
                            className="d-grid w-100"
                            style={{
                              gridTemplateColumns: "50px auto auto auto",
                            }}
                          >
                            <div
                              className="text-center fz-12 text-uppercase font-weight-bold py-1"
                              style={{
                                border: "1px solid",
                                borderWidth: "0 1px 0 0",
                              }}
                            >
                              STT
                            </div>
                            <div
                              className="text-center fz-12 text-uppercase font-weight-bold py-1"
                              style={{
                                border: "1px solid",
                                borderWidth: "0 1px 0 0",
                              }}
                            >
                              Loại tài khoản
                            </div>
                            <div
                              className="text-center fz-12 text-uppercase font-weight-bold py-1"
                              style={{
                                border: "1px solid",
                                borderWidth: "0 1px 0 0",
                              }}
                            >
                              Số tài khoản
                            </div>
                            <div className="text-center fz-12 text-uppercase font-weight-bold p-1">
                              Ký xác nhận
                            </div>
                            <div
                              className="text-center fz-12 py-1"
                              style={{
                                border: "1px solid",
                                borderWidth: "1px 1px 0 0",
                              }}
                            >
                              1.
                            </div>
                            <div
                              className="fz-12 p-1"
                              style={{
                                border: "1px solid",
                                borderWidth: "1px 1px 0 0",
                              }}
                            >
                              Tài Khoản Giao Dịch Ký Quỹ Chứng Khoán <br />
                              (Là tài khoản được dùng để thực hiện các giao dịch
                              ký quỹ chứng khoán)
                            </div>
                            <div
                              className="d-flex justify-content-center align-items-center p-1"
                              style={{
                                border: "1px solid",
                                borderWidth: "1px 1px 0 0",
                              }}
                            >
                              <div className="group-number">
                                <span>1</span>
                                <span>1</span>
                                <span>9</span>
                                <span>C</span>
                                <span>{accountCode[0]}</span>
                                <span>{accountCode[1]}</span>
                                <span>{accountCode[2]}</span>
                                <span>{accountCode[3]}</span>
                                <span>{accountCode[4]}</span>
                                <span>{accountCode[5]}</span>
                              </div>
                              &nbsp;-&nbsp;
                              <span
                                style={{
                                  padding: "2px 10px",
                                  border: "1px solid",
                                  fontWeight: "600",
                                }}
                              >
                                6
                              </span>
                              {/* {
                                paramSearch?.isMargin != 1 &&
                                <span
                                  style={{
                                    padding: "11px 10px",
                                    border: "1px solid",
                                    fontWeight: "600",
                                  }}
                                ></span>
                              } */}
                            </div>
                            <div
                              className="text-left fz-12 p-1 fs-i"
                              style={{
                                border: "1px solid",
                                borderWidth: "1px 0 0 0",
                              }}
                            >
                              {paramSearch?.isMargin == 1 && (
                                <>
                                  Ký Hợp Đồng Mở Tài Khoản Chứng Khoán; số chứng
                                  từ {formatSoChungTu(paramSearch?.accCode)}{" "}
                                  thời gian {formatTime()} ngày{" "}
                                  {formatDate(new Date(), "/", "dmy")}
                                </>
                              )}
                            </div>
                          </div>
                        </tr>
                        {/* TODO: B. */}
                        <tr>
                          <td className="bg-casc py-1">B.</td>
                          <td className="bg-casc py-1">
                            THÔNG TIN GIAO DỊCH KÝ QUỸ CHỨNG KHOÁN DÀNH CHO
                            KHÁCH HÀNG TỔ CHỨC
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1">1.</td>
                          <td className="fz-12 py-1">
                            Thông tin người có thẩm quyền ký kết, phê duyệt hạn
                            mức vay:
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1"></td>
                          <td className="fz-12 p-0">
                            <div
                              className="d-grid w-100"
                              style={{
                                gridTemplateColumns: "25% 25% 25% auto",
                              }}
                            >
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                Họ và tên:……………………………
                              </div>
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                Chức vụ:……………………………
                              </div>
                              <div
                                className="px-2 py-1 text-left"
                                style={{ gridColumn: "3/5" }}
                              >
                                Văn bản ủy quyền số:…………………………………………………………
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1">2.</td>
                          <td className="fz-12 py-1">
                            Khách Hàng lựa chọn một trong hai mục dưới đây (chỉ
                            được chọn 1 hoặc 2):
                          </td>
                        </tr>
                        <tr>
                          <td
                            rowSpan={5}
                            className="text-center fz-12 py-1"
                          ></td>
                          <td className="fz-12 py-1">
                            <label className="mb-0">
                              <input
                                type="checkbox"
                                readOnly
                                className="mr-1"
                              />
                              Doanh nghiệp không quy định bắt buộc phê duyệt hạn
                              mức vay khi sử dụng các sản phẩm giao dịch ký quỹ
                              chứng khoán.
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td className="fz-12 py-1">
                            <label className="mb-0">
                              <input
                                type="checkbox"
                                readOnly
                                className="mr-1"
                              />
                              Doanh nghiệp có các quy định khi sử dụng các sản
                              phẩm giao dịch ký quỹ chứng khoán:
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td className="fz-12 py-1 pl-4">
                            (a){" "}
                            <span className="ml-3">
                              Hạn mức được ký kết, phê duyệt của người đại
                              diện/ủy quyền:..............................
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td className="fz-12 py-1 pl-4">
                            (b){" "}
                            <span className="ml-3">
                              Hạn mức cần bổ sung thêm nghị quyết của cấp có
                              thẩm quyền của doanh nghiệp (Đại hội đồng cổ
                              đông/Hội đồng thành viên/Chủ tịch công ty/Hội đồng
                              quản trị/Chủ tịch hội đồng quản
                              trị..............................
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td className="fz-12 py-1 pl-4">
                            (c){" "}
                            <span className="ml-3">
                              Tỷ lệ % giá trị đầu tư/tổng tài sản mà người đại
                              diện/người ủy quyền được ký kết theo quy định của
                              doanh nghiệp:..............................
                            </span>
                          </td>
                        </tr>
                        {/* TODO: C. */}
                        <tr>
                          <td className="bg-casc py-1">C.</td>
                          <td className="bg-casc py-1">
                            THÔNG TIN NGƯỜI ĐƯỢC ỦY QUYỀN GIAO DỊCH (NẾU CÓ)
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1">1.</td>
                          <td className="fz-12 py-1">
                            Họ và
                            tên:..........................................................................................
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1">2.</td>
                          <td className="fz-12 p-0">
                            <div
                              className="d-grid w-100"
                              style={{
                                gridTemplateColumns: "25% 25% 25% auto",
                              }}
                            >
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                Ngày sinh:……………………………
                              </div>
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                Nơi sinh:……………………………
                              </div>
                              <div className="px-2 py-1 text-left">
                                Gới tính:
                                <label className="mb-0 pl-3">
                                  <input
                                    type="checkbox"
                                    readOnly
                                    className="mr-2"
                                  />
                                  Nam
                                </label>
                              </div>
                              <div className="px-2 py-1 text-left">
                                <label className="mb-0">
                                  <input
                                    type="checkbox"
                                    readOnly
                                    className="mr-2"
                                  />
                                  Nữ
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1">3.</td>
                          <td className="fz-12 py-1">
                            Giấy tờ chứng thực cá nhân:
                            <label className="mb-0 pl-3">
                              <input
                                type="checkbox"
                                readOnly
                                className="mr-2"
                              />
                              Giấy chứng minh nhân dân;
                            </label>
                            <label className="mb-0 pl-3">
                              <input
                                type="checkbox"
                                readOnly
                                className="mr-2"
                              />
                              Thẻ căn cước công dân;
                            </label>
                            <label className="mb-0 pl-3">
                              <input
                                type="checkbox"
                                readOnly
                                className="mr-2"
                              />
                              Khác;
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1"></td>
                          <td className="fz-12 p-0">
                            <div
                              className="d-grid w-100"
                              style={{
                                gridTemplateColumns: "25% 25% 25% auto",
                              }}
                            >
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                Số:……………………………
                              </div>
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                Ngày cấp:……………………………
                              </div>
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                Nơi cấp:……………………………
                              </div>
                              <div className="px-2 py-1 text-left">
                                Ngày hết hạn:……………………………
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1">4.</td>
                          <td className="fz-12 py-1">
                            Địa chỉ liên
                            hệ:..........................................................................................
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1">5.</td>
                          <td className="fz-12 p-0">
                            <div
                              className="d-grid w-100"
                              style={{
                                gridTemplateColumns: "25% 25% 25% auto",
                              }}
                            >
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                Điện thoại:……………………………
                              </div>
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                Di động:……………………………
                              </div>
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                Email:……………………………
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1">6.</td>
                          <td className="fz-12 py-1">
                            Phạm vi ủy quyền: Theo nội dung trên Giấy ủy quyền
                            Khách Hàng gửi cho CASC.
                          </td>
                        </tr>

                        {/* TODO: D. */}
                        <tr>
                          <td className="bg-casc py-1">D.</td>
                          <td className="bg-casc py-1">
                            THÔNG TIN CHỨNG KHOÁN VÀ KIẾN THỨC ĐẦU TƯ CỦA KHÁCH
                            HÀNG
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1">1.</td>
                          <td className="fz-12 p-0">
                            <div
                              className="d-grid w-100"
                              style={{
                                gridTemplateColumns: "25% 25% 25% auto",
                              }}
                            >
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                Mục tiêu đầu tư:
                              </div>
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                <label className="mb-0 pl-3">
                                  <input
                                    type="checkbox"
                                    readOnly
                                    className="mr-2"
                                  />
                                  Ngắn hạn
                                </label>
                              </div>
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                <label className="mb-0 pl-3">
                                  <input
                                    type="checkbox"
                                    readOnly
                                    className="mr-2"
                                  />
                                  Trung hạn
                                </label>
                              </div>
                              <div className="px-2 py-1 text-left">
                                <label className="mb-0 pl-3">
                                  <input
                                    type="checkbox"
                                    readOnly
                                    className="mr-2"
                                  />
                                  Dài hạn
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1">2.</td>
                          <td className="fz-12 p-0">
                            <div
                              className="d-grid w-100"
                              style={{
                                gridTemplateColumns: "25% 25% 25% auto",
                              }}
                            >
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                Mức độ chấp nhận rủi ro:
                              </div>
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                <label className="mb-0 pl-3">
                                  <input
                                    type="checkbox"
                                    readOnly
                                    className="mr-2"
                                  />
                                  Thấp
                                </label>
                              </div>
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                <label className="mb-0 pl-3">
                                  <input
                                    type="checkbox"
                                    readOnly
                                    className="mr-2"
                                  />
                                  Trung bình
                                </label>
                              </div>
                              <div className="px-2 py-1 text-left">
                                <label className="mb-0 pl-3">
                                  <input
                                    type="checkbox"
                                    readOnly
                                    className="mr-2"
                                  />
                                  Cao
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1">3.</td>
                          <td className="fz-12 p-0">
                            <div
                              className="d-grid w-100"
                              style={{
                                gridTemplateColumns: "25% 25% 25% auto",
                              }}
                            >
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                Hiểu biết về chứng khoán:
                              </div>
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                <label className="mb-0 pl-3">
                                  <input
                                    type="checkbox"
                                    readOnly
                                    className="mr-2"
                                  />
                                  Chưa có
                                </label>
                              </div>
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                <label className="mb-0 pl-3">
                                  <input
                                    type="checkbox"
                                    readOnly
                                    className="mr-2"
                                  />
                                  Hạn chế
                                </label>
                              </div>
                              <div className="px-2 py-1 text-left">
                                <label className="mb-0 pl-3">
                                  <input
                                    type="checkbox"
                                    readOnly
                                    className="mr-2"
                                  />
                                  Tốt
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1">4.</td>
                          <td className="fz-12 p-0">
                            <div
                              className="d-grid w-100"
                              style={{
                                gridTemplateColumns: "25% 25% 25% auto",
                              }}
                            >
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                Kinh nghiệm đầu tư:
                              </div>
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                <label className="mb-0 pl-3">
                                  <input
                                    type="checkbox"
                                    readOnly
                                    className="mr-2"
                                  />
                                  Chưa có
                                </label>
                              </div>
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                <label className="mb-0 pl-3">
                                  <input
                                    type="checkbox"
                                    readOnly
                                    className="mr-2"
                                  />
                                  Cổ phiếu
                                </label>
                              </div>
                              <div className="px-2 py-1 text-left">
                                <label className="mb-0 pl-3">
                                  <input
                                    type="checkbox"
                                    readOnly
                                    className="mr-2"
                                  />
                                  Trái phiếu
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1">5.</td>
                          <td className="fz-12 py-1">
                            Tài khoản chứng khoán tại công ty khác:
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1"></td>
                          <td className="fz-12 p-0">
                            <div
                              className="d-grid w-100"
                              style={{
                                gridTemplateColumns: "25% 25% 25% auto",
                              }}
                            >
                              <div
                                className="px-2 py-1 text-right"
                                style={{ borderRight: "1px solid" }}
                              >
                                Số tài khoản:
                              </div>
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                .................................
                              </div>
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                Tại công ty chứng khoán:
                              </div>
                              <div className="px-2 py-1 text-left">
                                .................................
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1"></td>
                          <td className="fz-12 p-0">
                            <div
                              className="d-grid w-100"
                              style={{
                                gridTemplateColumns: "25% 25% 25% auto",
                              }}
                            >
                              <div
                                className="px-2 py-1 text-right"
                                style={{ borderRight: "1px solid" }}
                              >
                                Số tài khoản:
                              </div>
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                .................................
                              </div>
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                Tại công ty chứng khoán:
                              </div>
                              <div className="px-2 py-1 text-left">
                                .................................
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1">6.</td>
                          <td className="fz-12 py-1">
                            Tên công ty đại chúng mà Khách Hàng nắm chức danh
                            quản lý (nếu
                            có):......................................
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1">7.</td>
                          <td className="fz-12 py-1">
                            Tên công ty đại chúng mà Khách Hàng là cổ đông lớn,
                            người có liên quan (nếu có):......................
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1">8.</td>
                          <td className="fz-12 py-1">
                            Tên công ty đại chúng mà Khách Hàng sở hữu từ 5% vốn
                            điều lệ trở lên (nếu có):..........................
                          </td>
                        </tr>
                        {/* TODO: E. */}
                        <tr>
                          <td className="bg-casc py-1">E.</td>
                          <td className="bg-casc py-1">
                            THÔNG TIN LIÊN QUAN ĐẾN GIAO DỊCH CHỨNG KHOÁN
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1">1.</td>
                          <td className="fz-12 p-0">
                            <div
                              className="d-grid w-100"
                              style={{ gridTemplateColumns: "50% 25% auto" }}
                            >
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                Loại chứng khoán giao dịch:
                              </div>
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                <label className="mb-0 pl-3">
                                  <input
                                    type="checkbox"
                                    readOnly
                                    className="mr-2"
                                  />
                                  Chứng khoán chưa niêm yết
                                </label>
                              </div>
                              <div className="px-2 py-1 text-left">
                                <label className="mb-0 pl-3">
                                  <input
                                    type="checkbox"
                                    readOnly
                                    className="mr-2"
                                  />
                                  Chứng khoán niêm yết
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1">2.</td>
                          <td className="fz-12 p-0">
                            <div
                              className="d-grid w-100"
                              style={{ gridTemplateColumns: "50% 25% auto" }}
                            >
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                Phương thức thông báo kết quả giao dịch hàng
                                ngày:
                              </div>
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                <label className="mb-0 pl-3">
                                  <input
                                    type="checkbox"
                                    readOnly
                                    className="mr-2"
                                  />
                                  Tại quầy
                                </label>
                              </div>
                              <div className="px-2 py-1 text-left">
                                <label className="mb-0 pl-3">
                                  <input
                                    type="checkbox"
                                    readOnly
                                    className="mr-2"
                                  />
                                  Qua tin nhắn SMS.
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1">3.</td>
                          <td className="fz-12 p-0">
                            <div
                              className="d-grid w-100"
                              style={{ gridTemplateColumns: "50% 25% auto" }}
                            >
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                Phương thức nhận sao kê hàng tháng:
                              </div>
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                <label className="mb-0 pl-3">
                                  <input
                                    type="checkbox"
                                    readOnly
                                    className="mr-2"
                                  />
                                  Tại quầy
                                </label>
                              </div>
                              <div className="px-2 py-1 text-left">
                                <label className="mb-0 pl-3">
                                  <input
                                    type="checkbox"
                                    readOnly
                                    className="mr-2"
                                  />
                                  Qua email.
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1">4.</td>
                          <td className="fz-12 py-1">
                            Phương thức nộp thuế thu nhập cá nhân: Cá nhân
                            chuyển nhượng chứng khoán nộp thuế theo thuế suất
                            0,1% trên giá chuyển nhượng chứng khoán từng lần
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1">3.</td>
                          <td className="fz-12 p-0">
                            <div
                              className="d-grid w-100"
                              style={{
                                gridTemplateColumns: "25% 25% 25% auto",
                              }}
                            >
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                Đăng ký thực hiện quyền mua:
                              </div>
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                <label className="mb-0 pl-3">
                                  <input
                                    type="checkbox"
                                    readOnly
                                    className="mr-2"
                                  />
                                  Tại quầy
                                </label>
                              </div>
                              <div
                                className="px-2 py-1 text-left"
                                style={{ borderRight: "1px solid" }}
                              >
                                <label className="mb-0 pl-3">
                                  <input
                                    type="checkbox"
                                    readOnly
                                    className="mr-2"
                                  />
                                  Qua điện thoại
                                </label>
                              </div>
                              <div className="px-2 py-1 text-left">
                                <label className="mb-0 pl-3">
                                  <input
                                    type="checkbox"
                                    readOnly
                                    className="mr-2"
                                  />
                                  Qua internet
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        {/* TODO: F. */}
                        <tr>
                          <td className="bg-casc py-1">F.</td>
                          <td className="bg-casc py-1">
                            THÔNG TIN BỔ SUNG CHO MỤC ĐÍCH TUÂN THỦ FATCA
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1"></td>
                          <td className="fz-12 py-1">
                            <label className="mb-0">
                              <input
                                type="checkbox"
                                readOnly
                                className="mr-2"
                              />
                              (a) Khách Hàng là công dân Hoa Kỳ hoặc đối tượng
                              cư trú tại Hoa Kỳ (1) hoặc là đối tượng kê khai
                              thuế tại Hoa Kỳ.
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1"></td>
                          <td className="fz-12 py-1">
                            <label className="mb-0">
                              <input
                                type="checkbox"
                                readOnly
                                className="mr-2"
                              />
                              (b) Khách Hàng không phải là công dân Hoa Kỳ hoặc
                              đối tượng cư trú tại Hoa Kỳ nhưng có dấu hiệu nhận
                              biết Hoa Kỳ (<b>2</b>).
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1"></td>
                          <td className="fz-12 py-1">
                            <label className="mb-0">
                              <input
                                type="checkbox"
                                readOnly
                                className="mr-2"
                              />
                              (c) Khách Hàng không phải là công dân Hoa Kỳ hoặc
                              đối tượng cư trú tại Hoa Kỳ, và không có dấu hiệu
                              nhận biết Hoa Kỳ.
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            Bằng cách tích chọn các ô trên, Khách Hàng xác nhận
                            những thông tin kê khai là đúng sự thật, đầy đủ và
                            chính xác. Khách Hàng cam kết sẽ thông báo tới CASC
                            ngay khi có sự thay đổi về các thông tin này. Khách
                            Hàng xác nhận sẽ tự tìm hiểu và chịu trách nhiệm về
                            các quy định có liên quan đến FATCA; CASC được miễn
                            trừ toàn bộ trách nhiệm liên quan và/hoặc xảy ra đối
                            với việc kể khai thông tin của Khách Hàng theo tờ
                            khai này.
                            <br />
                            Trường hợp Khách Hàng tích chọn là (a) hoặc (b):
                            Khách Hàng cam kết cung cấp bộ tài liệu FATCA bao
                            gồm mẫu đơn W9 hoặc W8-Ben và/hoặc các tài liệu khác
                            chứng minh theo yêu cầu của CASC trong vòng 90 ngày
                            kể từ ngày ký Các Hợp Đồng này. Nếu Khách Hàng không
                            cung cấp bộ tài liệu FATCA trong vòng 90 ngày, CASC
                            có thể phân loại Khách Hàng như là chủ tài khoản bất
                            hợp tác và có thể phong tỏa hay thanh lý (các) tài
                            khoản của Khách Hàng tại CASC.
                            <br />
                            Khách Hàng đồng ý rằng CASC có thể khấu trừ từ (các)
                            tài khoản của Khách Hàng các khoản tiền phù hợp với
                            yêu cầu của FATCA mà có thể được cụ thể hóa bởi các
                            Luật áp dụng, quy định, thỏa thuận, hướng dẫn hoặc
                            chỉ thị mang tính pháp lý.
                            <br />
                            <span
                              className="fs-i"
                              style={{ textDecoration: "underline" }}
                            >
                              Ghi chú
                            </span>
                            <br />
                            <span className="fs-i">(1)</span>
                            <span className="fs-i pl-3">
                              Là công dân Hoa Kỳ hoặc đối tượng cư trú tại Hoa
                              Kỳ: Đối tượng cư trú tại Hoa kỳ bao gồm đối tượng
                              có thẻ xanh hoặc cá nhân lưu trú tại Hoa Kỳ ít
                              nhất 31 ngày trong năm hiện tại và 183 ngày trong
                              giai đoạn 03 năm, bao gồm năm hiện tại và 02 năm
                              liền trước.
                            </span>
                            <br />
                            <span className="fs-i">(2)</span>
                            <span className="fs-i pl-3">
                              Có dấu hiệu nhận biết Hoa Kỳ: Khách Hàng có một
                              trong các dấu hiệu sau: nơi sinh Hoa Kỳ, địa chỉ
                              nhận thư hoặc địa chỉ thường trú tại Hoa Kỳ, số
                              điện thoại liên lạc tại Hoa Kỳ, có chỉ định chuyển
                              khoản định kỳ vào một/các tài khoản tại Hoa Kỳ hay
                              định kỳ nhận tiền từ một/các tài khoản mở tại Hoa
                              Kỳ, có ủy quyền còn hiệu lực cho một đối tượng có
                              địa chỉ tại Hoa Kỳ, có địa chỉ nhận thư hộ hoặc
                              giữ thư tại Hoa Kỳ.
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="table table-bordered">
                      <tbody>
                        {/* TODO: G. */}
                        <tr>
                          <td
                            className="bg-casc py-1"
                            style={{ borderTop: "0" }}
                          >
                            G.
                          </td>
                          <td
                            className="bg-casc py-1"
                            style={{ borderTop: "0" }}
                            colSpan={3}
                          >
                            ĐĂNG KÝ SỬ DỤNG DỊCH VỤ GIAO DỊCH ĐIỆN TỬ
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1">1.</td>
                          <td
                            className="fz-12 py-1 font-weight-bold text-uppercase"
                            colSpan={2}
                          >
                            Giao dịch điện tử
                          </td>
                          <td className="fz-12 py-1 font-weight-bold text-uppercase text-center text-nowrap">
                            Lựa chọn
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1"></td>
                          <td className="fz-12 py-1" colSpan={2}>
                            Bằng cách lựa chọn tại đây, Khách Hàng đồng ý sử
                            dụng tất cả các nội dung giao dịch điện tử như liệt
                            kê tại mục (a), (b), (c) dưới đây. Trường hợp lựa
                            chọn từng dịch vụ đơn lẻ, Khách Hàng vui lòng lựa
                            chọn dịch vụ tương ứng phía dưới.
                          </td>
                          <td
                            className="fz-12 py-1 text-center prev-mobile"
                            style={{ verticalAlign: "middle" }}
                          >
                            {paramSearch?.isMobile == 1 && (
                              <div className="check-label checked mx-auto"></div>
                            )}
                            {paramSearch?.isMobile != 1 && (
                              <div
                                style={{
                                  height: "25px",
                                  width: "25px",
                                  border: "1px solid",
                                }}
                                className="mx-auto"
                              ></div>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1 font-weight-bold">
                            (a)
                          </td>
                          <td
                            className="fz-12 py-1 font-weight-bold text-uppercase"
                            colSpan={3}
                          >
                            GIAO DỊCH QUA ĐIỆN THOẠI
                          </td>
                        </tr>
                        <tr>
                          <td rowSpan={3}></td>
                          <td className="fz-12 p-0" colSpan={3}>
                            <div className="p-2 text-left">
                              <p className="my-0">
                                <b>Số điện thoại đăng ký</b>: Là số điện thoại
                                di động của Khách Hàng nêu tại Mục I của Hợp
                                Đồng Mở Tài Khoản Chứng Khoán.
                              </p>
                              <p className="my-0">
                                CASC tự động gửi mật khẩu giao dịch vào số điện
                                thoại này của Khách Hàng.
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="fz-12 p-0" colSpan={2}>
                            <div
                              className="d-grid w-100"
                              style={{ gridTemplateColumns: "50px auto" }}
                            >
                              <div
                                className="px-2 py-1 text-center font-weight-bold"
                                style={{ borderRight: "1px solid" }}
                              >
                                STT
                              </div>

                              <div className="px-2 py-1 text-center font-weight-bold">
                                Nội dung
                              </div>
                            </div>
                          </td>
                          <td
                            className="fz-12 py-0 px-2 text-nowrap font-weight-bold text-center"
                            style={{ verticalAlign: "middle" }}
                          >
                            Lựa chọn
                          </td>
                        </tr>
                        <tr>
                          <td className="fz-12 p-0" colSpan={2}>
                            <div
                              className="d-grid w-100"
                              style={{ gridTemplateColumns: "50px auto" }}
                            >
                              <div
                                className="px-2 py-1 text-center"
                                style={{ borderRight: "1px solid" }}
                              >
                                (1)
                              </div>

                              <div className="px-2 py-1 text-left">
                                Đăng ký thực hiện giao dịch tới hệ thống điện
                                thoại cố định của CASC.{" "}
                                <span className="fs-i">
                                  Các loại giao dịch đăng ký thực hiện bao gồm:
                                  (i) Nhận thông tin, thông báo; (ii) Đặt lệnh
                                  giao dịch chứng khoán và các giao dịch chuyển
                                  tiền giữa các tiểu khoản của Khách Hàng tại
                                  CASC; (iii) Ứng trước tiền bán chứng khoán;
                                  (iv) Thực hiện quyền mua chứng khoán; (iv)
                                  Nhận mật khẩu, thông tin xác thực khác.
                                </span>
                              </div>
                            </div>
                          </td>
                          <td
                            className="fz-12 py-1 text-center prev-mobile"
                            style={{ verticalAlign: "middle" }}
                          >
                            {paramSearch?.isMobile == 1 && (
                              <div className="check-label checked mx-auto"></div>
                            )}
                            {paramSearch?.isMobile != 1 && (
                              <div
                                style={{
                                  height: "25px",
                                  width: "25px",
                                  border: "1px solid",
                                }}
                                className="mx-auto"
                              ></div>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1 font-weight-bold">
                            (b)
                          </td>
                          <td
                            className="fz-12 py-1 font-weight-bold text-uppercase"
                            colSpan={3}
                          >
                            GIAO DỊCH QUA THƯ ĐIỆN TỬ
                          </td>
                        </tr>
                        <tr>
                          <td rowSpan={3}></td>
                          <td className="fz-12 p-0" colSpan={3}>
                            <div
                              className="px-2 py-1 text-left"
                              style={{ borderRight: "1px solid" }}
                            >
                              <b>Email đăng ký</b>: Là email của Khách Hàng nêu
                              tại Mục I của Hợp Đồng Mở Tài Khoản Chứng Khoán.
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="fz-12 p-0" colSpan={2}>
                            <div
                              className="d-grid w-100"
                              style={{ gridTemplateColumns: "50px auto" }}
                            >
                              <div
                                className="px-2 py-1 text-center font-weight-bold"
                                style={{ borderRight: "1px solid" }}
                              >
                                STT
                              </div>

                              <div className="px-2 py-1 text-center">
                                Nội dung
                              </div>
                            </div>
                          </td>
                          <td
                            className="fz-12 py-0 px-2 text-nowrap font-weight-bold text-center"
                            style={{ verticalAlign: "middle" }}
                          >
                            Lựa chọn
                          </td>
                        </tr>
                        <tr>
                          <td className="fz-12 p-0" colSpan={2}>
                            <div
                              className="d-grid w-100"
                              style={{ gridTemplateColumns: "50px auto" }}
                            >
                              <div
                                className="px-2 py-1 text-center"
                                style={{ borderRight: "1px solid" }}
                              >
                                (2)
                              </div>

                              <div className="px-2 py-1 text-left">
                                Đăng ký thực hiện giao dịch từ hoặc qua thư điện
                                tử Khách Hàng đã đăng ký tới tới hệ thống thư
                                điện tử của CASC.{" "}
                                <span className="fs-i">
                                  Các loại giao dịch đăng ký thực hiện bao gồm:
                                  (i) Nhận thông tin, thông báo; (ii) Đặt lệnh
                                  giao dịch chứng khoán và các giao dịch chuyển
                                  tiền giữa các tiểu khoản của Khách Hàng tại
                                  CASC; (iii) Ứng trước tiền bán chứng khoán;
                                  (iv) Thực hiện quyền mua chứng khoán; (iv)
                                  Nhận mật khẩu, thông tin xác thực khác; (vi)
                                  Gửi thông tin, thông báo và tài liệu khác.
                                </span>
                              </div>
                            </div>
                          </td>
                          <td
                            className="fz-12 py-1 text-center prev-mobile"
                            style={{ verticalAlign: "middle" }}
                          >
                            {/* <div
                              style={{
                                height: "25px",
                                width: "25px",
                                border: "1px solid",
                              }}
                              className="mx-auto"
                            >
                              
                            </div> */}
                            <div className="check-label checked mx-auto"></div>
                          </td>
                        </tr>

                        <tr>
                          <td className="text-center fz-12 py-1 font-weight-bold">
                            (c)
                          </td>
                          <td
                            className="fz-12 py-1 font-weight-bold text-uppercase"
                            colSpan={2}
                          >
                            GIAO DỊCH QUA HỆ THỐNG GIAO DỊCH ĐIỆN TỬ
                          </td>
                          <td className="fz-12 py-1 font-weight-bold text-uppercase text-center">
                            KÝ XÁC NHẬN
                          </td>
                        </tr>
                        <tr>
                          <td rowSpan={3}></td>
                          <td className="fz-12 py-1 px-2" colSpan={2}>
                            <b>User đăng ký</b>: Là mã Khách Hàng do CASC cung
                            cấp.
                            <br />
                            <b>Số điện thoại đăng ký nhận mật khẩu </b>(OTP): Là
                            số điện thoại di động của Khách Hàng nêu tại Mục I
                            của Hợp Đồng Mở Tài Khoản Chứng Khoán.
                          </td>
                          <td className="fz-12 py-1">
                            Ký Hợp Đồng Mở Tài Khoản Chứng Khoán; số chứng từ{" "}
                            <br />
                            {formatSoChungTu(paramSearch?.accCode)} thời gian{" "}
                            {formatTime()} ngày{" "}
                            {formatDate(new Date(), "/", "dmy")}
                          </td>
                        </tr>
                        <tr>
                          <td className="fz-12 p-0" colSpan={2}>
                            <div
                              className="d-grid w-100"
                              style={{ gridTemplateColumns: "50px auto" }}
                            >
                              <div
                                className="px-2 py-1 text-center font-weight-bold"
                                style={{ borderRight: "1px solid" }}
                              >
                                STT
                              </div>

                              <div className="px-2 py-1 text-center font-weight-bold">
                                Nội dung
                              </div>
                            </div>
                          </td>
                          <td
                            className="fz-12 py-0 px-2 text-nowrap font-weight-bold text-center"
                            style={{ verticalAlign: "middle" }}
                          >
                            Lựa chọn
                          </td>
                        </tr>
                        <tr>
                          <td className="fz-12 p-0" colSpan={2}>
                            <div
                              className="d-grid w-100"
                              style={{ gridTemplateColumns: "50px auto" }}
                            >
                              <div
                                className="px-2 py-1 text-center"
                                style={{ borderRight: "1px solid" }}
                              >
                                (3)
                              </div>

                              <div className="px-2 py-1 text-left">
                                Đăng ký thực hiện giao dịch và các thao tác khác
                                trên tài khoản qua hệ thống website chuyên dụng,
                                ứng dụng điện thoại di động và hệ thống giao
                                dịch điện tử khác do CASC cung cấp/sử dụng.{" "}
                                <span className="fs-i">
                                  Các loại giao dịch và thao tác đăng ký thực
                                  hiện bao gồm: (i) Truy cập tài khoản và thực
                                  hiện thao tác trên tài khoản; (ii) Nhận thông
                                  tin, thông báo; (iii) Đặt, gửi lệnh (gồm cả
                                  lệnh chuyển tiền); (iv) Ứng trước tiền bán
                                  chứng khoán; (v) Nhận mật khẩu, thông tin xác
                                  thực khác; (vi) Ký kết hợp đồng và thỏa thuận
                                  điện tử; (vii) Gửi thông tin, thông báo và tài
                                  liệu khác; (viii) Thực hiện các giao dịch,
                                  thao tác khác có trên hệ thống.
                                </span>
                              </div>
                            </div>
                          </td>
                          <td
                            className="fz-12 py-1 text-center prev-mobile"
                            style={{ verticalAlign: "middle" }}
                          >
                            {/* <div
                              style={{
                                height: "25px",
                                width: "25px",
                                border: "1px solid",
                              }}
                              className="mx-auto"
                            ></div> */}
                            <div className="check-label checked mx-auto"></div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1 font-weight-bold">
                            2.
                          </td>
                          <td
                            className="fz-12 py-1 font-weight-bold text-uppercase"
                            colSpan={3}
                          >
                            CHUYỂN TIỀN/THANH TOÁN
                          </td>
                        </tr>
                        <tr>
                          <td rowSpan={6}></td>
                          <td className="fz-12 p-0">
                            <div
                              className="d-grid w-100"
                              style={{ gridTemplateColumns: "50px auto" }}
                            >
                              <div
                                className="px-2 py-1 text-center font-weight-bold"
                                style={{ borderRight: "1px solid" }}
                              >
                                STT
                              </div>

                              <div className="px-2 py-1 text-center font-weight-bold">
                                Nội dung
                              </div>
                            </div>
                          </td>
                          <td
                            className="fz-12 py-0 px-2 text-nowrap font-weight-bold text-center"
                            style={{ verticalAlign: "middle" }}
                          >
                            Lựa chọn
                          </td>
                          <td
                            className="fz-12 py-0 px-2 text-nowrap font-weight-bold text-center"
                            style={{ verticalAlign: "middle" }}
                          >
                            Ký xác nhận
                          </td>
                        </tr>
                        <tr>
                          <td className="fz-12 p-0">
                            <div
                              className="d-grid w-100 h-100"
                              style={{ gridTemplateColumns: "50px auto" }}
                            >
                              <div
                                className="px-2 py-1 d-flex align-items-center justify-content-center"
                                style={{ borderRight: "1px solid" }}
                              >
                                (4)
                              </div>

                              <div className="px-2 py-1 d-flex align-items-center">
                                Đăng ký chuyển khoản tiền cùng tên chủ tài
                                khoản.
                              </div>
                            </div>
                          </td>
                          <td
                            className="fz-12 py-1 text-center prev-mobile"
                            style={{ verticalAlign: "middle" }}
                          >
                            <div className="check-label checked mx-auto"></div>
                          </td>
                          <td className="fz-12 py-1">
                            Ký Hợp Đồng Mở Tài Khoản Chứng Khoán; số chứng từ{" "}
                            <br />
                            {formatSoChungTu(paramSearch?.accCode)} thời gian{" "}
                            {formatTime()} ngày{" "}
                            {formatDate(new Date(), "/", "dmy")}
                          </td>
                        </tr>
                        <tr>
                          <td className="fz-12 p-0">
                            <div
                              className="d-grid w-100"
                              style={{ gridTemplateColumns: "50px auto" }}
                            >
                              <div
                                className="px-2 py-1"
                                style={{ borderRight: "1px solid" }}
                              >
                                (5)
                              </div>

                              <div className="px-2 py-1 text-left">
                                Đăng ký chuyển khoản tiền đến tài khoản khác tên
                                chủ tài khoản{" "}
                                <span className="fs-i">
                                  (bao gồm nhưng không giới hạn: Chuyển tiền
                                  sang tài khoản đối ứng khác tại CASC, chuyển
                                  tiền về tài khoản ngân hàng không đứng tên chủ
                                  tài khoản).
                                </span>
                              </div>
                            </div>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className="fz-12 p-0">
                            <div
                              className="d-grid w-100"
                              style={{ gridTemplateColumns: "50px auto" }}
                            >
                              <div
                                className="px-2 py-1"
                                style={{ borderRight: "1px solid" }}
                              >
                                (6)
                              </div>

                              <div className="px-2 py-1 text-left">
                                Đăng ký chuyển tiền sang tài khoản ngân hàng chỉ
                                định như đăng ký dưới đây.
                              </div>
                            </div>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className="fz-12 p-0" colSpan={3}>
                            <div
                              className="d-grid w-100"
                              style={{ gridTemplateColumns: "50px auto" }}
                            >
                              <div
                                className="px-2 py-1"
                                style={{ borderRight: "1px solid" }}
                              >
                                (6.1)
                              </div>

                              <div className="px-2 py-1 text-left">
                                Tài khoản ngân hàng chỉ định cùng tên chủ tài
                                khoản:
                                <div
                                  className="w-100 d-grid"
                                  style={{
                                    gridTemplateColumns: "50% auto",
                                    gap: "5px",
                                  }}
                                >
                                  <span className="text-left">
                                    Tên chủ tài khoản:.....................
                                  </span>
                                  <span className="text-left">
                                    Tên chủ tài khoản:.....................
                                  </span>
                                  <span className="text-left">
                                    Số tài khoản 1:.....................
                                  </span>
                                  <span className="text-left">
                                    Số tài khoản 2:.....................
                                  </span>
                                  <span className="text-left">
                                    Tại ngân hàng:.....................
                                  </span>
                                  <span className="text-left">
                                    Tại ngân hàng:.....................
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="fz-12 p-0" colSpan={3}>
                            <div
                              className="d-grid w-100"
                              style={{ gridTemplateColumns: "50px auto" }}
                            >
                              <div
                                className="px-2 py-1"
                                style={{ borderRight: "1px solid" }}
                              >
                                (6.2)
                              </div>

                              <div className="px-2 py-1 text-left">
                                Tài khoản ngân hàng chỉ định khác tên chủ tài
                                khoản:
                                <div
                                  className="w-100 d-grid"
                                  style={{
                                    gridTemplateColumns: "50% auto",
                                    gap: "5px",
                                  }}
                                >
                                  <span className="text-left">
                                    Tên chủ tài khoản:.....................
                                  </span>
                                  <span className="text-left">
                                    Tên chủ tài khoản:.....................
                                  </span>
                                  <span className="text-left">
                                    Số tài khoản 1:.....................
                                  </span>
                                  <span className="text-left">
                                    Số tài khoản 2:.....................
                                  </span>
                                  <span className="text-left">
                                    Tại ngân hàng:.....................
                                  </span>
                                  <span className="text-left">
                                    Tại ngân hàng:.....................
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-center fz-12 py-1 font-weight-bold">
                            3.
                          </td>
                          <td
                            className="fz-12 py-1 font-weight-bold text-uppercase"
                            colSpan={3}
                          >
                            YÊU CẦU GIAO DỊCH ĐIỆN TỬ KHÁC
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td className="fz-12 py-1" colSpan={3}>
                            <br />
                            <br />
                            <br />
                          </td>
                        </tr>
                        <tr>
                          <td className="fz-12 py-1" colSpan={4}>
                            <b className="fs-i">Ghi chú:</b>
                            <br />
                            <ul className="ul-counter-1">
                              <li className="fs-i">
                                Trường hợp Khách Hàng thay đổi email và số điện
                                thoại liên hệ trên giấy đề nghị thay đổi thông
                                tin sẽ đồng nghĩa với việc thay đổi email đăng
                                ký giao dịch qua thư điện tử và số điện thoại
                                giao dịch qua điện thoại.
                              </li>
                              <li className="fs-i">
                                CASC sẽ chỉ cung cấp các dịch vụ khi Khách Hàng
                                thực hiện đăng ký bằng cách điền X hoặc V. Nếu
                                Khách Hàng để trống mục lựa chọn, CASC sẽ không
                                cung cấp dịch vụ tương ứng.
                              </li>
                              <li className="fs-i">
                                Các dịch vụ Khách Hàng đăng ký sẽ được CASC cung
                                cấp hoặc không cung cấp theo chính sách áp dụng
                                được CASC ban hành từng thời kỳ.
                              </li>
                              <li className="fs-i">
                                Giao dịch qua hệ thống giao dịch điện tử và
                                chuyển tiền cùng chủ tài khoản là các dịch vụ
                                mặc định CASC cung cấp cho Khách Hàng khi Khách
                                Hàng đăng ký mở mới tài khoản chứng khoán bằng
                                phương thức điện tử.
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p className="mt-4 text-center font-weight-bold text-uppercase">
                      BỘ ĐIỀU KHOẢN VÀ ĐIỀU KIỆN CỦA HỢP ĐỒNG MỞ TÀI KHOẢN CHỨNG
                      KHOÁN
                    </p>
                    <p className="mb-1">
                      <b>
                        Bộ Điều Khoản Và Điều Kiện Của Hợp Đồng Mở Tài Khoản
                        Chứng Khoán{" "}
                      </b>{" "}
                      này (sau đây gọi là “<b>Bộ T&C</b>”) là một phần không
                      tách rời của Hợp Đồng Mở Tài Khoản Chứng Khoán mà Bộ T&C
                      này đính kèm (sau đây gọi là “<b>Hợp Đồng Mở TKCK</b>”).
                    </p>
                    <p className="my-0">
                      Bộ T&C bao gồm: (Phần I) Những Quy Định Chung; (Phần II)
                      Quy Định Về Ứng Trước Tiền Bán Chứng Khoán; (Phần III)
                      Thỏa Thuận Giao Dịch Điện Tử và (Phần IV) Quy Định Về Tài
                      Khoản Giao Dịch Ký Quỹ.
                    </p>
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <td
                            className="bg-casc py-1"
                            style={{ width: "75px" }}
                          >
                            PHẦN I:
                          </td>
                          <td className="bg-casc py-1">NHỮNG QUY ĐỊNH CHUNG</td>
                        </tr>
                      </tbody>
                    </table>

                    <p className="my-1">
                      <b>
                        Điều 1: &nbsp;&nbsp; Định Nghĩa Và Giải Thích Từ Ngữ
                        Chung
                      </b>
                    </p>
                    <p className="my-1">
                      Trong Bộ T&C này, các từ ngữ dưới đây có nghĩa như sau:
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        <b>Khách Hàng:</b> Là chủ tài khoản giao dịch chứng
                        khoán có thông tin mặc định ghi trong phần thông tin
                        Khách Hàng trên Hợp Đồng Mở TKCK.
                      </li>
                      <li>
                        <b>CASC:</b> Là Công ty Cổ Phần Chứng khoán Thủ Đô (gồm
                        cả sau khi được đổi tên hoặc doanh nghiệp tiếp tục tồn
                        tại sau khi tổ chức lại và kế thừa quyền và nghĩa vụ của
                        Công ty Cổ Phần Chứng khoán Thủ Đô theo Hợp Đồng Mở
                        TKCK).
                      </li>
                      <li>
                        <b>TKCK:</b> Là tài khoản giao dịch chứng khoán của
                        Khách Hàng với số ghi tại trang đầu của Hợp Đồng Mở TKCK
                        và các tài khoản, tiểu khoản khác được CASC mở dưới tên
                        Khách Hàng gắn với tài khoản đó nhằm cung cấp dịch vụ
                        chứng khoán được phép cho Khách Hàng (trong đó có cả tài
                        khoản lưu ký, tài khoản giao dịch ký quỹ (nếu Khách Hàng
                        đề nghị mở), tài khoản/tiểu khoản trong tài khoản chuyên
                        dụng của CASC mở để quản lý tài sản của Khách Hàng).
                      </li>
                      <li>
                        <b>TKGDKQ:</b> Là tài khoản giao dịch ký quỹ của Khách
                        Hàng để thực hiện các giao dịch kỹ quỹ chứng khoán.
                      </li>
                      <li>
                        <b>Lệnh:</b> Là lệnh (gồm cả lệnh mua, lệnh bán, lệnh
                        chuyển tiền, lệnh thanh toán và lệnh giao dịch thuộc
                        loại khác), chỉ thị, chỉ dẫn, chỉ định, hoặc tương đương
                        do Khách Hàng lập/gửi/xác nhận liên quan đến TKCK theo
                        quy định của Hợp Đồng Mở TKCK.
                      </li>
                      <li>
                        <b>Ngày giao dịch:</b> Là ngày theo lịch (không bao gồm
                        thứ Bảy, Chủ Nhật và các ngày nghỉ lễ khác theo luật, kể
                        cả nghỉ bù) mà vào ngày đó VSDC, SGDCK và ngân hàng
                        thương mại tại Việt Nam mở cửa để hoạt động bình thường.
                      </li>
                      <li>
                        <b>Số điện thoại:</b> : Là số điện thoại cố định, di
                        động hoặc cả hai được ghi tại phần thông tin về Khách
                        Hàng trên Hợp Đồng Mở TKCK hoặc số khác được Khách Hàng
                        đăng ký thay đổi và lưu trong hệ thống của CASC.
                      </li>
                      <li>
                        <b>Thư điện tử (email):</b> Là thư điện tử (email) được
                        ghi tại phần thông tin về Khách Hàng trên Hợp Đồng Mở
                        TKCK hoặc thư điện tử khác được Khách Hàng đăng ký thay
                        đổi và lưu trong hệ thống của CASC.
                      </li>

                      <li>
                        <b>Dữ liệu cá nhân:</b> Là các thông tin gắn liền với
                        một con người cụ thể hoặc giúp xác định một người cụ
                        thể, bao gồm: Dữ liệu cá nhân cơ bản và Dữ liệu cá nhân
                        nhạy cảm.
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Dữ liệu cá nhân cơ bản gồm: Họ, chữ đệm và tên khai
                            sinh, tên gọi khác (nếu có); Ngày, tháng, năm sinh;
                            ngày, tháng, năm chết hoặc mất tích; Giới tính; Nơi
                            sinh, nơi đăng ký khai sinh, nơi thường trú, nơi tạm
                            trú, nơi ở hiện tại, quê quán, địa chỉ liên hệ; Quốc
                            tịch; Hình ảnh của cá nhân; Số điện thoại, số chứng
                            minh nhân dân, số định danh cá nhân, số hộ chiếu, số
                            giấy phép lái xe, số biển số xe, số mã số thuế cá
                            nhân, số bảo hiểm xã hội, số thẻ bảo hiểm y tế; Tình
                            trạng hôn nhân; Thông tin về mối quan hệ gia đình
                            (cha mẹ, con cái); Thông tin về tài khoản số của cá
                            nhân; dữ liệu cá nhân phản ánh hoạt động, lịch sử
                            hoạt động trên không gian mạng.
                          </il>
                          <br />
                          <il>
                            Dữ liệu cá nhân nhạy cảm gồm: Quan điểm chính trị,
                            quan điểm tôn giáo; Tình trạng sức khỏe và đời tư
                            được ghi trong hồ sơ bệnh án, không bao gồm thông
                            tin về nhóm máu; Thông tin liên quan đến nguồn gốc
                            chủng tộc, nguồn gốc dân tộc; Thông tin về đặc điểm
                            di truyền được thừa hưởng hoặc có được của cá nhân;
                            Thông tin về thuộc tính vật lý, đặc điểm sinh học
                            riêng của cá nhân; Thông tin về đời sống tình dục,
                            xu hướng tình dục của cá nhân;Dữ liệu về tội phạm,
                            hành vi phạm tội được thu thập, lưu trữ bởi các cơ
                            quan thực thi pháp luật; Thông tin khách hàng của tổ
                            chức tín dụng, chi nhánh ngân hàng nước ngoài, tổ
                            chức cung ứng dịch vụ trung gian thanh toán, các tổ
                            chức được phép khác, gồm: thông tin định danh khách
                            hàng theo quy định của pháp luật, thông tin về tài
                            khoản, thông tin về tiền gửi, thông tin về tài sản
                            gửi, thông tin về giao dịch, thông tin về tổ chức,
                            cá nhân là bên bảo đảm tại tổ chức tín dụng, chi
                            nhánh ngân hàng, tổ chức cung ứng dịch vụ trung gian
                            thanh toán; Dữ liệu về vị trí của cá nhân được xác
                            định qua dịch vụ định vị; Dữ liệu cá nhân khác được
                            pháp luật quy định là đặc thù và cần có biện pháp
                            bảo mật cần thiết.
                          </il>
                          <br />
                        </ol>
                      </li>

                      <li>
                        <b>Xử lý dữ liệu cá nhân là:</b> một hoặc nhiều hoạt
                        động tác động tới dữ liệu cá nhân, như: thu thập, ghi,
                        phân tích, xác nhận, lưu trữ, chỉnh sửa, công khai, kết
                        hợp, truy cập, truy xuất, thu hồi, mã hóa, giải mã, sao
                        chép, chia sẻ, truyền đưa, cung cấp, chuyển giao, xóa,
                        hủy dữ liệu cá nhân hoặc các hành động khác có liên
                        quan.
                      </li>
                      <li>
                        <b>VSDC:</b> Là Tổng công ty Lưu ký và Bù trừ chứng
                        khoán Việt Nam.
                      </li>
                      <li>
                        <b>SGDCK:</b> Là Sở Giao dịch Chứng khoán tại Việt Nam.
                      </li>
                    </ul>
                    <p className="my-1">
                      Các từ ngữ được sử dụng riêng cho một phần của Bộ T&C sẽ
                      được giải thích tại phần đó.
                    </p>
                    <p className="my-1">
                      <b>Điều 2: &nbsp;&nbsp; Mở tài khoản</b>
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li className="text-danger">
                        Theo đề nghị của Khách Hàng, CASC sẽ mở tài khoản giao
                        dịch chứng khoán đứng tên Khách Hàng. Đồng thời, CASC
                        cũng sẽ mở tài khoản lưu ký chứng khoán cho Khách Hàng.
                      </li>
                      <li>
                        Bằng Hợp Đồng Mở TKCK, Khách Hàng chọn phương thức quản
                        lý tiền trong tài khoản chuyên dụng của CASC. Nhằm quản
                        lý tiền của Khách Hàng, CASC sẽ mở tiểu khoản dưới tên
                        Khách Hàng trong tài khoản chuyên dụng. Việc thay đổi
                        phương thức quản lý tiền sẽ theo thỏa thuận của các bên
                        hoặc theo quy định được CASC ban hành/áp dụng trong từng
                        thời kỳ.
                      </li>
                      <li>
                        Nếu Khách Hàng có đề nghị và đã đăng ký, CASC sẽ mở tài
                        khoản giao dịch ký quỹ cho Khách Hàng. CASC cũng sẽ mở
                        các tài khoản/tiểu khoản hỗ trợ giao dịch chứng khoán
                        trên tài khoản đó phù hợp với nghiệp vụ/chính sách của
                        CASC và quy định của pháp luật có liên quan.
                      </li>
                      <li>
                        Tại từng thời điểm trong thời hạn Hợp Đồng Mở TKCK, CASC
                        cũng có thể mở tài khoản/tiểu khoản khác cho Khách Hàng
                        nhằm cung cấp dịch vụ chứng khoán được phép theo yêu cầu
                        của Khách Hàng.
                      </li>
                      <li>
                        Các tài khoản và tiểu khoản này đều gắn với tài khoản
                        giao dịch chứng khoán của Khách Hàng.
                      </li>
                    </ul>
                    <p className="my-1">
                      <b>Điều 3: &nbsp;&nbsp; Dịch Vụ Chứng Khoán</b>
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        CASC sẽ cung cấp cho Khách Hàng các dịch vụ chứng khoán
                        sau trên TKCK cho Khách Hàng:
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Thực hiện giao dịch mua, bán và giao dịch khác đối
                            với chứng khoán và tài sản tài chính được phép;
                          </il>
                          <br />
                          <il>
                            Tham gia vào việc thanh toán tiền và bù trừ chứng
                            khoán do Khách Hàng giao dịch;
                          </il>
                          <br />
                          <il>
                            Nhận tiền Khách Hàng nộp/chuyển để giao dịch chứng
                            khoán và cho các mục đích đầu tư và thanh toán được
                            phép khác; nhận tiền của tổ chức/cá nhân khác chuyển
                            hoặc thanh toán cho Khách Hàng; quản lý nguồn tiền
                            của Khách hàng và thực hiện lệnh/yêu cầu sử dụng
                            tiền của Khách Hàng (gồm cả thanh toán giao dịch
                            chứng khoán, rút tiền, thanh toán cho giao dịch và
                            mục đích khác và chuyển tiền đến tài khoản khác);
                          </il>
                          <br />
                          <il>
                            Quản lý chứng khoán và tài sản được phép khác của
                            Khách Hàng;
                          </il>
                          <br />
                          <il>
                            Hỗ trợ Khách Hàng thực hiện quyền của người sở hữu
                            chứng khoán;
                          </il>
                          <br />
                          <il>
                            Các dịch vụ giao dịch khác gắn với từng loại tài
                            khoản/tiểu khoản phù hợp với nghiệp vụ được phép của
                            CASC; và
                          </il>
                          <br />
                          <il>
                            Các dịch vụ khác phù hợp với quy định của pháp luật
                            được CASC cung cấp trong từng thời kỳ và được công
                            bố trên website của CASC.
                          </il>
                          <br />
                        </ol>
                      </li>
                      <li>
                        Tại từng thời điểm trong thời hạn Hợp Đồng Mở TKCK,
                        Khách Hàng có thể đưa ra yêu cầu đăng ký thêm/sửa
                        đổi/hủy tiện ích, dịch vụ liên quan đến TKCK và việc sử
                        dụng dịch vụ của Khách Hàng. Các yêu cầu này phải được
                        lập thành văn bản theo quy định được CASC ban hành/áp
                        dụng trong từng thời kỳ. CASC có quyền chấp thuận hoặc
                        từ chối các yêu cầu này của Khách Hàng mà không cần giải
                        thích lý do tùy theo quy định và khả năng đáp ứng dịch
                        vụ của CASC trong từng thời kỳ.
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>Điều 4: &nbsp;&nbsp; Cách Thức Nhận lệnh</b>
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        CASC nhận Lệnh của Khách Hàng theo các cách thức sau:
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Nhận Lệnh trực tiếp tại quầy giao dịch (là địa điểm
                            giao dịch được phép của CASC);
                          </il>
                          <br />
                          <il>Nhận Lệnh qua điện thoại;</il>
                          <br />
                          <il>Nhận Lệnh qua thư điện tử; và</il>
                          <br />
                          <il>
                            Nhận Lệnh qua Hệ thống giao dịch điện tử theo quy
                            định tại Phần III “THỎA THUẬN GIAO DỊCH ĐIỆN TỬ” của
                            Bộ T&C này.
                          </il>
                          <br />
                        </ol>
                      </li>
                      <li>
                        CASC có thể nhận Lệnh của Khách Hàng qua cách thức khác
                        phù hợp với quy định của pháp luật theo chính sách/quy
                        tắc do CASC ban hành trong từng thời kỳ.
                      </li>
                      <li>
                        Trong trường hợp hợp đồng/thỏa thuận mà cả CASC và Khách
                        Hàng là một bên có chứa đựng Lệnh thì bằng việc ký hợp
                        đồng/thỏa thuận đó, Khách Hàng được coi là lập và gửi
                        Lệnh cho CASC và CASC được coi là nhận Lệnh của Khách
                        Hàng. Tương tự, nếu Khách Hàng gửi văn bản có chứa Lệnh
                        đến CASC liên quan đến giao dịch của Khách Hàng và bên
                        thứ ba và CASC chấp thuận việc đó thì bằng việc gửi văn
                        bản, Khách Hàng được coi là lập và gửi Lệnh cho CASC
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>Điều 5: &nbsp;&nbsp; Quy Định Chung Về Lệnh</b>
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        Khách Hàng chỉ lập và gửi Lệnh theo mẫu do CASC cung cấp
                        hoặc đồng ý và theo phương thức áp dụng cho loại Lệnh đó
                        quy định trong Hợp Đồng Mở TKCK.
                      </li>
                      <li>
                        Lệnh của Khách Hàng phải có đủ thông tin bắt buộc theo
                        quy định của pháp luật và CASC.
                      </li>
                      <li>
                        Khách Hàng là pháp nhân có trách nhiệm tuân thủ quy định
                        nội bộ của mình khi lập và gửi Lệnh (cả về nội dung và
                        thủ tục). Khi nhận được Lệnh của Khách Hàng, CASC được
                        mặc nhiên coi rằng Khách Hàng đã tuân thủ quy định nội
                        bộ của mình.
                      </li>
                      <li>
                        Nếu nhận được Lệnh và tin tưởng trên tinh thần thiện chí
                        Lệnh đó là thật và được ký bởi chính Khách Hàng (hoặc
                        người được Khách Hàng ủy quyền đã đăng ký với CASC) thì
                        CASC có quyền thực hiện Lệnh đó. Nếu CASC không thể xác
                        minh được chữ ký trên Lệnh của Khách Hàng so với chữ ký
                        mẫu của Khách Hàng sau khi đã thực hiện mọi thủ tục xác
                        minh hợp lý thì CASC có quyền không thực hiện Lệnh. Nếu
                        CASC có lý do hợp lý để nghi ngờ chữ ký trên Lệnh không
                        phải là chữ ký Khách Hàng (hoặc người được Khách Hàng ủy
                        quyền đã đăng ký với CASC) thì CASC có quyền trì hoãn
                        thực hiện Lệnh cho đến khi đã thực hiện xong việc xác
                        thực cần thiết.
                      </li>
                      <li>
                        Nếu nhận được Lệnh của Khách Hàng mà người ký Lệnh không
                        phải là người đại diện đã đăng ký với CASC thì CASC có
                        quyền yêu cầu Khách Hàng đăng ký người đại diện bổ sung
                        trước khi thực hiện Lệnh.
                      </li>
                      <li>
                        Nếu CASC nhận được hai hay nhiều Lệnh từ Khách Hàng
                        (theo cùng phương thức hoặc theo phương thức khác nhau)
                        có nội dung mâu thuẫn nhau thì CASC được từ chối thực
                        hiện Lệnh đến sau nếu đã thực hiện xong Lệnh đến trước
                        hoặc chỉ thực hiện được một phần nhưng việc thực hiện
                        Lệnh đến sau, theo đánh giá hợp lý của CASC, là không
                        phù hợp, không thể thực hiện được hoặc sẽ gây hoặc có
                        nguy cơ gây thiệt hại cho CASC hoặc việc hủy Lệnh đến
                        trước là không thể thực hiện được trên thực tế.
                      </li>
                      <li>
                        CASC chỉ thực hiện Lệnh của Khách Hàng sau khi Khách
                        Hàng đã đáp ứng được các điều kiện theo quy định của
                        pháp luật để Lệnh có thể được thực hiện, kể cả có đủ
                        tiền hoặc chứng khoán theo yêu cầu trên TKCK cho lệnh
                        mua hoặc bán tương ứng.
                      </li>
                      <li>
                        CASC được từ chối thực hiện Lệnh nếu Lệnh đó được gửi
                        đến khi đã quá khung thời gian thực hiện (xác định theo
                        quy định của pháp luật hay quy chế của SGDCK, VSDC hoặc
                        CASC) hoặc sau thời điểm chốt sổ (cut-off time) (xác
                        định theo chính sách được CASC áp dụng trong từng thời
                        kỳ và được công bố trên website của CASC).
                      </li>
                      <li>
                        Việc lập, gửi và thực hiện Lệnh của Khách Hàng phải tuân
                        thủ chính sách, quy định và quy chế do CASC áp dụng
                        trong từng thời kỳ, và được công bố trên website của
                        CASC.
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>
                        Điều 6: &nbsp;&nbsp; Quyền Và Nghĩa Vụ Của Khách Hàng
                      </b>
                    </p>
                    <p className="my-1">
                      Ngoài các quyền và nghĩa vụ được quy định tại các phần
                      khác của Bộ T&C và pháp luật, Khách Hàng có các quyền và
                      nghĩa vụ sau đây:
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        <b>Quyền</b>
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Khách Hàng là chủ sở hữu hợp pháp với toàn bộ số
                            tiền và chứng khoán trên TKCK, được thực hiện các
                            quyền hợp pháp của người sở hữu chứng khoán theo quy
                            định của pháp luật.
                          </il>
                          <br />
                          <il>
                            Khách Hàng được hưởng mức lãi suất theo quy định của
                            CASC công bố trong từng thời kỳ đối với số dư tiền
                            có trên TKCK.
                          </il>
                          <br />
                          <il>
                            Phụ thuộc vào đoạn b khoản 1 Điều 7 dưới đây, Khách
                            Hàng được rút/chuyển một phần hoặc toàn bộ tiền,
                            chứng khoán ra khỏi TKCK khi không có nhu cầu giao
                            dịch hoặc khi chấm dứt Hợp Đồng Mở TKCK với điều
                            kiện tiền và chứng khoán đó không bị ràng buộc, hạn
                            chế bởi các nghĩa vụ hoặc trách nhiệm nào với
                            CASC/hoặc với bất kỳ bên thứ ba nào có liên quan
                            hoặc theo yêu cầu của cơ quan có thẩm quyền.
                          </il>
                          <br />
                          <il>
                            Khách Hàng có quyền yêu cầu CASC xuất hóa đơn tài
                            chính theo đúng quy định pháp luật. Khách Hàng cần
                            liên hệ với CASC trong giờ hành chính vào các ngày
                            giao dịch tương ứng (nếu muốn cấp hóa đơn tài chính
                            theo ngày) hoặc vào ngày giao dịch cuối cùng của
                            tháng (nếu muốn cấp hóa đơn tài chính theo tháng).
                            Nếu Khách Hàng không yêu cầu cụ thể, CASC sẽ gộp phí
                            dịch vụ của Khách Hàng vào tổng mức phí dịch vụ của
                            CASC (của tất cả Khách Hàng phát sinh trong tháng)
                            và/hoặc phần lãi vay của Khách Hàng vào tổng mức lãi
                            vay của CASC (của tất cả Khách Hàng phát sinh trong
                            tháng) và xuất hóa đơn tài chính chung cho từng
                            tháng giao dịch vào ngày giao dịch cuối cùng của
                            tháng tương ứng.
                          </il>
                          <br />
                        </ol>
                      </li>
                      <li>
                        <b>Nghĩa vụ</b>
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Khách Hàng có nghĩa vụ cung cấp đầy đủ và trung thực
                            các thông tin về Khách Hàng theo yêu cầu tại Hợp
                            Đồng Mở TKCK, cam đoan và cam kết thông tin đã cung
                            cấp là đúng sự thật và phải thông báo cho CASC khi
                            phát sinh những thay đổi có liên quan. Khách Hàng
                            phải chịu mọi trách nhiệm, rủi ro hoặc thiệt hại
                            liên quan đến việc thay đổi thông tin mà không thông
                            báo cho CASC. Các thông tin của Khách Hàng trên TKCK
                            sẽ đồng nhất với các thông tin trên TKGDKQ, các tài
                            khoản, tiểu khoản khác mà CASC mở theo yêu cầu của
                            Khách Hàng. Khi các thông tin của TKCK có sự thay
                            đổi đồng nghĩa với việc các thông tin tại các tài
                            khoản, tiểu khoản khác cũng thay đổi tương ứng.
                          </il>
                          <br />
                          <il>
                            Khách Hàng có nghĩa vụ thanh toán các khoản phí môi
                            giới, các khoản phí khác theo quy định của CASC và
                            có trách nhiệm nộp các loại thuế, phí liên quan theo
                            quy định của pháp luật. Khách Hàng mặc nhiên chấp
                            thuận việc CASC tự động khấu trừ vào tài khoản giao
                            dịch chứng khoán của Khách Hàng các khoản thuế, phí
                            dịch vụ trước hoặc ngay khi CASC cung cấp dịch vụ
                            theo biểu phí của CASC.
                          </il>
                          <br />
                          <il>
                            Nếu Khách Hàng không thực hiện đúng, đầy đủ các
                            nghĩa vụ phát sinh với CASC theo Hợp Đồng Mở TKCK và
                            các thỏa thuận khác có liên quan, Khách Hàng đương
                            nhiên và tự nguyện chấp thuận để CASC có toàn quyền
                            và tự động phong tỏa, xử lý các tài sản trên TKCK và
                            các tài sản khác (nếu có) để khấu trừ/ thực hiện các
                            nghĩa vụ còn lại của Khách Hàng mà không có bất kỳ
                            khiếu kiện, yêu cầu và/hoặc phản đối nào đối với
                            CASC.
                          </il>
                          <br />
                          <il>
                            Khách Hàng tự chịu rủi ro đối với mọi quyết định sử
                            dụng TKCK, tiền hay tài sản trên TKCK (Khách Hàng
                            hiểu rằng CASC không đưa ra bất kỳ cam kết hay bảo
                            đảm nào (dù rõ ràng hay ngụ ý) về bảo toàn vốn đầu
                            tư của Khách Hàng hay khả năng sinh lợi của vốn đầu
                            tư).
                          </il>
                          <br />
                          <il>
                            Khách Hàng có nghĩa vụ tuân thủ quy định của pháp
                            luật, quy chế/quy định của VSDC, SGDCK và quy
                            chế/quy định của CASC có liên quan trong việc mở và
                            sử dụng TKCK, tiền và tài sản trên TKCK.
                          </il>
                          <br />
                          <il>
                            Khách Hàng phải bảo đảm tính hợp pháp về số tiền và
                            chứng khoán Khách Hàng sở hữu.
                          </il>
                          <br />
                          <il>
                            Khách Hàng có nghĩa vụ ký/xác nhận công nợ với CASC
                            khi có yêu cầu từ bên thứ ba và/hoặc cơ quan Nhà
                            nước có thẩm quyền.
                          </il>
                          <br />
                          <il>
                            Khách Hàng có trách nhiệm công bố thông tin đối với
                            các giao dịch mua bán chuyển nhượng trên TKCK của
                            Khách Hàng theo quy định pháp luật, bao gồm nhưng
                            không hạn chế bởi các trường hợp Khách Hàng tự bán
                            chứng khoán hoặc CASC thực hiện lệnh bán giải chấp
                            chứng khoán ký quỹ hoặc bán chứng khoán cầm cố.
                          </il>
                          <br />
                          <il>
                            Khách Hàng có nghĩa vụ thông báo tới CASC các giao
                            dịch mà Khách Hàng là người nội bộ, người có liên
                            quan của người nội bộ theo quy định pháp luật.
                          </il>
                          <br />
                        </ol>
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>Điều 7: &nbsp;&nbsp; Quyền Và Nghĩa Vụ Của CASC</b>
                    </p>
                    <p className="my-1">
                      Ngoài các quyền và nghĩa vụ được quy định tại các phần
                      khác của Bộ T&C và pháp luật, CASC có các quyền và nghĩa
                      vụ sau đây:
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        <b>Quyền</b>
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            CASC được thu phí/giá dịch vụ từ Khách Hàng theo mức
                            phí/giá đã công bố cho Khách Hàng.
                          </il>
                          <br />
                          <il>
                            CASC được tạm khóa/phong tỏa một phần hoặc toàn bộ
                            một hoặc một số TKCK:
                            <br />
                            <pl className="counter-roman">
                              <jl>
                                CASC được tạm khóa/phong tỏa một phần hoặc toàn
                                bộ một hoặc một số TKCK:
                              </jl>
                              <br />
                              <jl>
                                Theo thỏa thuận giữa Khách Hàng và CASC; hoặc
                              </jl>
                              <br />
                              <jl>Theo yêu cầu của chính Khách Hàng.</jl>
                            </pl>
                          </il>
                          <il>
                            CASC được phép yêu cầu ngân hàng nơi Khách Hàng mở
                            tài khoản tiền giao dịch chứng khoán trích số dư
                            tiền trên tài khoản tiền để thực hiện thanh toán các
                            lệnh mua, thanh toán các khoản phí, thuế cho các
                            lệnh mua/bán chứng khoán đã được thực hiện của Khách
                            Hàng.
                          </il>
                          <br />
                          <il>
                            CASC được ghi tăng/ghi giảm chứng khoán trên TKCK để
                            thực hiện thanh toán theo lệnh mua/bán của Khách
                            Hàng đã được thực hiện.
                          </il>
                          <br />
                          <il>
                            CASC chỉ có trách nhiệm thực hiện các giao dịch
                            chứng khoán theo yêu cầu của Khách Hàng và không
                            chịu trách nhiệm về các quyết định giao dịch chứng
                            khoán của Khách Hàng.
                          </il>
                          <br />
                          <il>
                            CASC có quyền tạm ngừng/chấm dứt/từ chối việc mở
                            TKCK, đăng ký sử dụng dịch vụ trên TKCK, đóng/đình
                            chỉ hoạt động của TKCK hoặc hủy bỏ dịch vụ Khách
                            Hàng đã đăng ký phù hợp với quy định của Hợp Đồng Mở
                            TKCK, quy định của CASC và của pháp luật hoặc cơ
                            quan Nhà nước có thẩm quyền.
                          </il>
                          <br />
                          <il>
                            CASC có quyền khấu trừ bất kỳ khoản tiền nào trên
                            TKCK để thanh toán cho phần nghĩa vụ mà Khách Hàng
                            phải thực hiện với CASC, cơ quan Nhà nước có thẩm
                            quyền hoặc với bên thứ ba phối hợp cùng CASC để cung
                            cấp dịch vụ cho Khách Hàng.
                          </il>
                          <br />
                          <il>
                            CASC được cung cấp thông tin liên quan đến TKCK và
                            thực hiện các giao dịch khác theo ủy quyền của Khách
                            Hàng hoặc theo quy định của pháp luật tới các cơ
                            quan Nhà nước có thẩm quyền khi được yêu cầu.
                          </il>
                          <br />
                        </ol>
                      </li>
                      <li>
                        <b>Nghĩa vụ</b>
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            CASC phải quản lý tách bạch tiền gửi/tài sản của
                            Khách Hàng với tiền gửi/tài sản của Khách Hàng khác
                            và với tiền/tài sản của chính CASC.
                          </il>
                          <br />
                          <il>
                            CASC phải công bố mức phí/giá dịch vụ giao dịch
                            chứng khoán trước khi Khách Hàng thực hiện giao
                            dịch.
                          </il>
                          <br />
                          <il>
                            CASC có nghĩa vụ cập nhật thông tin thay đổi của
                            Khách Hàng liên quan đến TKCK khi Khách Hàng có yêu
                            cầu.
                          </il>
                          <br />
                          <il>
                            CASC phải tuân thủ quy định của pháp luật, quy chế
                            hay quy định có liên quan trong việc cung ứng dịch
                            vụ cho Khách Hàng liên quan đến TKCK.
                          </il>
                          <br />
                          <il>
                            CASC không được sử dụng tiền và chứng khoán của
                            Khách Hàng nếu không được phép của Khách Hàng, trừ
                            trường hợp pháp luật quy định hoặc các bên có thỏa
                            thuận khác.
                          </il>
                          <br />
                          <il>
                            CASC có nghĩa vụ thông báo thông tin về chứng khoán,
                            thông tin về thực hiện quyền phát sinh từ chứng
                            khoán và các thông báo khác trên website chính thức
                            của CASC và/hoặc các phương thức khác do CASC lựa
                            chọn phù hợp với quy định của pháp luật.
                          </il>
                          <br />
                          <il>
                            CASC có nghĩa vụ thông báo kết quả khớp lệnh cho
                            Khách Hàng.
                          </il>
                          <br />
                        </ol>
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>Điều 8: &nbsp;&nbsp; Thông Tin Liên Lạc</b>
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        Khách Hàng có thể gửi thông tin, thông báo cho CASC bằng
                        cách chuyển tận tay hoặc qua dịch vụ chuyển phát của bên
                        thứ ba (kể cả bưu chính hay chuyển phát) đến trụ sở, chi
                        nhánh, phòng giao dịch hay điểm giao dịch được phép khác
                        của CASC theo địa chỉ tương ứng được công bố trên
                        website của CASC trong từng thời kỳ.
                      </li>
                      <li>
                        CASC có thể gửi thông tin, thông báo cho Khách Hàng bằng
                        cách chuyển tận tay hoặc qua dịch vụ chuyển phát đến địa
                        chỉ ghi trên Hợp Đồng Mở TKCK hoặc địa chỉ khác đã được
                        Khách Hàng đăng ký thay đổi và lưu trong hệ thống của
                        CASC. CASC cũng có thể gửi thông tin, thông báo cho
                        Khách Hàng bằng cách gọi điện (có ghi âm) hoặc gửi tin
                        nhắn (SMS) đến Số điện thoại và qua Thư điện tử.
                      </li>
                      <li>
                        Một thông tin/thông báo (nếu có) được xem là đã gửi
                        thành công:
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Nếu gọi điện, tại thời điểm cuộc gọi kết thúc thành
                            công và được ghi âm;
                          </il>
                          <br />
                          <il>
                            Nếu gửi qua tin nhắn (SMS), khi người gửi nhận được
                            thông báo của nhà cung cấp dịch vụ chuyển tin nhắn
                            rằng tin nhắn đã được gửi (delivered) thành công;
                          </il>
                          <br />
                          <il>
                            Nếu gửi qua thư điện tử, khi thư điện tử có liên
                            quan được gửi vào đúng địa chỉ thư điện tử người
                            nhận và thư điện tử đã được lưu vào mục “Thư đi”
                            (Sent) trong hòm thư của người gửi và trong vòng 24h
                            từ thời điểm thư điện tử đó được lưu, người gửi
                            không nhận được thông báo tự động nào về việc thư
                            điện tử đó không được gửi thành công; và
                          </il>
                          <br />
                          <il>
                            Nếu được gửi qua dịch vụ chuyển phát, tại thời điểm
                            xác nhận nhận được gói bưu kiện đựng thông báo có
                            liên quan.
                          </il>
                        </ol>
                      </li>
                      <li>
                        CASC và Khách Hàng có thể gửi thông tin, thông báo cho
                        nhau qua Hệ thống giao dịch điện tử. Cách thức gửi và
                        thời điểm nhận được thông tin, thông báo sẽ theo quy
                        định của Phần III “Thỏa Thuận Giao Dịch Điện Tử”.
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>Điều 9: &nbsp;&nbsp; Sửa đổi</b>
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        Hợp Đồng Mở TKCK (gồm cả Bộ T&C) có thể được sửa đổi
                        theo thỏa thuận bằng văn bản được ký giữa CASC và Khách
                        Hàng (theo cách thức truyền thống hoặc theo phương thức
                        giao dịch điện tử).
                      </li>
                      <li>
                        Hợp Đồng Mở TKCK cũng có thể được sửa đổi theo một trong
                        các cách thức sau:
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Theo thông báo của CASC hoặc công bố trên website
                            của CASC và Khách Hàng tiếp tục sử dụng dịch vụ có
                            liên quan tại CASC; hoặc
                          </il>
                          <br />
                          <il>
                            Theo thông báo của CASC và Khách Hàng gửi chấp thuận
                            hoặc không gửi phản đối trong thời gian nêu trong
                            thông báo đó.
                          </il>
                        </ol>
                      </li>
                    </ul>
                    <p className="my-0">
                      Việc sửa đổi theo các cách trên có giá trị và hiệu lực như
                      sửa đổi theo cách nêu tại khoản 1 Điều này.
                    </p>

                    <p className="my-1">
                      <b>
                        Điều 10: &nbsp;&nbsp; Cam Đoan Và Cam Kết Của Các Bên
                      </b>
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        CASC phải bồi thường cho Khách Hàng đối với thiệt
                        hại/tổn thất mà Khách Hàng thực tế gánh chịu phát sinh
                        trực tiếp từ việc CASC có hành vi vi phạm Hợp Đồng Mở
                        TKCK một cách chủ ý hoặc lừa dối.
                      </li>
                      <li>
                        Khách Hàng phải bồi thường và bồi hoàn cho CASC (hoặc
                        bên thứ ba do CASC chỉ định) đối với thiệt hại/tổn thất
                        mà CASC (hoặc bên đó) thực tế gánh chịu phát sinh trực
                        tiếp từ việc Khách Hàng sử dụng TKCK và dịch vụ tại CASC
                        hoặc Khách Hàng vi phạm pháp luật, vi phạm Hợp Đồng Mở
                        TKCK hoặc có hành vi lừa dối.
                      </li>
                      <li>
                        CASC được miễn trách nhiệm với Khách Hàng nếu không thực
                        hiện một hành động mà theo đánh giá của CASC, việc thực
                        hiện hành động đó sẽ dẫn đến vi phạm pháp luật.
                      </li>
                      <li>
                        CASC không phải chịu trách nhiệm đối với hành vi vi phạm
                        pháp luật của Khách Hàng. CASC cũng không phải chịu
                        trách nhiệm do hành vi của bất kỳ bên thứ ba nào mà
                        không phải là đại diện của CASC.
                      </li>
                      <li>
                        Khách Hàng hiểu rằng mình phải tự quyết định đầu tư và
                        tự chịu trách nhiệm về các quyết định đó của mình (ý
                        kiến của nhân viên môi giới, nhân viên tư vấn đầu tư chỉ
                        có ý nghĩa tham khảo).
                      </li>
                      <li>
                        Khi Khách Hàng hoặc người được Khách Hàng ủy quyền ký
                        tên vào Lệnh, Khách Hàng mặc nhiên thừa nhận đã cân nhắc
                        kỹ lưỡng trước khi yêu cầu CASC thực hiện Lệnh.
                      </li>
                      <li>
                        Khách Hàng mặc nhiên công nhận và chấp thuận kết quả
                        giao dịch do CASC thực hiện theo Lệnh của Khách Hàng
                        hoặc của người được Khách Hàng ủy quyền theo cách thức
                        đặt Lệnh được các bên thoả thuận tại Hợp Đồng Mở TKCK.
                        Nếu Khách Hàng ủy quyền cho người khác giao dịch bằng
                        các hình thức ủy quyền theo quy định của pháp luật thì
                        các giao dịch được thực hiện bởi người được Khách Hàng
                        ủy quyền được coi là giao dịch của chính Khách Hàng.
                      </li>
                      <li>
                        Khách Hàng và người ủy quyền hợp pháp của Khách Hàng có
                        trách nhiệm theo dõi và tự mình kiểm tra tình trạng giao
                        dịch, số dư tiền, số dư chứng khoán trên TKCK và chủ
                        động theo dõi biến động giá chứng khoán trên thị trường.
                        Nếu có thắc mắc liên quan tới TKCK, giao dịch đã được
                        thực hiện cũng như những dịch vụ do CASC cung cấp (kể cả
                        sai sót, giao dịch trái thẩm quyền) thì Khách Hàng phải
                        gửi yêu cầu bằng văn bản tới CASC trong vòng 24h kể từ
                        ngày phát sinh giao dịch hoặc từ ngày diễn ra sự thay
                        đổi trên TKCK của Khách Hàng. Sau thời hạn này, nếu
                        Khách Hàng không có bất kỳ phản đối nào, Khách Hàng được
                        xem là mặc nhiên xác nhận các giao dịch, số dư tiền và
                        chứng khoán của Khách Hàng trên TKCK. CASC không có
                        trách nhiệm đối với các giao dịch nào được thực hiện
                        trên TKCK ngoại trừ các giao dịch mà Khách Hàng đã thực
                        hiện theo đúng Hợp Đồng Mở TKCK và đã được xác minh là
                        có sai sót, gian lận hoặc giao dịch trái thẩm quyền do
                        lỗi của CASC.
                      </li>
                      <li>
                        Khách Hàng cam đoan rằng nội dung và việc ký Hợp Đồng Mở
                        TKCK đã được phê duyệt đầy đủ và hợp lệ bởi các cơ
                        quan/cấp có thẩm quyền nội bộ của Khách Hàng và người ký
                        đại diện cho Khách Hàng trên các hồ sơ giao dịch là
                        người đại diện hợp pháp của Khách Hàng.
                      </li>
                      <li>
                        Khách Hàng có trách nhiệm thông báo cho CASC các thông
                        tin về việc trở thành/không còn là cổ đông nội bộ của
                        công ty đại chúng/quỹ đại chúng và/hoặc thực hiện công
                        bố thông tin theo quy định đối với các trường hợp cần
                        thực hiện công bố thông tin trong quá trình giao dịch
                        chứng khoán tại CASC.
                      </li>
                      <li>
                        Trong mọi trường hợp, Khách Hàng cam đoan đã và sẽ tìm
                        hiểu, nắm bắt và chấp hành nghiêm chỉnh các quy định của
                        pháp luật và của CASC liên quan đến việc giao dịch chứng
                        khoán, sử dụng tài khoản chứng khoán của Khách Hàng và
                        các quy định về phòng chống rửa tiền.
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>
                        Điều 11: &nbsp;&nbsp; Hiệu Lực; Chấm Dứt Và Thanh Lý
                      </b>
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>Hợp Đồng Mở TKCK có hiệu lực từ ngày ký.</li>
                      <li>
                        Hợp Đồng Mở TKCK có thể bị chấm dứt theo thỏa thuận bằng
                        văn bản giữa các bên. Trong trường hợp này, quyền và
                        nghĩa vụ của các bên khi Hợp Đồng Mở TKCK bị chấm dứt sẽ
                        theo thỏa thuận bằng văn bản giữa các bên.
                      </li>
                      <li>
                        CASC có quyền đơn phương chấm dứt Hợp Đồng Mở TKCK trong
                        các trường hợp sau:
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Khách Hàng vi phạm pháp luật trong việc mở/sử dụng
                            TKCK hoặc dịch vụ tại CASC mà theo đánh giá của CASC
                            vi phạm đó gây hoặc có thể gây ảnh hưởng bất lợi đến
                            mình;
                          </il>
                          <br />
                          <il>
                            CASC phải chấm dứt Hợp Đồng Mở TKCK theo yêu cầu của
                            Cơ quan có thẩm quyền;
                          </il>
                          <br />
                          <il>
                            Khách Hàng vi phạm nghiêm trọng Hợp Đồng Mở TKCK
                            hoặc quy định của CASC trong quá trình sử dụng TKCK
                            và vi phạm đó không được khắc phục trong vòng 15
                            (mười lăm) ngày từ ngày Khách Hàng nhận được thông
                            báo bằng văn bản về vi phạm đó từ CASC;
                          </il>
                          <br />
                          <il>
                            Khách Hàng có hành vi lừa dối, kể cả cung cấp sai
                            thông tin cho thủ tục “nhận biết Khách Hàng” hoặc mở
                            tài khoản;
                          </il>
                          <br />
                          <il>
                            CASC đề xuất sửa đổi Hợp Đồng Mở TKCK mà Khách Hàng
                            không đồng ý;
                          </il>
                          <br />
                          <il>
                            CASC ngừng thực hiện nghiệp vụ môi giới chứng khoán;
                          </il>
                          <br />
                          <il>CASC ngừng kinh doanh chứng khoán; hoặc</il>
                          <br />
                          <il>
                            CASC thực hiện tổ chức lại doanh nghiệp dẫn đến dừng
                            hoạt động và tổ chức được hình thành từ việc tổ chức
                            lại không kế thừa quyền và nghĩa vụ của CASC theo
                            Hợp Đồng Mở TKCK.
                          </il>
                        </ol>
                        CASC thực hiện quyền đơn phương này bằng cách gửi thông
                        báo bằng văn bản cho Khách Hàng. Hợp Đồng Mở TKCK sẽ
                        chấm dứt vào ngày ghi trên thông báo đó với điều kiện là
                        ngày chấm dứt không được sớm hơn 10 ngày từ ngày gửi
                        thông báo
                      </li>
                      <li>
                        Khách Hàng có quyền đơn phương chấm dứt Hợp Đồng Mở TKCK
                        nếu CASC vi phạm nghiêm trọng Hợp Đồng Mở TKCK và vi
                        phạm đó không được khắc phục trong vòng 15 (mười lăm)
                        ngày từ ngày CASC nhận được thông báo bằng văn bản về vi
                        phạm đó từ Khách Hàng. Khách Hàng thực hiện quyền đơn
                        phương này bằng cách gửi thông báo bằng văn bản cho
                        CASC. Hợp Đồng Mở TKCK sẽ chấm dứt vào ngày ghi trên
                        thông báo đó với điều kiện là ngày chấm dứt không được
                        sớm hơn 10 ngày từ ngày gửi thông báo. <br />
                        Khách Hàng cũng có quyền đơn phương chấm dứt Hợp Đồng Mở
                        TKCK vì bất kỳ lý do nào khác bằng cách gửi thông báo
                        bằng văn bản cho CASC. Hợp Đồng Mở TKCK sẽ chấm dứt vào
                        ngày ghi trên thông báo đó với điều kiện là ngày chấm
                        dứt không được sớm hơn 30 ngày từ ngày gửi thông báo.
                      </li>
                      <li>
                        Cho dù Hợp Đồng Mở TKCK này chấm dứt trong bất kỳ tình
                        huống nào trên đây, các bên sẽ cùng nhau tiến thành thủ
                        tục thanh lý để cho phép:
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            CASC chuyển giao tiền và tài sản còn trên TKCK (sau
                            khi đã khấu trừ các loại thuế, phí và nghĩa vụ còn
                            tồn đọng với CASC hoặc bên thứ ba (thông qua CASC));
                          </il>
                          <br />
                          <il>
                            Khách Hàng thanh toán đủ các khoản phí, chi phí và
                            thuế còn tồn đọng (nếu có) liên quan đến TKCK; và
                          </il>
                          <br />
                          <il>CASC đóng TKCK.</il>
                        </ol>
                      </li>
                      <li>
                        Trừ khi các bên có thỏa thuận khác, việc chấm dứt Hợp
                        Đồng Mở TKCK không làm ảnh hưởng đến quyền và nghĩa vụ
                        của các bên phát sinh trước thời điểm chấm dứt. Các quy
                        định tại Điều 12 vẫn tiếp tục áp dụng sau khi Hợp Đồng
                        Mở TKCK chấm dứt.
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>
                        Điều 12: &nbsp;&nbsp; Các Điều Khoản Bảo Mật Thông Tin,
                        Xử Lý và Bảo Vệ Dữ Liệu Cá Nhân
                      </b>
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        Mỗi bên có trách nhiệm bảo mật việc ký và nội dung Hợp
                        Đồng Mở TKCK và chỉ được tiết lộ trong các trường hợp
                        sau đây: (i) khi được bên kia chấp thuận trước, (ii)
                        nhằm tuân thủ pháp luật hoặc yêu cầu của cơ quan Nhà
                        nước có thẩm quyền hoặc giải quyết tranh chấp hoặc (iii)
                        nhằm phục vụ việc tuân thủ nội bộ, vận hành của CASC
                        hoặc phục vụ cho các mục đích theo quy định tại Khoản 5
                        Điều này.
                      </li>
                      <li>
                        Các Điều Khoản Bảo Mật Thông Tin, Xử Lý và Bảo Vệ Dữ
                        Liệu Cá Nhân sẽ được ưu tiên áp dụng trong trường hợp có
                        bất kỳ xung đột hoặc mâu thuẫn nào với các thỏa thuận,
                        điều khoản và điều kiện chi phối mối quan hệ của Khách
                        hàng với CASC, cho dù được ký kết trước, vào ngày hoặc
                        sau ngày Khách hàng chấp thuận Các Điều Khoản Bảo Mật
                        Thông Tin, Xử Lý và Bảo Vệ Dữ Liệu Cá Nhân này.
                      </li>
                      <li>
                        Khách hàng hiểu và đồng ý rằng, việc cung cấp Dữ liệu cá
                        nhân của Khách hàng cho CASC (bao gồm và không giới hạn
                        bởi các thông tin CASC đã có trước, trong và sau khi
                        Khách hàng chấp thuận Các Điều Khoản Bảo Mật Thông Tin,
                        Xử Lý và Bảo Vệ Dữ Liệu Cá Nhân này) chính là sự chấp
                        nhận toàn phần của Khách hàng cho phép CASC sử dụng Dữ
                        liệu cá nhân trong suốt quy trình tiếp nhận và xử lý Dữ
                        liệu cá nhân, bắt đầu từ khi CASC tiếp nhận thông tin
                        cho đến khi có yêu cầu chấm dứt việc xử lý dữ liệu từ
                        Khách hàng.
                      </li>
                      <li>
                        Các thông tin Dữ liệu cá nhân của Khách hàng mà CASC thu
                        thập:
                        <br />
                        Bằng việc ký kết Hợp đồng này, Khách hàng tại đây chấp
                        thuận và cho phép việc CASC được phép thu thập các thông
                        tin và dữ liệu cá nhân của Khách hàng được quy định dưới
                        đây:
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Dữ liệu cá nhân cơ bản gồm: Họ, chữ đệm và tên khai
                            sinh, tên gọi khác (nếu có); Ngày, tháng, năm sinh;
                            ngày, tháng, năm chết hoặc mất tích; Giới tính; Nơi
                            sinh, nơi đăng ký khai sinh, nơi thường trú, nơi tạm
                            trú, nơi ở hiện tại, quê quán, địa chỉ liên hệ; Quốc
                            tịch; Hình ảnh của cá nhân; Số điện thoại, số chứng
                            minh nhân dân, số định danh cá nhân, số hộ chiếu, số
                            giấy phép lái xe, số biển số xe, số mã số thuế cá
                            nhân, số bảo hiểm xã hội, số thẻ bảo hiểm y tế; Tình
                            trạng hôn nhân; Thông tin về mối quan hệ gia đình
                            (cha mẹ, con cái); Thông tin về tài khoản số của cá
                            nhân; dữ liệu cá nhân phản ánh hoạt động, lịch sử
                            hoạt động trên không gian mạng.
                          </il>
                          <br />
                          <il>
                            Một phần Dữ liệu cá nhân nhạy cảm gồm: Dữ liệu về
                            tội phạm, hành vi phạm tội được thu thập, lưu trữ
                            bởi các cơ quan thực thi pháp luật; Thông tin khách
                            hàng của tổ chức tín dụng, chi nhánh ngân hàng nước
                            ngoài, tổ chức cung ứng dịch vụ trung gian thanh
                            toán, các tổ chức được phép khác, gồm: thông tin
                            định danh khách hàng theo quy định của pháp luật,
                            thông tin về tài khoản, thông tin về tiền gửi, thông
                            tin về tài sản gửi, thông tin về giao dịch, thông
                            tin về tổ chức, cá nhân là bên bảo đảm tại tổ chức
                            tín dụng, chi nhánh ngân hàng, tổ chức cung ứng dịch
                            vụ trung gian thanh toán;
                          </il>
                        </ol>
                      </li>
                      <li>
                        Mục đích xử lý Dữ liệu cá nhân của Khách hàng:
                        <br />
                        Khách Hàng đồng ý cho CASC xử lý các dữ liệu cá nhân của
                        Khách hàng quy định tại Khoản 4 điều này (trong đó có
                        thông tin về tài khoản, tài sản và giao dịch) mà CASC
                        nhận được liên quan đến hoặc theo Hợp Đồng này, giao
                        dịch giữa Khách Hàng và CASC hay giao dịch giữa Khách
                        Hàng và bên thứ ba qua hệ thống/dịch vụ của CASC (gọi
                        chung là “<b>thông tin</b>”) để phục vụ các mục đích
                        sau:
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Nhằm cung cấp dịch vụ cho Khách Hàng (gồm cả cho
                            VSDC, các SGDCK, đối tác giao dịch của Khách Hàng và
                            bên thứ ba cung cấp dịch vụ/sản phẩm cho Khách Hàng
                            phụ trợ cho dịch vụ của CASC);
                          </il>
                          <br />
                          <il>
                            Nhằm hỗ trợ hay tạo điều kiện cho Khách Hàng sử dụng
                            sản phẩm/dịch vụ của bên thứ ba qua CASC;
                          </il>
                          <br />
                          <il>Để tuân thủ pháp luật Việt Nam;</il>
                          <br />
                          <il>
                            Liên quan đến hoạt động hợp pháp của CASC nhằm tăng
                            chất lượng dịch vụ/trải nghiệm cho Khách Hàng nói
                            chung (trong đó có hoạt động chấm điểm tín dụng
                            (credit scoring), phân tích thị trường, nghiên cứu
                            và phân tích nhu cầu Khách Hàng và các mục đích vận
                            hành và quản trị khác);
                          </il>
                          <br />
                          <il>
                            Nhằm thực hiện nghĩa vụ của CASC với cơ quan Nhà
                            nước có thẩm quyền (kể cả nhằm báo cáo hoặc cung cấp
                            thông tin theo yêu cầu của cơ quan Nhà nước có thẩm
                            quyền);
                          </il>
                          <br />
                          <il>Nhằm mục đích kiểm soát rủi ro;</il>
                          <br />
                          <il>
                            Để thuê bên thứ ba cung cấp dịch vụ xử lý dữ liệu và
                            thực hiện giao dịch, dịch vụ báo cáo, lưu ký, giám
                            sát, quản lý rủi ro và các dịch vụ khác được phép
                            với điều kiện bên thứ ba cũng phải bảo mật thông
                            tin.
                          </il>
                        </ol>
                        <br />
                        Bằng việc ký kết Hợp đồng này, Khách hàng đồng ý và
                        thống nhất rằng: CASC sẽ được quyền xử lý dữ liệu cá
                        nhân của Khách hàng để phục vụ cho mục đích nêu trên mà
                        không phải thông báo cho Khách hàng dưới bất kỳ hình
                        thức nào khác. CASC sẽ có đề nghị/hoặc thông báo cho
                        Khách hàng khi tiến hành xử lý dữ liệu cá nhân cho các
                        mục đích khác ngoài các mục đích quy định tại Khoản 5
                        Điều này.
                      </li>

                      <li>
                        Tổ chức cá nhân được tiếp cận Dữ liệu cá nhân của Khách
                        hàng:
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Nội bộ CASC: Cá nhân, đơn vị làm việc trong nội bộ
                            CASC;
                          </il>
                          <br />
                          <il>
                            Các tổ chức cá nhân khác để cung cấp dịch vụ hoặc để
                            thực hiện các mục đích theo quy định tại Khoản 5
                            Điều này.
                          </il>
                        </ol>
                      </li>
                      <li>
                        Các quyền và nghĩa vụ của Khách hàng trong xử lý dữ liệu
                        cá nhân:
                        <br />
                        Các quyền của Khách hàng:
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Quyền được biết về hoạt động xử lý Dữ liệu cá nhân
                            Khách hàng: Khách hàng được biết về hoạt động xử lý
                            dữ liệu cá nhân của mình, trừ trường hợp các hoạt
                            động xử lý Dữ liệu cá nhân Khách hàng để thực hiện
                            mục đích quy định tại Khoản 5, Điều này.
                          </il>
                          <br />
                          <il>
                            Quyền đồng ý hay không đồng ý cho phép xử lý Dữ liệu
                            cá nhân Khách hàng: Khách hàng được đồng ý hoặc
                            không đồng ý cho phép xử lý dữ liệu cá nhân của mình
                            trừ trường hợp việc cung cấp bắt buộc theo yêu cầu
                            của cơ quan nhà nước/Ủy ban chứng khoán/VSDC/HNX.
                            Khách hàng hiểu và chấp nhận rằng: trong trường hợp
                            Khách hàng không đồng ý cho phép xử lý Dữ liệu cá
                            nhân, CASC có thể không đảm bảo chất lượng dịch vụ,
                            sản phẩm cung cấp cho Khách hàng và điều này không
                            xuất phát từ lỗi của CASC, CASC không có nghĩa vụ
                            phải bồi thường với các thiệt hại phát sinh từ điều
                            này.
                          </il>
                          <br />
                          <il>
                            Quyền truy cập: Khách hàng được truy cập để xem,
                            chỉnh sửa hoặc yêu cầu chỉnh sửa dữ liệu cá nhân của
                            mình, trừ trường hợp luật có quy định khác.
                          </il>
                          <br />
                          <il>
                            Quyền chỉnh sửa: Khách hàng được xóa hoặc yêu cầu
                            xóa dữ liệu cá nhân của mình, trừ trường hợp luật có
                            quy định khác.
                          </il>
                          <br />
                          <il>
                            Quyền phản đối: Khách hàng được phản đối CASC xử lý
                            dữ liệu cá nhân của mình nhằm ngăn chặn hoặc hạn chế
                            tiết lộ dữ liệu cá nhân hoặc sử dụng cho mục đích
                            quảng cáo, tiếp thị;
                          </il>
                          <br />
                          <il>
                            Quyền rút lại sự đồng ý: Khách hàng có quyền rút lại
                            sự đồng ý cho phép CASC xử lý dữ liệu cá nhân. Việc
                            yêu cầu rút lại sự đồng ý phải được thể hiện bằng
                            văn bản có chữ ký của Khách hàng. Khách hàng
                          </il>
                          <br />
                          <il>
                            Quyền xóa thông tin Khách hàng: Khách hàng có quyền
                            yêu cầu xóa thông tin của Khách hàng trừ trường hợp
                            pháp luật có quy định khác.
                          </il>
                          <br />
                          <il>
                            Quyền khiếu nại: Khách hàng có quyên khiếu nại đến
                            CASC về việc xử lý dữ liệu cá nhân của Khách hàng.
                          </il>
                          <br />
                          <il>
                            Quyền hạn chế việc xử lý: Khách hàng được yêu cầu
                            hạn chế xử lý dữ liệu cá nhân của mình, trừ trường
                            hợp luật có quy định khác; Việc hạn chế xử lý dữ
                            liệu được thực hiện trong 72 giờ sau khi có yêu cầu
                            của Khách hàng, với toàn bộ dữ liệu cá nhân mà Khách
                            hàng yêu cầu hạn chế, trừ trường hợp luật có quy
                            định khác.
                          </il>
                        </ol>
                        <br />
                        Các nghĩa vụ của Khách hàng:
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Tuân thủ các quy định pháp luật, quy định của CASC
                            liên quan đến Xử lý Dữ liệu cá nhân của Khách hàng;
                          </il>
                          <br />
                          <il>
                            Cung cấp đầy đủ, trung thực, chính xác Dữ liệu cá
                            nhân, các thông tin khác theo yêu cầu của CASC khi
                            tìm kiếm, tiếp cận, mua, đăng ký sử dụng và sử dụng
                            dịch vụ và khi có thay đổi về các thông tin này.
                            Chúng tôi sẽ tiến hành bảo mật Dữ liệu cá nhân của
                            Khách hàng căn cứ trên thông tin Khách hàng đã cung
                            cấp. Do đó, nếu có bất kỳ thông tin sai lệch nào
                            hoặc Khách hàng không cung cấp thông tin khi thay
                            đổi, chúng tôi sẽ không chịu trách nhiệm trong
                            trường hợp thông tin đó làm ảnh hưởng hoặc hạn chế
                            quyền lợi của Khách hàng. Trường hợp không thông
                            báo, nếu có phát sinh rủi ro, tổn thất thì Khách
                            hàng chịu trách nhiệm về những sai sót hay hành vi
                            lợi dụng, lừa đảo khi sử dụng dịch vụ do lỗi của
                            Khách hàng hoặc do không cung cấp đúng, đầy đủ,
                            chính xác, kịp thời sự thay đổi thông tin; bao gồm
                            cả thiệt hại về tài chính, chi phí phát sinh do
                            thông tin cung cấp sai hoặc không thống nhất.
                          </il>
                          <br />
                          <il>
                            Phối hợp với CASC, cơ quan nhà nước có thẩm quyền
                            hoặc bên thứ ba trong trường hợp phát sinh các vấn
                            đề ảnh hưởng đến tính bảo mật Dữ liệu cá nhân của
                            Khách hàng.
                          </il>
                          <br />
                          <il>
                            Tự bảo vệ Dữ liệu cá nhân của Khách hàng; yêu cầu
                            các tổ chức, cá nhân khác có liên quan bảo vệ Dữ
                            liệu cá nhân của Khách hàng; chủ động áp dụng các
                            biện pháp nhằm bảo vệ Dữ liệu cá nhân của Khách hàng
                            trong quá trình sử dụng dịch vụ của CASC; thông báo
                            kịp thời cho chúng tôi khi phát hiện thấy có sai
                            sót, nhầm lẫn về Dữ liệu cá nhân hoặc nghi ngờ Dữ
                            liệu cá nhân đang bị xâm phạm.
                          </il>
                          <br />
                          <il>
                            Tự chịu trách nhiệm đối với những thông tin, dữ liệu
                            mà Khách hàng tạo lập, cung cấp trên không gian
                            mạng; tự chịu trách nhiệm trong trường hợp Dữ liệu
                            cá nhân bị rò rỉ, xâm phạm do lỗi của mình.
                          </il>
                          <br />
                          <il>
                            Thường xuyên cập nhật và tuân thủ các quy định,
                            chính sách liên quan đến việc bảo vệ và Xử lý Dữ
                            liệu cá nhân của CASC trong từng thời kỳ được thông
                            báo tới Khách hàng qua Kênh giao dịch của CASC.
                          </il>
                          <br />
                          <il>
                            Khi sử dụng bất kỳ dịch vụ, sản phẩm hoặc truy cập
                            bất kỳ trang tin điện tử, ứng dụng hoặc thiết bị của
                            CASC hoặc được kết nối đến CASC, Khách hàng được coi
                            là đã chấp nhận toàn bộ Điều khoản xử lý và bảo vệ
                            dữ liệu cá nhân này. Khách hàng đồng ý và cam kết áp
                            dụng, phối hợp và tuân thủ Điều khoản xử lý và bảo
                            vệ dữ liệu cá nhân do CASC triển khai.
                          </il>
                          <br />
                          <il>
                            Trong trường hợp Khách hàng rút lại sự đồng ý của
                            mình, yêu cầu xóa dữ liệu và/hoặc thực hiện các
                            quyền có liên quan khác đối với bất kỳ hoặc tất cả
                            các Dữ liệu cá nhân của Khách hàng, CASC có thể xem
                            xét và quyết định việc không tiếp tục cung cấp các
                            sản phẩm, dịch vụ của CASC cho Khách hàng do không
                            thể đảm bảo tiêu chuẩn/chất lượng của sản phẩm, dịch
                            vụ theo đánh giá của CASC hoặc do quy định của pháp
                            luật cần phải thu thập Dữ liệu cá nhân của Khách
                            hàng khi cung cấp sản phẩm, dịch vụ. Nếu CASC quyết
                            định không cung cấp sản phẩm, dịch vụ cho Khách hàng
                            trong trường hợp này được xem là sự đơn phương chấm
                            dứt từ phía Khách hàng. Các vấn đề phát sinh từ việc
                            chấm dứt Hợp đồng này sẽ xử lý theo quy định tại Hợp
                            đồng này và quy định của pháp luật.
                          </il>
                          <br />
                          <il>Các nghĩa vụ khác theo quy định pháp luật.</il>
                        </ol>
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>Điều 13: &nbsp;&nbsp; Luật Điều Chỉnh</b>
                    </p>
                    <p className="my-0">
                      Hợp Đồng Mở TKCK được điều chỉnh và giải thích theo pháp
                      luật Việt Nam.
                    </p>
                    <p className="my-1">
                      <b>Điều 14: &nbsp;&nbsp; Giải Quyết Tranh Chấp</b>
                    </p>
                    <p className="my-0">
                      Mọi tranh chấp phát sinh từ hoặc liên quan đến Hợp Đồng Mở
                      TKCK sẽ được giải quyết trước hết bằng thương lượng. Nếu
                      tranh chấp không giải quyết được bằng thương lượng trong
                      vòng 30 (ba mươi) ngày từ ngày bắt đầu thương lượng, bất
                      kỳ bên nào cũng có quyền đưa tranh chấp ra giải quyết tại
                      tòa án có thẩm quyền của Việt Nam.
                    </p>

                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <td
                            className="bg-casc py-1"
                            style={{ width: "75px" }}
                          >
                            PHẦN II:
                          </td>
                          <td className="bg-casc py-1">
                            QUY ĐỊNH VỀ ỨNG TRƯỚC TIỀN BÁN CHỨNG KHOÁN
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p className="my-1">
                      <b>Điều 15: &nbsp;&nbsp; Giải Thích Từ Ngữ</b>
                    </p>
                    <p className="my-0">
                      Trong Phần II này, các từ ngữ dưới đây có nghĩa như sau:
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        <b>Lệnh bán</b>: Là lệnh do Khách Hàng lập và gửi cho
                        CASC để bán chứng khoán.
                      </li>
                      <li>
                        <b>Khớp lệnh</b>: Là việc Lệnh bán được đưa vào hệ thống
                        giao dịch của SGDCK và đã được khớp thành công.
                      </li>
                      <li>
                        <b>Tiền bán chứng khoán</b>: là số tiền Khách Hàng sẽ
                        được nhận từ việc bán chứng khoán theo Lệnh bán có liên
                        quan và đã được CASC xác nhận là giao dịch khớp lệnh
                        thành công.
                      </li>
                      <li>
                        <b>Phí ứng trước</b>: Là khoản phí Khách Hàng phải trả
                        cho CASC do sử dụng dịch vụ ứng trước tiền bán chứng
                        khoán theo biểu phí do CASC quy định trong từng thời kỳ.
                      </li>
                      <li>
                        <b>Số tiền ứng trước</b>: Là số tiền CASC chuyển vào
                        TKCK trên cơ sở yêu cầu ứng trước của Khách Hàng.
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>Điều 16: &nbsp;&nbsp; Phạm Vi Áp Dụng</b>
                    </p>
                    <p className="my-0">
                      Phần II này áp dụng cho việc ứng trước tiền bán chứng
                      khoán trên toàn bộ TKCK (kể cả tài khoản giao dịch chứng
                      khoán thông thường và tài khoản giao dịch chứng khoán ký
                      quỹ).
                    </p>
                    <p className="my-1">
                      <b>
                        Điều 17: &nbsp;&nbsp; Điều Kiện Và Cách Thức Thực Hiện
                      </b>
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        Khi đăng ký mở TKCK, Khách Hàng mặc định đồng ý sử dụng
                        dịch vụ ứng trước tiền bán chứng khoán của CASC. Trên cơ
                        sở yêu cầu của Khách Hàng (đã được lập phù hợp), CASC có
                        quyền nhưng không có nghĩa vụ ứng trước tiền bán chứng
                        khoán cho Khách Hàng.
                      </li>
                      <li>
                        CASC có thể tự động ứng trước tiền bán chứng khoán trên
                        TKCK nếu Khách Hàng có nghĩa vụ tài chính đến hạn với
                        CASC, kể cả nghĩa vụ phải thanh toán trên tài khoản giao
                        dịch chứng khoán ký quỹ.
                      </li>
                      <li>
                        Trong mọi trường hợp, Số tiền ứng trước không vượt quá
                        Tiền bán chứng khoán trừ phí giao dịch, Phí ứng trước,
                        thuế và phí khác theo quy định pháp luật (nếu có).
                      </li>
                      <li>
                        Bằng việc gửi yêu cầu ứng trước tiền bán chứng khoán và
                        phụ thuộc vào việc được CASC ứng trước theo yêu cầu đó,
                        Khách Hàng chuyển giao vô điều kiện cho CASC quyền nhận
                        Tiền bán chứng khoán có liên quan. CASC được nhận Tiền
                        bán chứng khoán để bù trừ nghĩa vụ Khách Hàng hoàn trả
                        cho CASC toàn bộ Số tiền ứng trước và Phí ứng trước.
                      </li>
                    </ul>
                    <p className="my-1">
                      <b>
                        Điều 18: &nbsp;&nbsp; Quyền Và Nghĩa Vụ Của Khách Hàng
                      </b>
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        Khách Hàng có quyền gửi yêu cầu ứng trước tiền bán chứng
                        khoán khi đáp ứng điều kiện như đã nêu tại Điều 17.
                      </li>
                      <li>
                        Khách Hàng đồng ý để CASC được tự động khấu trừ tiền từ
                        TKCK để hoàn trả toàn bộ Số tiền ứng trước và Phí ứng
                        trước. Khách Hàng cam kết không đưa ra khiếu nại, khiếu
                        kiện về việc CASC khấu trừ tiền từ TKCK như vậy.
                      </li>
                      <li>
                        Nếu kết quả khớp lệnh bị hủy thanh toán theo thông báo
                        của VSDC vì bất kỳ lý do nào thì Khách Hàng phải hoàn
                        trả lại cho CASC toàn bộ Số tiền ứng trước cùng với phí,
                        lệ phí phát sinh đến thời điểm hoàn trả theo quy định
                        của CASC.
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>Điều 19: &nbsp;&nbsp; Quyền Và Nghĩa Vụ Của CASC</b>
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        CASC được tự động khấu trừ TKCK để thực hiện nghĩa vụ
                        của Khách Hàng liên quan đến việc ứng trước tiền bán
                        chứng khoán mà không cần có lệnh hay chấp thuận của
                        Khách Hàng.
                      </li>
                      <li>
                        CASC được đơn phương ngưng cung cấp dịch vụ cho Khách
                        Hàng nếu CASC xét thấy việc cung cấp dịch vụ có thể gây
                        rủi ro, tổn thất cho CASC hoặc việc cung cấp dịch vụ
                        không phù hợp với chính sách, điều kiện hoạt động của
                        CASC.
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>Điều 20: &nbsp;&nbsp; Quy Định Khác</b>
                    </p>
                    <p className="my-0">
                      Tùy từng thời kỳ, dịch vụ ứng trước tiền bán chứng khoán
                      có thể thực hiện qua nguồn của CASC hoặc bên thứ ba. Khách
                      Hàng đồng ý rằng nếu việc ứng trước thực hiện qua nguồn
                      của bên thứ ba, ngoài việc tuân thủ Phần II này, Khách
                      Hàng cũng sẽ tuân thủ quy định của bên thứ ba đó về việc
                      ứng trước tiền bán chứng khoán.
                    </p>

                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <td
                            className="bg-casc py-1"
                            style={{ width: "75px" }}
                          >
                            PHẦN III:
                          </td>
                          <td className="bg-casc py-1">
                            THỎA THUẬN GIAO DỊCH ĐIỆN TỬ
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <p className="my-1">
                      <b>Điều 21: &nbsp;&nbsp; Giải Thích Từ Ngữ</b>
                    </p>
                    <p className="my-0">
                      Trong Phần III này, các từ ngữ dưới đây có nghĩa như sau:
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        <b>Chấp thuận điện tử</b>: Là chấp thuận, phê duyệt, cho
                        phép, đồng ý hoặc tương đương do Khách Hàng lập và gửi
                        qua Hệ thống giao dịch điện tử, từ Số điện thoại hoặc từ
                        Thư điện tử. Chấp thuận điện tử có thể được thể hiện
                        bằng lời nói và được Khách Hàng lập bằng việc gọi điện
                        đến Tổng đài giao dịch qua điện thoại (có ghi âm).
                      </li>
                      <li>
                        <b>Chữ ký số</b>: Là chữ ký số (hay còn gọi là chữ ký
                        điện tử) theo quy định của pháp luật về giao dịch điện
                        tử.
                      </li>
                      <li>
                        <b>Hệ thống giao dịch điện tử</b>: Là tập hợp các Ứng
                        dụng thiết bị điện tử, Trading, Tổng đài giao dịch qua
                        điện thoại và hệ thống khác được CASC sử dụng để cho
                        phép Khách Hàng truy cập vào TKCK và thực hiện các Thao
                        tác/giao dịch trên hệ thống thông qua mạng Internet hoặc
                        qua điện thoại.
                      </li>
                      <li>
                        <b>Mật khẩu</b>: Là tên truy cập (user), tên định danh
                        (identification), mật khẩu dùng nhiều lần, mật khẩu dùng
                        một lần (one-time password - OTP), khóa token (token
                        key), mã xác thực (authentication code), mã PIN
                        (personal identification number), đặc điểm sinh trắc học
                        (vân tay, mống mắt hay khuôn mặt) và phương thức/thông
                        điệp xác thực khác cấp cho Khách Hàng hoặc Khách Hàng
                        tạo, chọn hay đăng ký sử dụng liên quan đến TKCK.
                      </li>
                      <li>
                        <b>Trading</b>: Là phần của trang thông tin điện tử của
                        CASC mà Khách Hàng có thể truy cập vào TKCK để thực hiện
                        một số hoặc tất cả Thao tác/giao dịch trên hệ thống.
                      </li>
                      <li>
                        <b>Hợp đồng điện tử</b>: Là hợp đồng, thỏa thuận, cam
                        kết, bản ghi nhớ, phụ lục hợp đồng hoặc tương đương được
                        thể hiện dưới hình thức trao đổi dữ liệu điện tử và được
                        ký, ký kết và chuyển giao thông qua Hệ thống giao dịch
                        điện tử (trừ Tổng đài giao dịch qua điện thoại).
                      </li>
                      <li>
                        <b>Lệnh điện tử</b>: Là lệnh do Khách Hàng lập gửi qua
                        Hệ thống giao dịch điện tử. Lệnh điện tử có thể được thể
                        hiện bằng lời nói và được Khách Hàng lập bằng việc gọi
                        điện đến Tổng đài giao dịch qua điện thoại (có ghi âm).
                        Lệnh điện tử có thể được thể hiện qua một thao tác hoặc
                        chuỗi thao tác trên Hệ thống giao dịch điện tử (ví dụ
                        lệnh chuyển tiền có thể được thiết lập trên cơ sở thao
                        tác chuyển tiền trên TKCK).
                      </li>

                      <li>
                        <b>Thao tác/giao dịch trên hệ thống</b> gồm:
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Truy cập, xem và kiểm tra thông tin và các thao tác
                            khác trên TKCK;
                          </il>
                          <br />
                          <il>Gửi, nhận, xem và đọc Thông điệp dữ liệu;</il>
                          <br />
                          <il>Tạo lập và/hoặc gửi/xác nhận Lệnh điện tử;</il>
                          <br />
                          <il>
                            Tạo lập và/hoặc gửi/xác nhận Chấp thuận điện tử;
                          </il>
                          <br />
                          <il>Xem, từ chối hay ký kết Hợp đồng điện tử; và</il>
                          <br />
                          <il>
                            Các thao tác khác có trên Hệ thống giao dịch điện
                            tử.
                          </il>
                        </ol>
                      </li>
                      <li>
                        <b>Thông điệp dữ liệu</b>: Là thông tin liên lạc, thông
                        báo, báo cáo danh mục, báo cáo đầu tư, báo cáo phân
                        tích, báo cáo thuộc loại khác, sao kê tài khoản, sao kê
                        giao dịch, bản công bố thông tin, bản cáo bạch, tuyên bố
                        và thông điệp thuộc loại khác dưới dạng thông điệp dữ
                        liệu (điện tử) được gửi qua Hệ thống giao dịch điện tử,
                        qua điện thoại hoặc tin nhắn (SMS) đến Số điện thoại
                        và/hoặc qua Thư điện tử.
                      </li>
                      <li>
                        <b>Tổng đài giao dịch qua điện thoại</b>: Là hệ thống
                        tổng đài điện thoại có chức năng ghi âm, quản lý, tra
                        cứu cuộc gọi và có dự phòng của CASC.
                      </li>
                      <li>
                        <b>Ứng dụng thiết bị điện tử</b>: Là ứng dụng
                        (application), phần mềm (software) do CASC hoặc đối tác
                        của CASC sở hữu hoặc được cấp quyền sử dụng và phát hành
                        trên website của CASC, Google Play Store, App Store hoặc
                        kho ứng dụng/phần mềm khác để Khách Hàng tải về và cài
                        đặt trên điện thoại, máy tính (computer) hay thiết bị
                        điện tử khác nhằm truy cập vào TKCK và thực hiện một số
                        hoặc tất cả Thao tác/giao dịch trên hệ thống thông qua
                        mạng Internet.
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>Điều 22: &nbsp;&nbsp; Đối Tượng Điều Chỉnh</b>
                    </p>
                    <p className="my-0">
                      Phần III này điều chỉnh việc Khách Hàng sử dụng Hệ thống
                      giao dịch điện tử để (i) nhận, đọc và gửi Thông điệp dữ
                      liệu; (ii) đưa ra và gửi Lệnh điện tử; (iii) đưa ra và gửi
                      Chấp thuận điện tử; (iv) ký kết và chuyển giao Hợp đồng
                      điện tử và (v) thực hiện các Thao tác/giao dịch trên hệ
                      thống khác. Phần III này cũng điều chỉnh việc sử dụng Số
                      điện thoại và Thư điện tử cho một số mục đích nhất định.
                    </p>
                    <p className="my-1">
                      <b>Điều 23: &nbsp;&nbsp; Quan Hệ Với Thỏa Thuận Khác</b>
                    </p>
                    <p className="my-0">
                      Phần III này bổ sung cho các hợp đồng, thỏa thuận khác
                      giữa CASC và Khách Hàng trong phạm vi liên quan đến đối
                      tượng điều chỉnh của Phần III này. Nếu có sự không thống
                      nhất giữa Phần III này và bất kỳ hợp đồng, thỏa thuận nào
                      liên quan đến đối tượng điều chỉnh của Phần III này thì
                      Phần III này sẽ được ưu tiên áp dụng.
                    </p>

                    <p className="my-1">
                      <b>Điều 24: &nbsp;&nbsp; Hệ Thống Giao Dịch Điện Tử</b>
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        Ứng dụng thiết bị điện tử, Hệ Thống Website, Tổng đài
                        giao dịch qua điện thoại hay hệ thống khác thuộc Hệ
                        thống giao dịch điện tử sẽ là ứng dụng, phần mềm hay hệ
                        thống được CASC công bố công khai trên trang thông tin
                        điện tử của mình hoặc theo cách khác trong từng thời kỳ
                        hoặc được ghi trên Hợp Đồng Mở TKCK.
                      </li>
                      <li>
                        Khi tải và cài đặt Ứng dụng thiết bị điện tử, Website
                        hay sử dụng hệ thống khác thuộc Hệ thống giao dịch điện
                        tử và thực hiện việc truy cập vào TKCK, Khách Hàng mặc
                        nhiên chấp nhận việc sử dụng ứng dụng, phần mềm hay hệ
                        thống đó như là một phần của Hệ thống giao dịch điện tử
                        cho các mục đích được quy định trong Phần III này.
                      </li>
                      <li>
                        Trừ khi các bên có thỏa thuận khác trong phần khác của
                        Hợp Đồng Mở TKCK, bằng Phần III này, Khách Hàng đồng ý
                        sử dụng Tổng đài giao dịch qua điện thoại để đặt/gửi một
                        số Lệnh điện tử và Chấp thuận điện tử và thực hiện một
                        số Thao tác/giao dịch trên hệ thống giới hạn khác liên
                        quan đến TKCK. Trừ trường hợp được CASC đồng ý khác đi,
                        mọi Thao tác/giao dịch trên hệ thống của Khách Hàng qua
                        Tổng đài giao dịch qua điện thoại phải được thực hiện từ
                        Số điện thoại.
                      </li>
                      <li>
                        Khi Khách Hàng lựa chọn bất kỳ phím/nút chức năng trên
                        Hệ thống giao dịch điện tử, Khách Hàng được coi là đã
                        thể hiện ý chí của mình theo nội dung hiển thị trên
                        phím/nút đó và chấp nhận sự ràng buộc bởi lựa chọn đó.
                        Ví dụ, nếu Khách Hàng lựa chọn phím “Đồng ý” (hay
                        “Agree”), “Ký” (hay “Sign”) hoặc “Xác nhận” (hay
                        “Confirm”) ngay bên dưới một Hợp đồng điện tử thì Khách
                        Hàng được coi là đã ký kết Hợp đồng điện tử đó. Khách
                        Hàng cũng bị ràng buộc bởi bất kỳ thao tác nào khác mà
                        Khách Hàng thực hiện trên Hệ thống giao dịch điện tử, kể
                        cả đặt/gửi Lệnh điện tử bằng cách gọi điện đến Tổng đài
                        giao dịch qua điện thoại.
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>Điều 25: &nbsp;&nbsp; Nhận Và Gửi Thông Tin</b>
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        <b>Nhận Thông Tin:</b>
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Mọi Thông điệp dữ liệu từ CASC hoặc từ tổ chức/cá
                            nhân khác thông qua CASC sẽ được coi là gửi hợp lệ
                            cho Khách Hàng nếu được gửi theo một trong các
                            phương thức sau:
                            <br />
                            <pl className="counter-roman">
                              <jl>
                                Gửi qua Hệ thống giao dịch điện tử đến địa chỉ
                                là tên truy cập TKCK (trong trường hợp này, thời
                                điểm Khách Hàng nhận được Thông điệp dữ liệu là
                                thời điểm ghi nhận trong Hệ thống giao dịch điện
                                tử); hoặc
                              </jl>
                              <br />
                              <jl>
                                Gửi đến Thư điện tử (trong trường hợp này, thời
                                điểm Khách Hàng nhận được Thông điệp dữ liệu là
                                khi thư có liên quan được gửi vào đúng địa chỉ
                                Thư điện tử và thư gửi đã được lưu vào mục “Thư
                                đi” (Sent) trong hòm thư của người gửi và trong
                                vòng 24h kể từ thời điểm thư đó được lưu, người
                                gửi không nhận được thông báo tự động nào về
                                việc thư đó không được gửi thành công).
                              </jl>
                            </pl>
                          </il>
                          <il>
                            Mật khẩu mà CASC cấp cho Khách Hàng để thực hiện
                            Thao tác/giao dịch trên hệ thống cũng có thể được
                            gửi cho Khách Hàng theo một trong các phương thức
                            trên.
                          </il>
                          <br />
                          <il>
                            CASC cũng có thể gửi thông báo cho Khách Hàng bằng
                            cách gọi điện đến Số điện thoại và có ghi âm. Thời
                            điểm Khách Hàng nhận được thông báo là khi cuộc gọi
                            kết thúc. Nếu thông báo đã được truyền tải bằng cách
                            gọi điện phải được lập bằng văn bản (theo thỏa thuận
                            hoặc theo yêu cầu của pháp luật) thì sau khi gọi
                            điện, CASC phải gửi Thông điệp dữ liệu chứa thông
                            báo đó theo một trong các phương thức trên đây để
                            Khách Hàng lưu giữ.
                          </il>
                        </ol>
                      </li>
                      <li>
                        <b>Xem Và Đọc Thông Tin:</b>
                        <br />
                        Khách Hàng có thể xem và đọc Thông điệp dữ liệu hiển thị
                        trên Hệ thống giao dịch điện tử thay cho việc CASC cung
                        cấp tài liệu in trực tiếp để Khách Hàng xem và đọc. Việc
                        Khách Hàng xem và đọc Thông điệp dữ liệu hiển thị trên
                        Hệ thống giao dịch điện tử như vậy có giá trị pháp lý
                        như việc CASC cung cấp tài liệu in trực tiếp cho Khách
                        Hàng (trừ khi có sai sót trong nội dung hiển thị do lỗi
                        kỹ thuật).
                      </li>
                      <li>
                        <b>Gửi Thông Tin:</b>
                        <br />
                        Khách Hàng có thể gửi Thông điệp dữ liệu cho CASC hay
                        cho tổ chức/cá nhân khác thông qua CASC qua Hệ thống
                        giao dịch điện tử (trừ Tổng đài giao dịch qua điện
                        thoại). Thời điểm Khách Hàng gửi đi là thời điểm được
                        ghi nhận trong Hệ thống giao dịch điện tử.
                      </li>
                      <li>
                        <b>Giá Trị Của Thông Tin</b>
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Mật khẩu mà CASC cấp cho Khách Hàng để thực hiện
                            Thao tác/giao dịch trên hệ thống cũng có thể được
                            gửi cho Khách Hàng theo một trong các phương thức
                            trên.
                          </il>
                          <br />
                          <il>
                            Trường hợp pháp luật hay hợp đồng/thỏa thuận của các
                            bên yêu cầu thông tin phải được thể hiện bằng văn
                            bản thì Thông điệp dữ liệu được xem là đáp ứng yêu
                            cầu này;
                          </il>
                          <br />
                          <il>Thông điệp dữ liệu có giá trị như bản gốc; và</il>
                          <br />
                          <il>
                            Thông điệp dữ liệu có giá trị làm chứng cứ và giá
                            trị này không thể bị phủ nhận chỉ vì đó là một thông
                            điệp dữ liệu.
                          </il>
                        </ol>
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>
                        Điều 26: &nbsp;&nbsp; Giao Kết, Chuyển Giao Và Thực Hiện
                        Hợp Đồng Điện Tử
                      </b>
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        Hợp đồng điện tử có thể được ký kết trên Hệ thống giao
                        dịch điện tử theo các thao tác ghi trên đó. Việc ký kết
                        Hợp đồng điện tử qua Hệ thống giao dịch điện tử phải
                        được xác thực theo quy định tại Điều 28.
                      </li>
                      <li>
                        Hợp đồng điện tử có thể được ký bằng Chữ ký số của một
                        bên và Hợp đồng điện tử đã ký bằng Chữ ký số được coi là
                        được chuyển giao hợp lệ nếu được gửi theo một trong các
                        phương thức quy định tại Điều 25.
                      </li>
                      <li>
                        Việc ký kết Hợp đồng điện tử có thể theo một trong các
                        hình thức sau:
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Ký kết toàn văn: Toàn văn Hợp đồng điện tử được hiển
                            thị trên Hệ thống giao dịch điện tử và mỗi bên ký
                            bằng thao tác chọn phím/nút chức năng trên Hệ thống
                            giao dịch điện tử;
                          </il>
                          <br />
                          <il>
                            Ký chấp nhận: Một bên gửi đề nghị giao kết hợp đồng
                            và bên kia ký chấp nhận giao kết hợp đồng cũng bằng
                            thao tác chọn phím/nút chức năng trên Hệ thống giao
                            dịch điện tử hoặc bằng việc không phản đối đề nghị
                            giao kết trong thời hạn nêu tại đề nghị giao kết;
                            hoặc
                          </il>
                          <br />
                          <il>
                            Hình thức khác được các bên thỏa thuận hoặc có sẵn
                            trên Hệ thống giao dịch điện tử.
                          </il>
                        </ol>
                      </li>
                      <li>
                        Thời điểm một bên ký kết Hợp đồng điện tử sẽ là thời
                        điểm ghi nhận trên Hệ thống giao dịch điện tử hoặc hệ
                        thống khác của CASC.
                      </li>
                      <li>
                        Hợp đồng điện tử được ký kết theo Điều 26 này có giá trị
                        pháp lý đầy đủ và ràng buộc các bên ký kết như một hợp
                        đồng, thỏa thuận được ký kết theo phương thức ký trực
                        tiếp truyền thống. Giá trị pháp lý của Hợp đồng điện tử
                        không thể bị phủ nhận chỉ vì hợp đồng đó được thể hiện
                        dưới dạng thông điệp dữ liệu.
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>
                        Điều 27: &nbsp;&nbsp; Đặt Lệnh Và Gửi Chấp Thuận Điện Tử
                      </b>
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        Khách Hàng có thể đặt Lệnh điện tử và gửi Chấp thuận
                        điện tử qua Hệ thống giao dịch điện tử. Thời điểm Khách
                        Hàng đặt Lệnh điện tử hay gửi Chấp thuận điện tử là thời
                        điểm được ghi nhận trong Hệ thống giao dịch điện tử.
                      </li>
                      <li>
                        Khách Hàng có thể đặt/gửi Lệnh điện tử hay gửi Chấp
                        thuận điện tử bằng cách gọi từ Số điện thoại đến Tổng
                        đài giao dịch qua điện thoại. Mọi cuộc gọi của Khách
                        Hàng đến Tổng đài giao dịch qua điện thoại sẽ được ghi
                        âm lưu trữ đầy đủ, đảm bảo an ninh thông tin và bảo mật
                        dữ liệu. Nếu có yêu cầu của CASC hay pháp luật, sau khi
                        gọi điện đến Tổng đài giao dịch qua điện thoại, Khách
                        Hàng phải gửi lệnh, văn bản chấp thuận được ký bằng tay
                        hoặc Lệnh điện tử, Chấp thuận điện tử được đặt/gửi theo
                        phương thức khác chứa đựng Lệnh điện tử, Chấp thuận điện
                        tử được đặt hay đưa ra trong cuộc gọi.
                      </li>
                      <li>
                        Nếu Khách Hàng đăng ký và được CASC chấp thuận (hoặc các
                        bên có thỏa thuận), Khách Hàng có thể đặt/gửi Lệnh điện
                        tử hay gửi Chấp thuận điện tử từ Thư điện tử.
                      </li>
                      <li>
                        CASC chỉ phải thực hiện Lệnh điện tử được gửi hợp lệ từ
                        Khách Hàng sau khi thực hiện giải pháp xác thực quy định
                        tại Điều 28 dưới đây.
                      </li>
                      <li>
                        Lệnh điện tử hay Chấp thuận điện tử chỉ có giá trị nếu
                        có đủ thông tin cần thiết theo yêu cầu của pháp luật và
                        CASC.
                      </li>
                      <li>
                        Khách Hàng đồng ý rằng việc gửi Lệnh điện tử hay Chấp
                        thuận điện tử có thể cấu thành giao kết Hợp đồng điện tử
                        với CASC hay đối tác có liên quan.
                      </li>
                      <li>
                        Khách Hàng đồng ý rõ ràng rằng khi Lệnh điện tử hay Chấp
                        thuận điện tử được đặt, gửi và chuyển cho CASC phù hợp
                        với Điều 27 này:
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Lệnh điện tử hay Chấp thuận điện tử đó có giá trị
                            pháp lý và ràng buộc đầy đủ đối với Khách Hàng;
                          </il>
                          <br />
                          <il>
                            CASC được hành động theo Lệnh điện tử hay Chấp thuận
                            điện tử đó (Khách Hàng ủy quyền cho CASC toàn quyền
                            thực hiện toàn bộ các giao dịch cần thiết, lập, ký
                            thay các hợp đồng, thỏa thuận, phiếu lệnh hoặc các
                            hóa đơn, chứng từ và các giấy tờ khác có liên quan
                            trên TKCK của Khách Hàng nhằm hành động theo Lệnh
                            điện tử hay Chấp thuận điện tử nêu trên; CASC sẽ
                            hoàn thành việc lập, ký thay các hồ sơ, chứng từ có
                            liên quan nêu trên trong vòng 03 (ba) ngày giao dịch
                            từ ngày Khách Hàng đặt Lệnh điện tử hay gửi Chấp
                            thuận điện tử);
                          </il>
                          <br />
                          <il>
                            Nếu CASC nhận được bất kỳ phiếu lệnh hay văn bản nào
                            từ Khách Hàng theo phương thức khác (ví dụ nộp trực
                            tiếp) mà có nội dung khác với Lệnh điện tử hay Chấp
                            thuận điện tử đã được gửi trước đó thì CASC vẫn được
                            hành động theo Lệnh điện tử hay Chấp thuận điện tử
                            trừ khi CASC và Khách Hàng đã thống nhất được việc
                            hủy Lệnh điện tử hay Chấp thuận điện tử đó; và
                          </il>
                          <br />
                          <il>
                            CASC không phải chịu trách nhiệm với Khách Hàng về
                            bất kỳ hành động hay không hành động nào mà CASC
                            thực hiện theo Lệnh điện tử hay Chấp thuận điện tử
                            đó (kể cả hành động chuyển tiền, bán chứng khoán mà
                            CASC tiến hành dựa trên Lệnh điện tử hày Chấp thuận
                            điện tử đó).
                          </il>
                        </ol>
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>Điều 28: &nbsp;&nbsp; Quy Định Về Xác Thực</b>
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        Đối với việc giao kết Hợp đồng điện tử hay chấp nhận
                        Lệnh điện tử/Chấp thuận điện tử, CASC sẽ áp dụng giải
                        pháp xác thực có độ an toàn tối thiểu tương đương giải
                        pháp xác thực hai yếu tố trở lên.
                      </li>
                      <li>
                        Khách Hàng phải lựa chọn giải pháp xác thực do CASC cung
                        cấp khi mở TKCK có liên quan và đăng ký sử dụng dịch vụ
                        giao dịch chứng khoán trực tuyến/điện tử và được đăng ký
                        lại giải pháp xác thực khi có nhu cầu. Nếu Khách Hàng
                        không đăng ký thì CASC được ấn định giải pháp xác thực
                        áp dụng cho Khách Hàng.
                      </li>
                      <li>
                        Hợp đồng điện tử chỉ được coi là giao kết bởi Khách Hàng
                        một khi được Khách Hàng ký kết phù hợp với Điều 26 và
                        tất cả thông tin do Khách Hàng cung cấp theo giải pháp
                        xác thực nêu trên đã được so khớp với thông tin Khách
                        Hàng đã đăng ký và lưu trong Hệ thống giao dịch điện tử
                        hoặc thông tin được khởi tạo bởi Hệ thống giao dịch điện
                        tử (áp dụng đối với thông tin xác thực là thông tin được
                        khởi tạo bởi Hệ thống giao dịch điện tử và gửi cho Khách
                        Hàng như mật khẩu sử dụng một lần (OTP) hay mã xác nhận
                        (authentication code)). Quy định này cũng áp dụng tương
                        tự cho việc gửi và thực hiện Lệnh điện tử.
                      </li>
                      <li>
                        Trừ trường hợp pháp luật có yêu cầu rõ ràng, việc xác
                        thực không áp dụng đối với Chấp thuận điện tử hay Thông
                        điệp dữ liệu.
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>Điều 29: &nbsp;&nbsp; Quyền Và Nghĩa Vụ Của Các Bên</b>
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        <b>Quyền Và Nghĩa Vụ Của CASC:</b>
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            CASC có trách nhiệm xây dựng Hệ thống giao dịch điện
                            tử đáp ứng được yêu cầu của pháp luật để thực hiện
                            giao dịch điện tử, giao dịch chứng khoán trực tuyến.
                          </il>
                          <br />
                          <il>
                            CASC phải lưu trữ thông tin, dữ liệu, văn bản, tài
                            liệu liên quan đến Khách Hàng (kể cả Thông điệp dữ
                            liệu, Lệnh điện tử, Chấp thuận điện tử và Hợp đồng
                            điện tử) theo đúng yêu cầu của pháp luật.
                          </il>
                          <br />
                          <il>
                            CASC có toàn quyền bổ sung, xóa bớt tính năng, điều
                            chỉnh giao diện, thay đổi cấu trúc hoặc thực hiện
                            bất kỳ điều chỉnh/thay đổi nào khác đối với Hệ thống
                            giao dịch điện tử mà không cần thông báo cho Khách
                            Hàng (trừ trường hợp pháp luật yêu cầu phải thông
                            báo) (trong trường hợp này, bằng việc tiếp tục sử
                            dụng Hệ thống giao dịch điện tử đã được điều
                            chỉnh/thay đổi như vậy, Khách Hàng được coi là chấp
                            nhận toàn bộ thay đổi/điều chỉnh đó).
                          </il>
                          <br />
                          <il>
                            CASC được thu phí cung cấp dịch vụ giao dịch điện tử
                            qua Hệ thống giao dịch điện tử theo mức phí công
                            khai trên trang thông tin điện tử của mình.
                          </il>
                          <br />
                          <il>
                            CASC không chịu trách nhiệm đối với Khách Hàng về
                            bất kỳ tổn thất, thiệt hại, chi phí, phí tổn hay
                            trách nhiệm nào mà Khách Hàng phải gánh chịu có liên
                            quan đến Hệ thống giao dịch điện tử mà:
                            <br />
                            <pl className="counter-roman">
                              <jl>
                                Phát sinh do xảy ra sự kiện bất khả kháng;
                              </jl>
                              <br />
                              <jl>
                                Phát sinh do Khách Hàng làm lộ Mật Khẩu hoặc
                                thông tin truy cập TKCK; hoặc
                              </jl>
                              <br />
                              <jl>
                                Phát sinh không do lỗi của CASC đối với Phần III
                                này hoặc pháp luật.
                              </jl>
                            </pl>
                          </il>
                          <il>
                            CASC cũng được miễn trách nhiệm đối với Khách Hàng
                            đối với bất kỳ tổn thất, thiệt hại, chi phí, phí tổn
                            hay trách nhiệm nào mà Khách Hàng phải gánh chịu có
                            liên quan đến Hệ thống giao dịch điện tử do Thao
                            tác/giao dịch trên hệ thống của Khách Hàng bị sai
                            sót, bị gián đoạn, bị cản trở hoặc bị chậm chễ do:
                            <br />
                            <pl className="counter-roman">
                              <jl>
                                Hệ thống xử lý, hệ thống đường truyền của CASC
                                gặp trục trặc hay sự cố kỹ thuật;
                              </jl>
                              <br />
                              <jl>
                                Hệ thống thông tin liên lạc, đặt lệnh, chỉ thị
                                và thực hiện lệnh, chỉ thị giữa Khách Hàng và
                                CASC bị lỗi, bị gián đoạn hoặc bị tạm dừng hoạt
                                động;
                              </jl>
                              <br />
                              <jl>
                                Hệ thống thông tin liên lạc, đặt lệnh, chỉ thị
                                và thực hiện lệnh, chỉ thị giữa CASC và bất kỳ
                                bên thứ ba nào (kể cả SGDCK hay VSDC) bị lỗi, bị
                                gián đoạn hoặc bị tạm dừng hoạt động;
                              </jl>
                              <br />
                              <jl>
                                CASC phải thực hiện thêm việc xác thực/kiểm tra
                                khi có bằng chứng hoặc lý do khác để nghi ngờ
                                Thao tác/giao dịch trên hệ thống đó không do
                                chính Khách Hàng thực hiện;
                              </jl>
                              <br />
                              <jl>Việc xác thực không thành công; hoặc</jl>
                              <br />
                              <jl>
                                Theo yêu cầu của cơ quan Nhà nước có thẩm quyền.
                              </jl>
                            </pl>
                          </il>
                          <il>
                            CASC có quyền gửi tin nhắn hoặc gọi điện đến Số điện
                            thoại hoặc gửi thư đến Thư điện tử.
                          </il>
                          <br />
                          <il>
                            CASC không đưa ra bất kỳ cam đoan hay cam kết nào về
                            (i) sự chính xác, đầy đủ hay kịp thời của Hệ thống
                            giao dịch điện tử hoặc (ii) khả năng không bị gián
                            đoạn hay mắc lỗi của Hệ thống giao dịch điện tử.
                          </il>
                          <br />
                          <il>
                            CASC có các quyền và nghĩa vụ khác quy định tại Phần
                            III này, các hợp đồng/thỏa thuận khác ký với Khách
                            Hàng và theo quy định của pháp luật Việt Nam.
                          </il>
                        </ol>
                      </li>
                      <li>
                        <b>Quyền Và Nghĩa Vụ Của Khách Hàng</b>
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Khách Hàng được cấp quyền sử dụng Hệ thống giao dịch
                            điện tử sau khi đã cài đặt và chấp thuận các điều
                            khoản và điều kiện áp dụng cho ứng dụng, phần mềm
                            hay hệ thống tương ứng (quyền sử dụng của Khách Hàng
                            là không độc quyền, không được chuyển giao và có thể
                            bị rút lại theo toàn quyền quyết định của CASC).
                          </il>
                          <br />
                          <il>
                            Khách Hàng có trách nhiệm quản lý, theo dõi, kiểm
                            soát và sử dụng TKCK và bảo đảm sự chính xác của
                            Thông điệp dữ liệu, Lệnh điện tử và Chấp thuận điện
                            tử gửi qua Hệ thống giao dịch điện tử.
                          </il>
                          <br />
                          <il>
                            Khách Hàng không được sử dụng Hệ thống giao dịch
                            điện tử để thực hiện giao dịch trái pháp luật.
                          </il>
                          <br />
                          <il>
                            Khách Hàng phải sử dụng thiết bị điện tử có hệ điều
                            hành tương thích để cài đặt và sử dụng Ứng dụng
                            thiết bị điện tử; Khách Hàng có trách nhiệm cài đặt
                            các phần mềm chống virus có uy tín trên các thiết bị
                            điện tử này.
                          </il>
                          <br />
                          <il>
                            Khách Hàng không được phát tán virus, sử dụng hay
                            phát tán phần mềm độc hại hay thực hiện bất kỳ hành
                            vi nào phá hoại Hệ thống giao dịch điện tử.
                          </il>
                          <br />
                          <il>
                            Khách Hàng phải tự ghi nhớ và chịu trách nhiệm lưu
                            giữ và bảo mật thông tin TKCK và tất cả Mật khẩu cho
                            riêng mình; Khách Hàng không được chia sẻ thông tin
                            TKCK hay Mật khẩu với bất kỳ ai khác nếu không được
                            CASC chấp thuận rõ ràng bằng văn bản.
                          </il>
                          <br />
                          <il>
                            Khách Hàng tự chịu trách nhiệm đối với mọi Thao
                            tác/giao dịch trên hệ thống được thực hiện đối với
                            TKCK (Khách Hàng công nhận rằng bất kỳ Thao tác/giao
                            dịch trên hệ thống nào được thực hiện đối với TKCK
                            đều mặc nhiên được hiểu là được chính Khách Hàng
                            thực hiện trừ trường hợp Khách Hàng đã thông báo và
                            CASC xác nhận đã nhận được thông báo của Khách Hàng
                            về việc mất Mật khẩu hoặc thiết bị điện tử có cài
                            đặt Hệ thống giao dịch điện tử).
                          </il>
                          <br />
                          <il>
                            Khách Hàng cũng tự chịu trách nhiệm đối với mọi
                            thông tin, Lệnh điện tử hay Chấp thuận điện tử được
                            đặt qua hoặc gửi đi từ Số điện thoại hay Thư điện tử
                            (Khách Hàng công nhận rằng bất kỳ mọi thông tin,
                            Lệnh điện tử hay Chấp thuận điện tử được đặt qua
                            hoặc gửi đi từ Số điện thoại hay Thư điện tử đều mặc
                            nhiên được hiểu là do chính Khách Hàng đặt và gửi đi
                            trừ trường hợp Khách Hàng đã thông báo và CASC xác
                            nhận đã nhận được thông báo của Khách Hàng về việc
                            mất điện thoại hay sim gắn với Số điện thoại hoặc
                            thiết bị điện tử có cài sẵn phần mềm hay ứng dụng để
                            gửi Thư điện tử).
                          </il>
                          <br />
                          <il>
                            Khách Hàng phải thông báo cho CASC ngay sau khi (i)
                            bị mất hoặc bị đánh cắp thiết bị điện tử cài đặt Ứng
                            dụng thiết bị điện tử hay hệ thống khác thuộc Hệ
                            thống giao dịch điện tử; (ii) bị mất hoặc bị đánh
                            cắp điện thoại hay sim gắn với Số điện thoại hoặc
                            (iii) bị mất hoặc bị đánh cắp thiết bị điện tử có
                            cài sẵn phần mềm hay ứng dụng để gửi và/hoặc nhận
                            Thư điện tử từ và/hoặc đến Thư điện tử.
                          </il>
                          <br />
                          <il>
                            Khách Hàng phải thông báo ngay cho CASC khi gửi Lệnh
                            điện tử hay Chấp thuận điện tử hay ký kết Hợp đồng
                            điện tử mà không được xác thực theo quy định tại
                            Điều 28.
                          </il>
                          <br />
                          <il>
                            Khách Hàng có trách nhiệm bồi thường thiệt hại cho
                            CASC theo quy định của pháp luật phát sinh từ:
                            <br />
                            <pl className="counter-roman">
                              <jl>Hành vi lừa dối của Khách Hàng;</jl>
                              <br />
                              <jl>
                                Hành vi vi phạm Phần III này của Khách Hàng;
                              </jl>
                              <br />
                              <jl>
                                Hành vi sử dụng sai mục đích Hệ thống giao dịch
                                điện tử; hoặc
                              </jl>
                              <br />
                              <jl>
                                Hành vi sử dụng Hệ thống giao dịch điện tử cho
                                các giao dịch hay hoạt động trái pháp luật.
                              </jl>
                            </pl>
                          </il>
                          <il>
                            Khách Hàng có các quyền và nghĩa vụ khác quy định
                            tại Phần III này, các hợp đồng/thỏa thuận khác ký
                            với CASC và theo quy đinh của pháp luật Việt Nam.
                          </il>
                        </ol>
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>Điều 30: &nbsp;&nbsp; Công Bố Rủi Ro</b>
                    </p>
                    <p className="my-0">
                      Khách Hàng đồng ý rằng việc sử dụng Hệ thống giao dịch
                      điện tử, Số điện thoại và Thư điện tử luôn gắn với rủi ro
                      và Khách Hàng đã hiểu rõ các rủi ro đó. Dưới đây là một số
                      rủi ro (Khách Hàng đồng ý rằng danh sách này không phải là
                      danh sách đầy đủ các rủi ro ảnh hưởng đến việc sử dụng Hệ
                      thống giao dịch điện tử, Số điện thoại và Thư điện tử):
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        <b>Rủi Ro Tắc Nghẽn/Gián Đoạn Hệ Thống Mạng</b>: Rủi ro
                        này thể hiện thông qua việc nghẽn đường truyền, đứt
                        đường truyền hay quá tải đường truyền. Hậu quả của rủi
                        ro này có thể là (i) Khách Hàng không thể truy cập được
                        TKCK thông qua Hệ thống giao dịch điện tử hoặc (ii) Thao
                        tác/giao dịch trên hệ thống bị chậm trễ, bị gián đoạn,
                        bị ngừng hoặc bị cản trở.
                      </li>
                      <li>
                        <b>Rủi Ro Hệ Thống Bị Tấn Công Trái Phép</b>: Rủi ro này
                        thể hiện thông qua việc thiết bị điện tử của Khách Hàng
                        cài đặt Hệ thống giao dịch điện tử, mạng nội bộ của CASC
                        hoặc mạng kết nối giữa CASC và các bên thứ ba có liên
                        quan bị nhiễm virus, bị xâm nhập, can thiệp trái phép,
                        bị chiếm quyền điều khiển hay bị tấn công. Hậu quả của
                        rủi ro này có thể là (i) Khách Hàng không thể truy cập
                        được TKCK thông qua Hệ thống giao dịch điện tử; (ii)
                        thông tin TKCK bị lộ, bị đánh cắp, bị xóa hoặc bị chỉnh
                        sửa; (iii) Thao tác/giao dịch trên hệ thống bị thực hiện
                        bởi người khác và/hoặc (iv) Thao tác/giao dịch trên hệ
                        thống do Khách Hàng thực hiện bị chỉnh sửa, bị làm sai
                        lệch, bị chậm trễ, bị gián đoạn, bị ngừng hoặc bị cản
                        trở.
                      </li>
                      <li>
                        <b>Rủi Ro Từ Lỗi Ứng Dụng/Phần Mềm</b>: Rủi ro này tồn
                        tại ở dạng có lỗi xây dựng, thiết kế phần mềm dùng để
                        chạy Hệ thống giao dịch điện tử hoặc mạng nội bộ của
                        CASC hoặc mạng kết nối giữa CASC và các bên thứ ba có
                        liên quan. Hậu quả của rủi ro này có thể là (i) Khách
                        Hàng không thể truy cập được TKCK thông qua Hệ thống
                        giao dịch điện tử, (ii) thông tin TKCK không hiển thị
                        đầy đủ hoặc sai lệch và/hoặc (iii) Thao tác/giao dịch
                        trên hệ thống bị chậm trễ, bị gián đoạn, bị ngừng hoặc
                        bị cản trở.
                      </li>
                      <li>
                        <b>Rủi Ro Con Người</b>: Rủi ro này phát sinh từ việc có
                        sự can thiệp trái phép của người khác đối với thông tin
                        TKCK hoặc truy cập trái phép TKCK. Hậu quả của rủi ro
                        này có thể là (i) Khách Hàng không thể truy cập được
                        TKCK thông qua Hệ thống giao dịch điện tử; (ii) thông
                        tin TKCK bị lộ, không hiển thị đầy đủ hoặc bị chỉnh sửa
                        và/hoặc (iii) Thao tác/giao dịch trên hệ thống bị thực
                        hiện bởi người khác không phải Khách Hàng.
                      </li>
                    </ul>

                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <td
                            className="bg-casc py-1"
                            style={{ width: "85px" }}
                          >
                            PHẦN IV:
                          </td>
                          <td className="bg-casc py-1">
                            QUY ĐỊNH VỀ TÀI KHOẢN GIAO DỊCH KÝ QUỸ
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <p className="my-1">
                      <b>Điều 31: &nbsp;&nbsp; Giải Thích Từ Ngữ</b>
                    </p>
                    <p className="my-0">
                      Trong Phần IV này, các từ ngữ dưới đây có nghĩa như sau:
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        <b>Tài khoản giao dịch ký quỹ (TKGDKQ)</b>: Là tài khoản
                        được CASC mở cho Khách Hàng khi Khách Hàng đăng ký mở
                        TKCK và sử dụng dịch vụ giao dịch ký quỹ (hoặc đăng ký
                        sau đó).
                      </li>
                      <li>
                        <b>Giao dịch ký quỹ (GDKQ)</b>: Là giao dịch Khách Hàng
                        mua chứng khoán bằng một phần vốn tự có của Khách Hàng
                        và một phần vốn CASC cho vay theo cách thức quy định tại
                        Phần IV này.
                      </li>
                      <li>
                        <b>Dư nợ vay GDKQ (Debt)</b>: Là số tiền bao gồm cả gốc
                        và lãi mà Khách Hàng nợ CASC trên TKGDKQ.
                      </li>
                      <li>
                        <b>Tiền bán chứng khoán</b>: Là số tiền bán chứng khoán
                        trên TKGDKQ sẽ được nhận về tại ngày thanh toán sau khi
                        đã trừ các khoản phí, thuế giao dịch có liên quan.
                      </li>
                      <li>
                        <b>Tổng giá trị tài sản thực có</b>: Bao gồm toàn bộ
                        tiền mặt, tổng giá trị đầu tư (LMV) và tổng giá trị thế
                        chấp (Collateral) trên TKGDKQ.
                      </li>
                      <li>
                        <b>Tổng giá trị tài sản (Asset)</b>: Bao gồm toàn bộ
                        tiền mặt, tiền bán chứng khoán sẽ nhận về của Khách
                        Hàng, tổng giá trị đầu tư (LMV) và tổng giá trị thế chấp
                        (Collateral) trên TKGDKQ.
                      </li>
                      <li>
                        <b>Giá trị tài sản ròng (Equity)</b>: Bằng Tổng giá trị
                        tài sản trừ đi (-) Dư nợ vay GDKQ.
                      </li>
                      <li>
                        <b>Tài sản đảm bảo (TSĐB)</b>: Là toàn bộ chứng khoán
                        được CASC đồng ý ghi nhận vào tài sản (bao gồm cả chứng
                        khoán sẽ hình thành trong tương lai), tiền trên TKGDKQ,
                        TKGDCK của Khách Hàng và các tài sản khác thuộc quyền sở
                        hữu hợp pháp của Khách Hàng để đảm bảo thực hiện nghĩa
                        vụ trả nợ cho CASC
                      </li>
                      <li>
                        <b>Tổng giá trị đầu tư (LMV)</b>: Là tổng giá trị hiện
                        tại của chứng khoán thuộc danh sách chứng khoán được
                        phép GDKQ do CASC quy định (bao gồm cả chứng khoán được
                        hình thành trong tương lai) và/hoặc các chứng khoán khác
                        được CASC chấp thuận theo từng thời kỳ.
                      </li>
                      <li>
                        <b>Tổng giá trị thế chấp (Collateral)</b>: Là Tổng giá
                        trị của chứng khoán hoặc tài sản không nằm trong Danh
                        sách chứng khoán được phép GDKQ mà CASC đồng ý (trong
                        trường hợp pháp luật cho phép) được Khách Hàng chuyển/bổ
                        sung vào TKGDKQ để làm tăng giá trị của TKGDKQ (được
                        tính giá trị theo cách thức quy định của CASC).
                      </li>
                      <li>
                        <b>Tỷ lệ ký quỹ ban đầu (IM)</b>: Là tỷ lệ giữa giá trị
                        tài sản ròng và giá trị chứng khoán dự kiến mua được
                        bằng lệnh giao dịch ký quỹ tính theo giá thị trường tại
                        thời điểm giao dịch.
                      </li>
                      <li>
                        <b>Giá trị ký quỹ yêu cầu (MR)</b>: Là giá trị CASC yêu
                        cầu Khách Hàng phải có để duy trì giá trị đầu tư.
                      </li>
                      <li>
                        <b>Giá trị tài sản ròng vượt mức ký quỹ (EE)</b>: Là giá
                        trị tài sản ròng còn lại của Khách Hàng sau khi trừ đi
                        Giá trị ký quỹ yêu cầu và Giá trị ký quỹ yêu cầu của
                        lệnh đặt mua.
                      </li>
                      <li>
                        <b>Sức mua (PP)</b>: Là giá trị Khách Hàng có thể đặt
                        lệnh mua chứng khoán trên TKGDKQ căn cứ vào Giá trị tài
                        sản ròng vượt mức ký quỹ. Sức mua của Khách Hàng có thể
                        thay đổi theo mã chứng khoán Khách Hàng mua.
                      </li>
                      <li>
                        <b>Tỷ lệ ký quỹ hiện tại (MRt)</b>: Là tỷ lệ hiện tại
                        giữa “Giá trị tài sản ròng của Khách Hàng” và “Tổng giá
                        trị tài sản thực có” (tại thời điểm do CASC xác định).
                      </li>
                      <li>
                        <b>Tỷ lệ ký quỹ duy trì (IMdt)</b>: Là tỷ lệ ký quỹ do
                        CASC quy định và không thấp hơn tỷ lệ pháp luật quy
                        định. Khi Tỷ lệ ký quỹ hiện tại trên TKGDKQ của Khách
                        Hàng giảm dưới tỷ lệ này, Khách Hàng bắt buộc phải bổ
                        sung thêm TSĐB và hoặc trả bớt một phần hoặc toàn bộ Dư
                        nợ vay GDKQ của Khách Hàng tại CASC để bảo đảm Tỷ lệ ký
                        quỹ hiện tại không thấp hơn Tỷ lệ ký quỹ duy trì.
                      </li>
                      <li>
                        <b>Tỷ lệ ký quỹ xử lý (IMxl)</b>: Là tỷ lệ bán xử lý
                        giải chấp tài khoản. Tỷ lệ này do CASC quy định và có
                        thể thay đổi theo chính sách của CASC ban hành trong
                        từng thời kỳ. Khi Tỷ lệ ký quỹ hiện tại trên tài khoản
                        TKGDKQ của Khách Hàng nhỏ hơn tỷ lệ này thì CASC được
                        toàn quyền thực hiện ngay các biện pháp xử lý cần thiết
                        theo quy định của Phần IV này.
                      </li>
                      <li>
                        <b>Lệnh gọi yêu cầu ký quỹ bổ sung</b>: Là thông báo của
                        CASC gửi cho Khách Hàng để yêu cầu Khách Hàng nộp thêm
                        tiền và/hoặc bổ sung thêm tài sản đầu tư, tài sản thế
                        chấp và/hoặc bán bớt chứng khoán đầu tư để bảo đảm Tỷ lệ
                        ký quỹ hiện tại không thấp hơp Tỷ lệ ký quỹ duy trì.
                      </li>
                      <li>
                        <b>Danh sách chứng khoán được phép GDKQ</b>: Là danh
                        sách các mã chứng khoán được CASC công bố từng thời kỳ.
                      </li>
                      <li>
                        <b>Giá chặn trần</b>: Là mức giá do CASC quy định và
                        được dùng làm cơ sở xác định mức giá cao nhất để tính
                        Giá trị tài sản ròng và các chỉ sổ khác.
                      </li>
                      <li>
                        <b>Hạn mức cho vay GDKQ</b>: Là tổng giá trị tối đa bằng
                        tiền mà CASC có thể cho vay GDKQ đối với Khách Hàng do
                        CASC quy định trong từng thời kỳ.
                      </li>
                      <li>
                        <b>Ngày giải ngân</b>: Là ngày CASC chuyển tiền vay vào
                        TKGDKQ.
                      </li>
                      <li>
                        <b>Ngày vay thực tế</b>: Là số ngày được tính từ ngày
                        giải ngân đến ngày Khách Hàng trả nợ cho CASC.
                      </li>
                      <li>
                        <b>Ngày quá hạn</b>: Là số ngày vượt quá thời hạn cho
                        vay.
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>
                        Điều 32: &nbsp;&nbsp; Điều Kiện Vay Để Giao Dịch Ký Quỹ
                      </b>
                    </p>
                    <p className="my-0">
                      Khách Hàng được hiện giao dịch ký quỹ khi đáp ứng đủ các
                      điều kiện sau:
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        Khách Hàng đáp ứng các điều kiện theo quy định của pháp
                        luật về GQKQ, đáp ứng các quy định về TSĐB, Giá trị tài
                        sản ròng tối thiểu trên TKGDKQ, Tỷ lệ ký quỹ ban đầu của
                        CASC. CASC có thể thay đổi các quy định này trong từng
                        thời kỳ/thời điểm.
                      </li>
                      <li>
                        Khách Hàng có khả năng tài chính bảo đảm trả toàn bộ nợ
                        vay cho CASC đầy đủ, đúng hạn và trong thời hạn cam kết
                        và không thuộc các trường hợp chấm dứt, thu hồi Dư nợ
                        vay GDKQ trước hạn theo Phần IV này.
                      </li>
                      <li>
                        Khách Hàng phải duy trì đúng/đầy đủ các Tỷ lệ/Giá trị
                        của TKGDKQ theo quy định của Phần IV này. Cách tính Tỷ
                        lệ ký quỹ hiện tại, các quy định về Tỷ lệ ký quỹ bắt
                        buộc, Tỷ lệ ký quỹ xử lý có thể thay đổi theo quyết định
                        của CASC từng thời kỳ.
                      </li>
                      <li>
                        Chứng khoán Khách Hàng mua nằm trong Danh sách chứng
                        khoán được phép GDKQ do CASC quy định. Danh sách này có
                        thể thay đổi theo từng thời điểm theo quyết định đơn
                        phương của CASC.
                      </li>
                      <li>
                        Các điều kiện khác theo quy định tại Phần IV này, các
                        văn bản pháp luật liên quan và các thông báo của CASC.
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>Điều 33: &nbsp;&nbsp; Cho Vay Giao Dịch Ký Quỹ:</b>
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        <b>Nguyên Tắc Cho Vay Giao Dịch Ký Quỹ:</b>
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Trước khi thực hiện giao dịch ký quỹ, Khách Hàng
                            phải có tài sản đảm bảo tỷ lệ ký quỹ theo quy định;
                            và
                          </il>
                          <br />
                          <il>
                            Trong giới hạn Hạn mức cho vay GDKQ và căn cứ vào
                            Giá trị tài sản ròng vượt mức ký quỹ (EE) trên
                            TKGDKQ, CASC thực hiện đánh giá và giải ngân cho vay
                            giao dịch ký quỹ trên TKGDKQ .
                          </il>
                        </ol>
                      </li>
                      <li>
                        <b>Phương Thức Giải Ngân:</b>
                        <br />
                        Khi Khách Hàng có lệnh mua chứng khoán được khớp nhưng
                        không đủ số dư tiền trên TKGDKQ để thanh toán lệnh mua
                        thì Khách Hàng mặc nhiên đề nghị CASC giải ngân khoản
                        tiền cho vay GDKQ theo nguyên tắc dưới đây:
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Căn cứ yêu cầu mua chứng khoán của Khách Hàng trong
                            lệnh mua tương ứng và/hoặc các trường hợp khác được
                            CASC chấp thuận giao dịch thành công, CASC tự động
                            chuyển tiền giải ngân vào TKGDKQ được thể hiện bằng
                            số dư tăng thêm của Dư nợ vay GDKQ trên TKGDKQ, đồng
                            thời là bằng chứng cho việc CASC đã giải ngân để
                            thanh toán theo yêu cầu của Khách Hàng.
                          </il>
                          <br />
                          <il>
                            Khách Hàng mặc nhiên chấp nhận và nhận nợ toàn bộ
                            các khoản tiền cho vay GDKQ được giải ngân theo quy
                            định trên đây và Khách Hàng có nghĩa vụ thanh toán
                            hoàn trả cho CASC toàn bộ số tiền CASC đã giải ngân
                            cho vay GDKQ. Khoản nợ gốc của Khách Hàng đối với
                            CASC được thể hiện bằng số Dư nợ vay GDKQ trên
                            TKGDKQ.
                          </il>
                          <br />
                          <il>
                            Khách Hàng tại đây ủy quyền cho CASC lập văn bản xác
                            nhận đối với các khoản Dư nợ vay GDKQ đã giải ngân
                            của Khách Hàng và được lưu trong hồ sơ giao dịch của
                            Khách Hàng. Khách Hàng đương nhiên ghi nhận nghĩa vụ
                            hoàn trả đối với Dư nợ vay GDKQ ghi trên văn bản do
                            CASC lập mà không cần có thêm bất cứ văn bản nào về
                            việc xác nhận của Khách Hàng.
                          </il>
                          <br />
                        </ol>
                        Khách Hàng tại đây cam kết đã hiểu, đồng ý và không có
                        bất kỳ khiếu nại, tranh chấp nào với CASC về nguyên tắc,
                        phương thức, thủ tục giải ngân và nhận nợ theo quy định
                        tại Điều này.
                      </li>
                      <li>
                        <b>Mục Đích Sử Dụng Tiền Vay Và Thời Hạn Cho Vay</b>
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            <b>Mục đích sử dụng tiền vay</b>: Khách Hàng chỉ
                            được phép sử dụng các khoản tiền vay cho GDKQ từ
                            CASC để mua chứng khoán trên TKGDKQ, và những trường
                            hợp được CASC chấp thuận.
                          </il>
                          <br />
                          <il>
                            <b>Thời hạn cho vay</b>:
                            <pl className="counter-roman">
                              <jl>
                                Thời hạn của các khoản vay được quy định theo
                                chính sách sản phẩm GDKQ của CASC trong từng
                                thời kỳ;
                              </jl>
                              <br />
                              <jl>
                                Bằng Hợp Đồng Mở TKCK, Khách Hàng đề nghị CASC
                                gia hạn thời gian cho vay đối với mỗi khoản vay
                                giao dịch ký quỹ đến hạn trừ trường hợp Khách
                                Hàng có yêu cầu khác bằng văn bản trước thời
                                điểm đến hạn nêu trên;
                              </jl>
                              <br />
                              <jl>
                                CASC có thể xem xét chấp thuận hoặc không chấp
                                thuận gia hạn thời gian cho vay theo đề nghị của
                                Khách Hàng dựa trên nguồn vốn hiện có của CASC
                                và/hoặc khả năng thu hồi nợ của Khách Hàng. Thời
                                gian gia hạn (nếu có) sẽ theo quy định của CASC
                                trên cơ sở phù hơp với quy định của pháp luật
                                hiện hành;
                              </jl>
                              <br />
                              <jl>
                                Nếu CASC chấp thuận cho Khách Hàng gia hạn, CASC
                                sẽ toàn quyền chủ động quyết định số lần gia
                                hạn, thời gian mỗi lần gia hạn cũng như mức lãi
                                suất cho vay gia hạn, cách thức tính lãi suất
                                cho vay gia hạn; và các quy định khác có liên
                                quan đến việc gia hạn cho vay để Khách Hàng được
                                giao dịch trên tài khoản ký quỹ phù hợp với
                                chính sách của CASC. Nếu CASC chủ động thay đổi
                                thời hạn gia hạn dẫn tới việc Khách Hàng phải
                                hoàn trả tiền vay và lãi vay cho CASC trước hạn,
                                CASC có trách nhiệm thông báo cho Khách Hàng ít
                                nhất 03 (ba) ngày giao dịch theo các hình thức
                                quy định tại khoản 2 Điều 8 của Phần I Bộ T&C
                                này;
                              </jl>
                              <br />
                              <jl>
                                Nếu CASC không chấp thuận cho Khách Hàng gia hạn
                                thì Khách Hàng phải thực hiện bán chứng khoán
                                trên TKGDKQ hoặc Khách Hàng phải chuyển tiền vào
                                TKGDKQ để CASC thu hồi nợ. Ngay sau ngày hết hạn
                                cho vay mà Khách Hàng chưa nộp tiền và/hoặc bán
                                chứng khoán hoặc nộp tiền/hoặc bán chứng khoán
                                chưa đủ để tất toán khoản vay thì CASC có quyền
                                nhưng không phải nghĩa vụ chủ động thu hồi nợ
                                theo quy định tại Điều 40 của Phần IV này.
                              </jl>
                            </pl>
                          </il>
                        </ol>
                      </li>
                      <li>
                        <b>
                          Giới Hạn Cho Vay Giao Dịch Ký Quỹ Và Miễn Trách Của
                          CASC:
                        </b>
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Hạn mức cho vay GDKQ đối với Khách Hàng có thể được
                            CASC thay đổi từng thời kỳ mà không bắt buộc phải
                            thông báo trước với Khách Hàng. Trong thời hạn hiệu
                            lực của hạn mức cho vay GDKQ, các bên đồng ý rằng:
                            <pl className="counter-roman">
                              <jl>
                                Khách Hàng có thể được giải ngân nhiều lần nhưng
                                tổng Dư nợ vay GDKQ tại bất kỳ thời điểm nào
                                cũng không vượt quá giá trị Hạn mức cho vay
                                GDKQ.
                              </jl>
                              <br />
                              <jl>
                                Tổng nguồn vốn cho vay GDKQ đối với tất cả các
                                Khách Hàng sử dụng hình thức GDKQ theo cách thức
                                tương tự như hợp đồng này có thể được CASC thay
                                đổi từng thời kỳ mà không bắt buộc phải thông
                                báo đến Khách Hàng.
                              </jl>
                              <br />
                              <jl>
                                Vì bất cứ lý do gì, nếu Khách Hàng có Dư nợ vay
                                GDKQ vượt Hạn mức cho vay GDKQ theo quy định (tỷ
                                lệ cho vay đối với từng mã chứng khoán, giới hạn
                                số lượng chứng khoán cho vay,…), Khách Hàng phải
                                thanh toán ngay phần chênh lệch ngay trong ngày
                                hoặc Khách Hàng phải đề nghị CASC tăng hạn mức
                                cho vay GDKQ để phù hợp với tình trạng số dư và
                                nếu được CASC đồng ý, CASC và Khách Hàng sẽ ký
                                các văn bản cần thiết để xác nhận việc điều
                                chỉnh. Trong mọi trường hợp, Khách Hàng đương
                                nhiên nhận nợ toàn bộ các khoản CASC đã giải
                                ngân, kể cả phần vượt đó (nếu có).
                              </jl>
                            </pl>
                          </il>
                          <il>
                            Khách Hàng không tiếp tục được vay GDKQ để mua chứng
                            khoán khi đã đạt Hạn mức cho vay GDKQ hoặc khi Tổng
                            nguồn vốn cho vay GDKQ của CASC đã được sử dụng hết
                            (mà không bắt buộc phải thông báo đến Khách Hàng
                            trước). Trong các trường hợp nêu tại khoản 4 Điều
                            này, CASC được miễn mọi trách nhiệm đối với Khách
                            Hàng.
                          </il>
                        </ol>
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>
                        Điều 34: &nbsp;&nbsp; Dư Nợ Cho Vay Và Lãi Suất Cho Vay
                        Giao Dịch Ký Quỹ
                      </b>
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        <b>Dư Nợ Vay Giao Dịch Ký Quỹ:</b>
                        <br />
                        Dư nợ vay GDKQ được tính hàng ngày và là tổng số tiền
                        thực tế CASC đã giải ngân cho Khách Hàng trên TKGDKQ lũy
                        kế đến cuối ngày đó (mà chưa được Khách Hàng hoàn trả
                        cho CASC).
                      </li>
                      <li>
                        <b>Lãi Suất:</b>
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Lãi suất cho vay GDKQ được xác định bằng tỷ lệ phần
                            trăm theo ngày, tháng (cơ sở 30 ngày/tháng) hoặc
                            theo năm (360 ngày/năm) được áp dụng từng thời kỳ do
                            CASC quy định. Khi có thay đổi Lãi suất cho vay,
                            CASC sẽ thông báo tới Khách Hàng một (01) ngày làm
                            việc trước khi áp dụng chính thức bằng một trong các
                            hình thức thông báo được quy định tại Phần IV này.
                          </il>
                          <br />
                          <il>
                            Tiền lãi vay được tính được tính như sau:
                            <br />
                            <b>
                              Lãi vay = Dư nợ vay GDKQ * lãi suất* số ngày thực
                              thế giải ngân/360 ngày
                            </b>
                          </il>
                          <br />
                          <il>
                            Tổng tiền lãi cho vay GDKQ: Là tổng số Tiền lãi áp
                            dụng cho toàn bộ các khoản cho vay GDKQ giải ngân
                            tính đến ngày hoàn trả thực tế. Khách Hàng đồng ý để
                            CASC được tính toán, lập bảng kê chi tiết Tổng tiền
                            lãi cho vay GDKQ và đương nhiên công nhận hiệu lực
                            của bảng kê này. Tiền lãi của tháng chưa thanh toán
                            sẽ được cộng dồn vào Dư nợ vay GDKQ lũy kế của Khách
                            Hàng.
                          </il>
                          <br />
                          <il>
                            Cách tính Tiền lãi, Lãi suất cho vay GDKQ có thể
                            thay đổi theo quyết định của CASC mà không phải
                            thông báo cho Khách Hàng. Khách Hàng đồng ý rằng,
                            bất kể kết quả hoạt động kinh doanh trên TKGDKQ như
                            thế nào (lãi/lỗ), Khách Hàng sẽ hoàn trả đủ các
                            khoản vay GDKQ và thanh toán đủ cho CASC Tổng lãi
                            cho vay GDKQ nêu trên.
                          </il>
                          <br />
                          <il>
                            Lãi vay quá hạn: CASC sẽ áp dụng lã suất vay quá hạn
                            theo thông báo tại từng thời kỳ:
                            <br />
                            <b>
                              Lãi vay quá hạn = Dư nợ vay GDKQ * lãi suất vay
                              quá hạn * số ngày quá hạn/360 ngày
                            </b>
                          </il>
                          <br />
                        </ol>
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>
                        Điều 35: &nbsp;&nbsp; Thu Hồi Dư Nợ Vay Giao Dịch Ký Quỹ
                        Và Lãi Vay
                      </b>
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        <b>Thu Hồi Dư Nợ Vay Giao Dịch Ký Quỹ:</b>
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Thu hồi Dư nợ vay GDKQ trong hạn:
                            <br />
                            <pl className="counter-roman">
                              <jl>
                                Dư nợ vay GDKQ gốc được thu ngay khi TKGDKQ nhận
                                được tiền do Khách Hàng nộp/chuyển vào và/hoặc
                                tiền bán chứng khoán đến hạn nhận về (đã trừ phí
                                giao dịch + thuế, nếu có), cổ tức bằng tiền đến
                                hạn nhận về.
                              </jl>
                              <br />
                              <jl>
                                CASC tự động lập bút toán trích thu Dư nợ vay
                                GDKQ từ TKGDKQ và ghi giảm Dư nợ vay GDKQ với
                                giá trị tương ứng với phần đã thu. Nếu Dư nợ vay
                                GDKQ đã được thu hồi đủ, phần còn lại được ghi
                                tăng vào Tiền mặt trên TKGDKQ
                              </jl>
                            </pl>
                          </il>
                          <il>
                            Thu hồi Dư nợ vay GDKQ trước hạn:
                            <br />
                            CASC có quyền không tiếp tục giải ngân, chấm dứt
                            việc cho vay GDKQ, thu hồi nợ trước hạn (bao gồm: Dư
                            nợ vay GDKQ, toàn bộ Tiền lãi, phí chưa thanh toán,
                            các nghĩa vụ khác của Khách Hàng với CASC) cũng như
                            chấm dứt Các Điều khoản và Điều kiện về GDKQ với
                            Khách Hàng trong các trường hợp sau:
                            <br />
                            <pl className="counter-roman">
                              <jl>
                                Khách Hàng có liên quan đến các vụ kiện mà theo
                                nhận định của CASC là có ảnh hưởng đến phần lớn
                                tài sản của Khách Hàng; Khách Hàng bị mất năng
                                lực hành vi dân sự, bị hạn chế năng lực hành vi
                                dân sự hoặc bị khởi tố/ truy tố/ xét xử về hình
                                sự. Đối với Khách Hàng là tổ chức bị giải thể,
                                ngừng hoạt động, chia tách, hợp nhất, sáp nhập,
                                chuyển đổi hình thức, cổ phần hóa hoặc thay đổi
                                chủ sở hữu…; người đại diện theo pháp luật,
                                người điều hành của Khách Hàng bị khởi tố/ truy
                                tố/ xét xử về hình sự;
                              </jl>
                              <br />
                              <jl>
                                Khách Hàng vi phạm bất kỳ nội dung nào của Phần
                                IV này và/hoặc cam kết đã ký với CASC;
                              </jl>
                              <br />
                              <jl>
                                Khách Hàng không đồng ý thay đổi: Lãi suất vay
                                GDKQ, phí, các tỷ lệ liên quan đến TKGDKQ…do
                                CASC đưa ra;
                              </jl>
                              <br />
                              <jl>
                                Khách Hàng cung cấp thông tin sai sự thật khi
                                thực hiện mở TKCK và TKGDKQ;
                              </jl>
                              <br />
                              <jl>
                                Khách Hàng là cá nhân chết mà không có người
                                thừa kế được CASC chấp thuận;
                              </jl>
                              <br />
                              <jl>
                                CASC quyết định ngừng cho vay GDKQ theo quyết
                                định của cơ quan Nhà nước có thẩm quyền, do thay
                                đổi chiến lược kinh doanh hay do bất kỳ nguyên
                                nhân nào khác; các trường hợp khác mà CASC xét
                                thấy cần thiết để bảo đảm thu hồi vốn cho CASC.
                              </jl>
                              <br />
                            </pl>
                            Việc thu nợ được thực hiện theo thứ tự ưu tiên như
                            sau: phí giao dịch/các khoản phải trả khác của Khách
                            Hàng (nếu có), chi phí xử lý tài sản bảo đảm, Tiền
                            lãi quá hạn, Tiền lãi trong hạn, nợ gốc. CASC có
                            quyền đơn phương thay đổi thứ tự ưu tiên thu hồi nợ
                            này mà không cần thông báo Khách Hàng.
                          </il>
                        </ol>
                      </li>
                      <li>
                        <b>Thu Tiền Lãi Vay:</b>
                        <br />
                        Tiền lãi vay được tính, cộng dồn hàng ngày (được tạm
                        phong tỏa khi kết thúc giao dịch và được tạm cộng vào Dư
                        nợ vay GDKQ để tính toán các tỷ lệ yêu cầu trên TKGDKQ)
                        và thu vào ngày đầu tiên của mỗi tháng (hoặc tại ngày
                        chấm dứt Hợp Đồng Mở TKCK và đăng ký dịch vụ chứng
                        khoán, tùy thời điểm nào đến sớm hơn).
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            CASC tự động trích thu hồi tiền lãi từ khoản Tiền
                            mặt trên TKGDKQ đến khi thu hồi đủ Tiền lãi phát
                            sinh và ghi giảm Tiền mặt tương ứng với phần Tiền
                            lãi đã thu.
                          </il>
                          <br />
                          <il>
                            Trường hợp Tiền mặt trên TKGDKQ không đủ tiền trả
                            Tiền lãi thu theo tháng, CASC được quyền, tùy theo
                            lựa chọn của mình yêu cầu Khách Hàng bổ sung Tiền
                            mặt ngay lập tức để trả đủ Tiền lãi hoặc phần Tiền
                            lãi còn thiếu của tháng đó được CASC tự động cộng
                            dồn vào Dư nợ vay GDKQ của ngày đầu tiên của tháng
                            kế tiếp.
                          </il>
                          <br />
                        </ol>
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>
                        Điều 36: &nbsp;&nbsp; Xử Lý Vi Phạm Tỷ Lệ Trên Tài Khoản
                        Ký Quỹ
                      </b>
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        <b>Xử Lý Vi Phạm Tỷ Lệ Ký Quỹ Duy Trì:</b>
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Khi Tỷ lệ ký quỹ hiện tại trên TKGDKQ thấp hơn Tỷ lệ
                            ký quỹ duy trì, CASC ngay lập tức gửi Lệnh gọi yêu
                            cầu ký quỹ bổ sung đến Khách Hàng.
                          </il>
                          <br />
                          <il>
                            Trong thời hạn bổ sung TSĐB theo quy định tại Điều
                            37 của Phần IV này, Khách Hàng phải bổ sung TSĐB
                            và/hoặc giảm Dư nợ vay GDKQ để đưa Tỷ lệ ký quỹ hiện
                            tại trên TKGDKQ cao hơn Tỷ lệ ký quỹ duy trì.
                          </il>
                          <br />
                          <il>
                            Quá thời hạn bổ sung TSĐB mà Khách Hàng không thực
                            hiện bổ sung TSĐB vào TKGDKQ theo đúng các nội dung
                            yêu cầu của CASC, đồng thời Tỷ lệ ký quỹ hiện tại
                            của TKGDKQ trong thời hạn bổ sung TSĐB này liên tục
                            thấp hơn Tỷ lệ ký quỹ duy trì, CASC sẽ được toàn
                            quyền thực hiện bán TSĐB của Khách Hàng (cả trên
                            TKGDCK và TKGDKQ) để thu hồi một phần hoặc toàn bộ
                            Dư nợ vay GDKQ để đưa Tỷ lệ ký quỹ hiện tại cao hơn
                            Tỷ lệ ký quỹ duy trì theo nguyên tắc xử lý TSĐB được
                            quy định tại Điều 38 của Phần IV này mà không cần có
                            bất kỳ sự đồng ý/chấp thuận nào của Khách Hàng. Việc
                            xử lý đến tỷ lệ cụ thể nào là do CASC được toàn
                            quyền quyết định.
                          </il>
                          <br />
                        </ol>
                      </li>
                      <li>
                        <b>Xử Lý Vi Phạm Tỷ Lệ Ký Quỹ Xử Lý:</b>
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Khi Tỷ lệ ký quỹ hiện tại trên TKGDKQ tại bất kỳ
                            thời điểm nào nhỏ hơn Tỷ lệ ký quỹ xử lý, ngay lập
                            tức CASC được toàn quyền bán TSĐB của Khách Hàng (cả
                            trên TKGDCK và TKGDKQ) để thu hồi một phần hoặc toàn
                            bộ tiền để đưa Tỷ lệ ký quỹ hiện lớn hơn Tỷ lệ ký
                            quỹ duy trì theo nguyên tắc xử lý TSĐB theo quy định
                            tại Điều 38 của Phần IV này mà không cần có bất kỳ
                            sự đồng ý/chấp thuận nào của Khách Hàng.
                          </il>
                          <br />
                          <il>
                            Trường hợp CASC xử lý tài sản trên TKGDCK, CASC được
                            toàn quyền chuyển khoản tiền thu được vào TKGDKQ để
                            thu nợ và thay mặt Khách Hàng lập tất cả các chứng
                            từ có liên quan.
                          </il>
                          <br />
                        </ol>
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>Điều 37: &nbsp;&nbsp; Bổ Sung Tài Sản Đảm Bảo</b>
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        Lệnh gọi yêu cầu ký quỹ bổ sung được CASC gửi tới Khách
                        Hàng ngay khi Tỷ lệ ký quỹ hiện tại trên TKGDKQ thấp hơn
                        Tỷ lệ ký quỹ duy trì.
                      </li>
                      <li>
                        Thời hạn Khách Hàng phải bổ sung là ba (03) ngày giao
                        dịch kể từ ngày CASC gửi Lệnh gọi yêu cầu ký quỹ bổ
                        sung. Tuy nhiên, trong mọi trường hợp, nếu trong thời
                        hạn yêu cầu bổ sung TSĐB này mà Tỷ lệ ký quỹ hiện tại
                        trên TKGDKQ thấp hơn Tỷ lệ ký quỹ xử lý, CASC có toàn
                        quyền xử lý chứng khoán ngay lập tức theo cách thức quy
                        định tại Điều 38 của Phần IV này mà không cần chờ đến
                        hết thời hạn bổ sung TSĐB.
                      </li>
                      <li>
                        Đối với trường hợp Khách Hàng phải bổ sung TSĐB do mã
                        chứng khoán bị đưa ra khỏi Danh sách chứng khoán được
                        phép GDKQ như quy định tại khoản 4 Điều này thì thời hạn
                        bổ sung tài sản của Khách Hàng được quy định như sau:
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Ba (03) ngày giao dịch kể từ ngày CASC gửi Lệnh gọi
                            yêu cầu ký quỹ bổ sung tới Khách Hàng nếu Tỷ lệ ký
                            quỹ duy trì &gt; Tỷ lệ ký quỹ hiện tại &gt; Tỷ lệ ký
                            quỹ xử lý (trừ trường hợp có quy định khác của
                            CASC); và
                          </il>
                          <br />
                          <il>
                            Một (01) ngày giao dịch kể từ ngày CASC gửi Lệnh gọi
                            yêu cầu ký quỹ bổ sung tới Khách Hàng nếu Tỷ lệ ký
                            quỹ hiện tại &lt; Tỷ lệ ký quỹ xử lý.
                          </il>
                          <br />
                        </ol>
                      </li>
                      <li>
                        Khách Hàng có thể thực hiện bổ sung TSĐB theo các cách
                        sau: (i) Nộp/chuyển tiền vào TKGDKQ với số tiền không
                        thấp hơn Giá trị tài sản tối thiểu cần bổ sung; (ii) Bán
                        chứng khoán trong TKGDKQ với giá trị chứng khoán bán (đã
                        trừ phí giao dịch và thuế liên quan) để đảm bảo Tỷ lệ ký
                        quỹ hiện tại không thấp hơn Tỷ lệ ký quỹ duy trì; (iii)
                        Chuyển chứng khoán được phép GDKQ từ TKGDCK của Khách
                        Hàng sang TKGDKQ với tổng giá trị tính theo giá thị
                        trường không thấp hơn Giá trị tài sản tối thiểu cần bổ
                        sung; (iv) Chuyển chứng khoán được chấp nhận làm tài sản
                        thế chấp từ TKGDCK của Khách Hàng sang TKGDKQ với tổng
                        giá trị tính theo giá CASC chấp nhận không nhỏ hơn Giá
                        trị tài sản tối thiểu cần bổ sung.
                      </li>
                      <li>
                        Đồng thời thực hiện các cách nêu tại khoản 4 Điều này
                        với tổng giá trị tiền, chứng khoán bán (đã trừ phí giao
                        dịch và thuế liên quan), giá trị thị trường của chứng
                        khoán được phép GDKQ, giá trị chứng khoán được chấp nhận
                        làm tài sản thế chấp sao cho TKGDKQ đảm bảo Tỷ lệ ký quỹ
                        duy trì theo quy định của Phần IV này.
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>
                        Điều 38: &nbsp;&nbsp; Đảm Bảo Khoản Vay Giao Dịch Ký Quỹ
                      </b>
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        <b>Nghĩa Vụ Được Bảo Đảm Và Tài Sản Đảm Bảo:</b>
                        <br />
                        Để thực hiện đúng các nghĩa vụ được bảo đảm, Khách Hàng
                        tự nguyện sử dụng toàn bộ tài sản thuộc sở hữu hợp pháp
                        của mình để làm TSĐB cho CASC với các nội dung sau:
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Nghĩa vụ được bảo đảm: Là nghĩa vụ trả nợ GDKQ của
                            Khách Hàng cho CASC (bao gồm: Dư nợ vay GDKQ, Tiền
                            lãi vay, phí, phạt bồi thường thiệt hại và các chi
                            phí khác cũng như các nghĩa vụ hoàn trả, thanh toán
                            khác của Khách Hàng phát sinh khi Khách Hàng thực
                            hiện GDKQ kể cả trong trường hợp Phần IV này bị hủy
                            bỏ, chấm dứt, vô hiệu).
                          </il>
                          <br />
                          <il>
                            Giá trị TSĐB: CASC có quyền tính toán, đánh giá và
                            xác định giá trị TSĐB của Khách Hàng tại bất kỳ thời
                            điểm nào theo giá trị thị trường hoặc theo mức giá
                            do CASC xác định. Khách Hàng đồng ý việc đánh giá
                            lại TSĐB này của CASC và cam kết không có bất kỳ
                            khiếu nại, tranh chấp nào đối với CASC.
                          </il>
                          <br />
                          <il>
                            Ngoài các TSĐB nêu trên, Khách Hàng đồng ý dùng toàn
                            bộ số tiền, chứng khoán của mình và các tài sản hợp
                            pháp khác tại CASC làm TSĐB cho nghĩa vụ với CASC.
                            Tuy nhiên, để tránh hiểu nhầm, các tài sản này sẽ
                            không được tính vào các tỷ lệ trên TKGDKQ (trừ
                            trường hợp CASC có quy định khác) và giá trị bổ sung
                            (mà chỉ để xử lý khi phát sinh các sự kiện xử lý
                            TSĐB theo Phần IV này).
                          </il>
                          <br />
                          <il>
                            Việc sử dụng tài sản của bên thứ ba hoặc các tài sản
                            khác (ngoài tiền và chứng khoán trên tài khoản giao
                            dịch chứng khoán của Khách Hàng) để bảo đảm cho
                            nghĩa vụ của Khách Hàng được thực hiện theo quy định
                            từng thời kỳ của CASC phù hợp với quy định pháp luật
                            hiện hành.
                          </il>
                          <br />
                        </ol>
                      </li>
                      <li>
                        <b>Thời Hạn Bảo Đảm:</b>
                        <br />
                        Kể từ ngày ký kết sử dụng dịch vụ cho đến khi Khách Hàng
                        thực hiện toàn bộ nghĩa vụ hoàn trả Dư nợ vay GDKQ, Tiền
                        lãi vay, phí, phạt và các nghĩa vụ khác (nếu có) phát
                        sinh và/hoặc liên quan đến Tài khoản GDKQ, TKGDCK của
                        Khách Hàng đối với CASC và/hoặc bên thứ ba (nếu có).
                      </li>
                      <li>
                        <b>Sử Dụng & Xử Lý Tài Sản Bảo Đảm:</b>
                        <br />
                        TSĐB được CASC sử dụng, xử lý để thanh toán các khoản nợ
                        mà Khách Hàng phải trả cho CASC gồm Dư nợ vay GDKQ, Tiền
                        lãi vay, phí, chi phí, nghĩa vụ thuế liên quan đến xử lý
                        TSĐB (nếu có), tiền phạt và bồi thường thiệt hại (nếu
                        có) và các nghĩa vụ khác của Khách Hàng.
                        <br />
                        Khách Hàng ủy quyền cho CASC được toàn quyền sử dụng
                        TSĐB để làm tài sản thế chấp trong trường hợp CASC cần
                        vay vốn từ Bên thứ ba để cung cấp dịch vụ GDKQ cho Khách
                        Hàng. Trong trường hợp CASC phải xử lý TSĐB để thu hồi
                        nợ trước hạn hoặc quá hạn, giá trị TSĐB sau khi xử lý
                        nếu còn dư thì Khách Hàng được CASC hoàn trả lại phần dư
                        này; nếu còn thiếu thì Khách Hàng có nghĩa vụ tiếp tục
                        trả cho CASC. Các chi phí, thuế, nghĩa vụ tài chính khác
                        phát sinh trong quá trình xử lý TSĐB do Khách Hàng chịu.
                      </li>
                      <li>
                        <b>Nguyên Tắc Xử Lý Tài Sản Đảm Bảo:</b>
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Các trường hợp xử lý:
                            <br />
                            <pl className="counter-roman">
                              <jl>
                                Xử lý TSĐB khi Khách Hàng vi phạm các tỷ lệ yêu
                                cầu trên TKGDKQ;
                              </jl>
                              <br />
                              <jl>
                                Xử lý TSĐB khi CASC thu hồi Dư nợ vay GDKQ trước
                                hạn;
                              </jl>
                              <br />
                              <jl>
                                Xử lý TSĐB khi Khách Hàng không thanh toán nợ
                                đúng hạn;
                              </jl>
                              <br />
                              <jl>
                                Xử lý TSĐB để thực hiện các nghĩa vụ khác của
                                Khách Hàng với CASC; hoặc
                              </jl>
                              <br />
                              <jl>
                                Các trường hợp khác theo quy định của pháp luật.
                              </jl>
                              <br />
                            </pl>
                          </il>
                          <il>
                            Thực hiện xử lý <br />
                            Ngay khi phát sinh các trường hợp được quy định tại
                            đoạn a khoản 4 Điều này, Khách Hàng chấp nhận vô
                            điều kiện và cam kết không có bất kỳ tranh chấp,
                            khiếu nại hay khiếu kiện nào đối với việc xử lý TSĐB
                            của CASC như sau:
                            <br />
                            <pl className="counter-roman">
                              <jl>
                                Thu số dư tiền khả dụng đang có trên TKGDKQ,
                                và/hoặc tự động bán thanh lý một phần hoặc toàn
                                bộ chứng khoán của bất kỳ loại chứng khoán nào
                                có trên TKGDKQ mà không cần có sự chấp thuận của
                                Khách Hàng để thu hồi nợ. Mã chứng khoán, giá
                                bán, số lượng và thời điểm bán do CASC quyết
                                định. Chứng từ liên quan đến việc bán chứng
                                khoán trên TKGDKQ sẽ đương nhiên có giá trị pháp
                                lý mà không cần có xác nhận của Khách Hàng.
                              </jl>
                              <br />
                              <jl>
                                Nếu CASC bán giải chấp chứng khoán trên TKGDKQ,
                                Khách Hàng mặc nhiên ủy quyền cho CASC thực hiện
                                ứng trước khoản tiền bán chứng khoán ngay trong
                                ngày giao dịch để trả nợ vay cho CASC. Khách
                                Hàng mặc nhiên chấp nhận và đồng ý thanh toán
                                khoản phí ứng trước và các khoản thuế, phí liên
                                quan khác theo quy định cho CASC.
                              </jl>
                              <br />
                              <jl>
                                Khách Hàng đồng ý rằng nếu bán hết chứng khoán
                                trên TKGDKQ mà vẫn không đủ để trả hết các khoản
                                nợ gốc, lãi và các trách nhiệm, nghĩa vụ nợ khác
                                của Khách Hàng phát sinh trên TKGDKQ và/hoặc
                                TKGDKQ không có chứng khoán được phép giao dịch,
                                bị mất thanh khoản không bán được (hoặc bán được
                                với số lượng ít theo đánh giá của CASC) thì CASC
                                được toàn quyền khấu trừ các khoản tiền/bán bất
                                kỳ loại chứng khoán nào trên tài khoản giao dịch
                                chứng khoán của Khách Hàng để thu hồi nợ.
                              </jl>
                              <br />
                            </pl>
                          </il>
                        </ol>
                      </li>
                      <li>
                        <b>Các Quy Định Khác:</b>
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Sau khi xử lý toàn bộ TSĐB của Khách Hàng mà CASC
                            vẫn không thu hồi đủ Dư nợ vay GDKQ, Tiền lãi vay,
                            chi phí và các nghĩa vụ khác (nếu có) của Khách
                            Hàng, các khoản phạt … thì Khách Hàng đương nhiên
                            tiếp tục nhận nợ phần nghĩa vụ chưa được thu hồi đủ
                            với CASC (CASC có quyền yêu cầu Khách Hàng thanh
                            toán ngay lập tức). Khách Hàng chịu trách nhiệm dùng
                            toàn bộ tài sản và các nguồn thu nhập của mình để
                            thực hiện nghĩa vụ tiếp tục trả nợ số vay cho CASC.
                            CASC được toàn quyền xử lý các tài sản khác của
                            Khách Hàng để thu hồi phần nghĩa vụ chưa thu hồi đủ
                            này.
                          </il>
                          <br />
                          <il>
                            Việc xử lý TSĐB là các tài sản khác không nằm trên
                            TKGDCK của Khách Hàng được thực hiện theo các văn
                            bản thỏa thuận, cam kết của Khách Hàng và/hoặc bên
                            thứ ba với CASC trên cơ sở các quy định pháp luật
                            hiện hành.
                          </il>
                          <br />
                        </ol>
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>
                        Điều 39: &nbsp;&nbsp; Quyền, Nghĩa Vụ Và Cam Kết Của
                        Khách Hàng
                      </b>
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        <b>Quyền Lợi Của Khách Hàng:</b>
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Gửi yêu cầu sử dụng dịch vụ cho vay GDKQ theo đúng
                            các nội dung quy định trong Phần IV này;
                          </il>
                          <br />
                          <il>
                            Chấm dứt Phần IV này trước thời hạn với điều kiện đã
                            thanh toán mọi khoản dư nợ, phí, chi phí liên quan
                            cho CASC;
                          </il>
                          <br />
                          <il>
                            Khiếu nại, khởi kiện nếu CASC vi phạm các thỏa thuận
                            trong Phần IV này;
                          </il>
                          <br />
                        </ol>
                      </li>
                      <li>
                        <b>Nghĩa Vụ Của Khách Hàng:</b>
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Cung cấp đầy đủ, trung thực và cập nhật thay đổi nếu
                            có liên quan đến các thông tin, tài liệu để mở, quản
                            lý TKGDKQ, GDKQ, vay vốn và chịu trách nhiệm về tính
                            chính xác của các thông tin, tài liệu đã cung cấp;
                            đồng thời tạo mọi điều kiện thuận lợi và không làm
                            bất cứ điều gì khó khăn trở ngại trong trường hợp
                            CASC phải xử lý TSĐB theo quy định tại Phần IV này;
                          </il>
                          <br />
                          <il>
                            Nhận nợ vô điều kiện đối với các khoản tiền vay được
                            CASC tự động giải ngân trên TKGDKQ (không cần có chữ
                            ký của Khách Hàng) để thanh toán cho các giao dịch
                            đã thành công tại CASC và có nghĩa vụ hoàn trả toàn
                            bộ các khoản tiền đó và/hoặc các nghĩa vụ khác cho
                            CASC theo quy định của Phần IV này;
                          </il>
                          <br />
                          <il>
                            Trong trường hợp CASC đồng ý cho Khách Hàng được
                            phép rút/chuyển khoản tiền trên TKGDKQ (khi vẫn còn
                            dư nợ), Khách Hàng cam kết nhận nợ vô điều kiện đối
                            với khoản tiền này, và khoản tiền này cũng được xem
                            là khoản vay của Khách Hàng và được cộng dồn vào Dư
                            nợ vay GDKQ lũy kế;
                          </il>
                          <br />
                          <il>
                            Chủ động theo dõi biến động giá chứng khoán trên thị
                            trường hàng ngày, chủ động bổ sung TSĐB theo quy
                            định của CASC trong trường hợp TSĐB của Khách Hàng
                            bị giảm sút giá trị theo đánh giá của CASC, trả bớt
                            dư nợ vay GDKQ, yêu cầu CASC xử lý một phần hoặc
                            toàn bộ TSĐB để đảm bảo duy trì Tỷ lệ ký quỹ hiện
                            tại luôn lớn hơn Tỷ lệ ký quỹ bắt buộc. Thực hiện
                            công bố thông tin (đối với trường hợp phải công bố
                            thông tin theo quy định của pháp luật) theo yêu cầu
                            của CASC khi CASC phải xử lý TSĐB;
                          </il>
                          <br />
                          <il>
                            Trong thời gian cầm cố TSĐB, TSĐB không được chuyển
                            nhượng, tặng, cho cầm cố, thế chấp TSĐB cho bất kỳ
                            bên thứ ba nào, không thực hiện bất cứ quyền nào
                            phát sinh từ TSĐB, không xâm phạm đến TSĐB hoặc sử
                            dụng biện pháp khác để rút tiền từ TSĐB không đúng
                            với các nội dung tại Phần IV này;
                          </il>
                          <br />
                          <il>
                            Thanh toán phí, chi phí, các khoản thuế liên quan
                            tới việc gửi, giữ, cầm cố, quản lý, phong tỏa TSĐB,
                            xử lý TSĐB, mở, sử dụng và quản lý tài khoản;
                          </il>
                          <br />
                          <il>
                            Thanh toán Dư nợ vay GDKQ khi đến hạn, Tiền lãi,
                            phí, chi phí, các khoản đến hạn khác (nếu có) đầy đủ
                            và đúng hạn cho CASC hoặc bên nhận chuyển nhượng;
                          </il>
                          <br />
                          <il>
                            Cam kết vô điều kiện dùng mọi nguồn thu và tài sản
                            của Khách Hàng để trả đủ số tiền nợ gốc, Tiền lãi,
                            phí, chi phí và các khoản thanh toán đến hạn khác
                            (nếu có) cho CASC hoặc bên nhận chuyển nhượng; đồng
                            thời đồng ý để CASC tự động phong tỏa tài khoản và
                            trích tài khoản của Khách Hàng mở tại CASC để trích
                            tiền thu hồi các khoản nợ gốc, Tiền lãi, phí, phạt
                            bồi thường thiệt hại cho CASC;
                          </il>
                          <br />
                          <il>
                            Ủy quyền cho CASC toàn quyền xử lý TSĐB để thu hồi
                            nợ cho CASC khi Tỷ lệ ký quỹ hiện tại bằng hoặc nhỏ
                            hơn Tỷ lệ ký quỹ bắt buộc. CASC có quyền phối hợp
                            hoặc ủy quyền lại cho bên thứ ba thay mặt CASC xử lý
                            TSĐB;
                          </il>
                          <br />
                          <il>
                            Đồng ý, chấp thuận, tuân thủ đúng và cam kết không
                            có bất khiếu nại, khiếu kiện nào đối với các quy
                            định của Phần IV này;
                          </il>
                          <br />
                          <il>
                            Tự chịu trách nhiệm về các khoản thuế và nghĩa vụ
                            tài chính khác theo quy định của pháp luật cũng như
                            các rủi ro do tổ chức và hoạt động của CASC gây nên.
                          </il>
                          <br />
                        </ol>
                      </li>
                      <li>
                        <b>Cam Kết Của Khách Hàng:</b>
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Khách Hàng đã đọc và thông hiểu quy định về việc vay
                            GDKQ quy định tại Phần IV này và tại đây cam kết
                            thực hiện các quyền và nghĩa vụ theo Phần IV này,
                            các văn bản liên quan theo quy định của pháp luật.
                          </il>
                          <br />
                          <il>
                            Khách Hàng có đủ năng lực dân sự và thẩm quyền cần
                            thiết để sử dụng giao dịch ký quỹ chứng khoán, tuân
                            thủ và thực hiện các nghĩa vụ được quy định tại Phần
                            IV này.
                          </il>
                          <br />
                          <il>
                            Khách Hàng đồng ý rằng: Trừ trường hợp Khách Hàng và
                            CASC có ký văn bản thỏa thuận khác đi một cách rõ
                            ràng, Người được ủy quyền của Khách Hàng liên quan
                            đến TKGDCK của Khách Hàng được hiểu là được ủy quyền
                            tương đương trên cả TKGDCK và TKGDKQ.
                          </il>
                          <br />
                          <il>
                            Người được ủy quyền của Khách Hàng cũng đồng ý sử
                            dụng các tài sản trong tài khoản riêng của Người
                            được ủy quyền mở tại CASC (nếu có) và tất cả các tài
                            sản khác để làm tài sản bảo đảm cho các nghĩa vụ của
                            Khách Hàng trong Phần IV này. Người được ủy quyền
                            đồng ý để CASC phong tỏa và xử lý tài sản trong tài
                            khoản riêng của mình (tương tự như cách xử lý quy
                            định trong Phần IV này) để đảm bảo các nghĩa vụ của
                            Khách Hàng trong các trường hợp mà CASC xét thấy cần
                            thiết. Tuy nhiên, CASC có quyền quyết định xử lý tài
                            sản của Người được ủy quyền theo từng trường hợp cụ
                            thể. Việc CASC không xử lý tài sản của Người được ủy
                            quyền sẽ không được hiểu là miễn trách đối với Khách
                            Hàng trong mọi trường hợp.
                          </il>
                          <br />
                          <il>
                            Trường hợp xảy ra tranh chấp giữa Khách Hàng và
                            Người được ủy quyền giao dịch của Khách Hàng, Khách
                            Hàng và Người được ủy quyền cam kết và đảm bảo không
                            yêu cầu CASC phải tham gia vào quá trình giải quyết
                            tranh chấp dù trong bất kỳ trường hợp nào. Nếu CASC
                            phải tham gia theo yêu cầu của tòa án thì Khách Hàng
                            và Người được ủy quyền sẽ liên đới chịu các phí tổn
                            của CASC để tham gia.
                          </il>
                          <br />
                          <il>
                            Nội dung của Phần IV này và việc Khách Hàng ký kết
                            và thực hiện các giao dịch đó đã được phê duyệt đầy
                            đủ và hợp lệ bởi các cơ quan/ cấp có thẩm quyền nội
                            bộ của Khách Hàng; người ký đại diện cho Khách Hàng
                            trên các hồ sơ giao dịch là người đại diện hợp pháp
                            của Khách Hàng.
                          </il>
                          <br />
                          <il>
                            Khách Hàng cam kết chuyển giao cho CASC bản sao đầy
                            đủ của các quy định/chấp thuận nội bộ cho phép Khách
                            Hàng ký kết và thực hiện Phần IV này; các quy
                            định/chấp thuận đó vẫn còn đầy đủ hiệu lực và chưa
                            bị sửa đổi, thay thế, hủy bỏ hay điều chỉnh theo bất
                            kỳ cách thức nào khác; Trường hợp có sửa đổi, thay
                            thế, hủy bỏ hay điều chỉnh các quy định/chấp thuận
                            nội bộ nêu trên, Khách Hàng cam kết ngay lập tức gửi
                            thông tin cho CASC. Nếu việc không thông báo hoặc
                            thông báo chậm trế dẫn đến vi phạm hoặc có thiệt hại
                            phát sinh, Khách Hàng phải chịu toàn bộ trách nhiệm
                            đối với CASC, bao gồm cả trách nhiệm bồi thường
                            thiệt hại thực tế phát sinh.
                          </il>
                          <br />
                          <il>
                            Đối với trường hợp Khách Hàng chuyển chứng
                            khoán/tiền mặt từ TKGDCK sang TKGDKQ theo quy định
                            của Phần IV này, căn cứ vào phiếu yêu cầu của Khách
                            Hàng lập theo mẫu quy định của CASC, CASC sẽ thực
                            hiện việc chuyển chứng khoán/chuyển tiền vào TKGDKQ.
                            Trường hợp Khách Hàng yêu cầu thông qua điện thoại,
                            giao dịch điện tử…CASC sẽ được quyền thay mặt Khách
                            Hàng lập phiếu và tự động chuyển chứng khoán/tiền
                            mặt theo yêu cầu của Khách Hàng.
                          </il>
                          <br />
                        </ol>
                      </li>
                    </ul>

                    <p className="my-1">
                      <b>Điều 40: &nbsp;&nbsp; Quyền Và Nghĩa Vụ Của CASC</b>
                    </p>
                    <ul className="ul-counter-2 mb-0">
                      <li>
                        <b>Quyền Của CASC:</b>
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Liên quan đến việc quản lý TKGDKQ, Khách Hàng ủy
                            quyền cho CASC và không hủy ngang cho đến khi Khách
                            Hàng hoàn thành mọi nghĩa vụ hoàn trả toàn bộ Dư nợ
                            vay GDKQ cho CASC thực hiện các công việc sau:
                            <br />
                            <pl className="counter-roman">
                              <jl>
                                Thay mặt Khách Hàng xử lý TSĐB để thu hồi các
                                khoản tiền Khách Hàng phải thanh toán cho CASC
                                khi Khách Hàng vi phạm một trong các quy định
                                được thỏa thuận tại Phần IV này;
                              </jl>
                              <br />
                              <jl>
                                Thay mặt Khách Hàng rút tiền để thanh toán các
                                khoản nợ đến hạn cho CASC khi Khách Hàng vi phạm
                                một trong các quy định được thỏa thuận tại Phần
                                IV này;
                              </jl>
                              <br />
                              <jl>
                                Thay mặt Khách Hàng thực hiện bất kỳ nghĩa vụ
                                tài chính nào khác đến hạn đối với CASC theo quy
                                định tại Phần IV này hay bất kỳ thỏa thuận nào
                                khác giữa Khách Hàng và CASC hoặc theo quy định
                                của pháp luật.
                              </jl>
                              <br />
                            </pl>
                          </il>
                          <il>
                            Từ chối giải ngân nếu Khách Hàng không đáp ứng đầy
                            đủ các điều kiện giải ngân, điều kiện về TSĐB, các
                            Tỷ lệ ký quỹ và/hoặc các điều kiện khác theo quy
                            định của CASC trong từng thời kỳ;
                          </il>
                          <br />
                          <il>
                            Từ chối giải ngân trong trường hợp nằm ngoài khả
                            năng cung cấp dịch vụ của CASC;
                          </il>
                          <br />
                          <il>
                            Đơn phương xác định lại, điều chỉnh và/hoặc thay đổi
                            tỷ lệ cho vay đối với bất kỳ mã chứng khoán nào
                            thuộc Danh sách chứng khoán được phép GDKQ;
                          </il>
                          <br />
                          <il>
                            Đơn phương thay đổi các tỷ lệ liên quan tới quản lý,
                            xử lý TSĐB của Khách Hàng…,và/hoặc thay đổi mức lãi
                            suất cho vay, thay đổi cách thức tính lãi vay mà
                            không cần phải thông báo trước;
                          </il>
                          <br />
                          <il>
                            Đơn phương xác định lại, điều chỉnh thay đổi
                            tăng/giảm và chấm dứt hạn mức, sức mua đã cấp cho
                            Khách Hàng;
                          </il>
                          <br />
                          <il>
                            Tự động ghi nợ, ghi có, chuyển khoản, nộp/trích/rút
                            tiền đối với các tài khoản của Khách Hàng tại CASC
                            để:
                            <br />
                            <pl className="counter-roman">
                              <jl>
                                Chuyển tiền thanh toán cho các giao dịch mua,
                                bán, thanh toán phí của Khách Hàng tại CASC để
                                thực hiện các GDKQ theo Phần IV này;
                              </jl>
                              <br />
                              <jl>
                                Thu hồi nợ gốc, nợ lãi, phí, phạt và các nghĩa
                                vụ khác đối với các trường hợp được quy định tại
                                Điều 35 của Phần IV này;
                              </jl>
                              <br />
                              <jl>
                                Được quyền (nhưng không phải là nghĩa vụ) tự
                                động chuyển tiền từ TKGDCK (nếu có) sang TKGDKQ
                                trong trường hợp Khách Hàng không bảo đảm các tỷ
                                lệ yêu cầu trên TKGDKQ;
                              </jl>
                              <br />
                              <jl>
                                Tự động đóng TKGDKQ khi Khách Hàng vi phạm các
                                nội dung theo Phần IV này.
                              </jl>
                              <br />
                            </pl>
                          </il>
                          <il>
                            Khi các khoản vay của Khách Hàng đến hạn trả nợ hoặc
                            phải thanh toán trước hạn theo các quy định tại Phần
                            IV này, CASC được quyền tự động trích tài khoản của
                            Khách Hàng tại CASC hoặc tại bất kỳ tài khoản được
                            ủy quyền giao dịch nào của Khách Hàng;
                          </il>
                          <br />
                          <il>
                            Được quyền xử lý TSĐB theo quy định tại Phần IV này.
                            Trường hợp xử lý TSĐB bằng cách bán chứng khoán trên
                            các SGDCK mà không bán được (do mất thanh khoản tại
                            thời điểm cần xử lý), CASC được quyền (nhưng không
                            phải là nghĩa vụ) nhận chứng khoán là TSĐB theo giá
                            sàn của ngày CASC quyết định xử lý (bằng cách tự
                            động thực hiện giao dịch vào tài khoản tự doanh của
                            CASC) để giảm bớt tương ứng nghĩa vụ thanh toán của
                            Khách Hàng;
                          </il>
                          <br />
                          <il>
                            Được quyền yêu cầu Khách Hàng sử dụng mọi tài sản và
                            nguồn thu khác của Khách Hàng để thanh toán các
                            nghĩa vụ cho CASC trong trường hợp TSĐB hoặc bảo
                            lãnh không đủ để chi trả cho CASC;
                          </il>
                          <br />
                          <il>
                            Được quyền thu hồi nợ trước hạn khi xảy ra các sự
                            kiện quy định tại Điều 35 trên đây;
                          </il>
                          <br />
                          <il>
                            Không cần sự chấp thuận của Khách Hàng, được toàn
                            quyền chuyển giao hoặc chuyển nhượng cho bên thứ 3
                            (gọi tắt là bên nhận chuyển nhượng) thực hiện toàn
                            bộ hoặc một phần các quyền lợi và nghĩa vụ của CASC
                            theo quy định của Phần IV này và các văn bản bổ
                            sung, sửa đổi kèm theo. Trong trường hợp này CASC có
                            nghĩa vụ phải thông báo cho Khách Hàng bằng văn bản;
                          </il>
                          <br />
                          <il>
                            Được quyền thu Tiền lãi và/hoặc các khoản phí khác
                            theo CASC quy định trong từng thời kỳ phù hợp với
                            pháp luật và nội dung của Phần IV này;
                          </il>
                          <br />
                          <il>
                            CASC có quyền gửi Thông báo liên quan đến TKGDKQ cho
                            chính chủ tài khoản và/hoặc người nhận ủy quyền giao
                            dịch.
                          </il>
                          <br />
                        </ol>
                      </li>
                      <li>
                        <b>Nghĩa Vụ Của CASC:</b>
                        <br />
                        <ol className="counter-alpha">
                          <il>
                            Thực hiện nghĩa vụ thông báo cho Khách Hàng các thay
                            đổi về danh mục ký quỹ, các tỷ lệ liên quan tới xử
                            lý, quản lý TSĐB,…quy định tại Phần IV này, các thỏa
                            thuận và văn bản đi kèm (nếu có) theo phương thức do
                            CASC quyết định;
                          </il>
                          <br />
                          <il>
                            Thực hiện đúng quy định tại Phần IV này và các văn
                            bản thỏa thuận khác (nếu có);
                          </il>
                          <br />
                          <il>
                            Thực hiện lưu giữ hồ sơ Khách Hàng phù hợp với các
                            quy định của pháp luật;
                          </il>
                          <br />
                          <il>
                            Thông báo cho Khách Hàng trước và sau khi thực hiện
                            lệnh bán chứng khoán thế chấp và gửi cho Khách Hàng
                            bảng sao kê kết quả giao dịch bán chứng khoán thế
                            chấp theo phương thức do CASC lựa chọn phù hợp với
                            Bộ T&C này.
                          </il>
                          <br />
                        </ol>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </main>
    </div>
  );
}

export default PreviewPage;
