import React, { memo, useEffect, useState } from "react";
import FormResSer from "./step2/formResService";
import { makeGetDataAccount } from "../../lib/selector";
import { useHistory } from "react-router";
import PerfectScrollbar from "react-perfect-scrollbar";
import { translate } from "react-i18next";
import { connect, useDispatch } from "react-redux";

function PageResService(props) {
  const {
    orcData,
    subStep,
    handlePreSubStep,
    handleNextSubStep,
    dataAccount,
    t,
    prePageFirst,
  } = props;

  const history = useHistory();

  useEffect(() => {
    if (!dataAccount || subStep < 6) {
      history.push("/open-account/check-info");
      prePageFirst();
      return;
    }
  }, []);

  return (
    <>
      <div className="md:col-span-2 grid gap-4 auto-rows-max  box-top_margin">
        <div className=" w-100 grid grid-cols-5 gap-1">
          <div className="col-span-5 style-bg-header">
            <span className="text-base font-semibold text-skin-title ml-2">
              {t("register-service")}
            </span>
          </div>
        </div>
      </div>
      <PerfectScrollbar
        style={{
          height: "calc(100vh - 150px)",
        }}
      >
        {subStep === 6 && (
          <FormResSer
            handleNextSubStep={handleNextSubStep}
            handlePreSubStep={handlePreSubStep}
            prePageFirst={prePageFirst}
            orcData={orcData}
          />
        )}
        {/* {subStep === 7 && (
          <FormQuestion
            handlePreSubStep={handlePreSubStep}
            handleNextSubStep={handleNextSubStep}
          />
        )} */}
      </PerfectScrollbar>
    </>
  );
}

const mapStateToProps = (state) => {
  const getDataAccount = makeGetDataAccount();

  return {
    dataAccount: getDataAccount(state),
  };
};

export default connect(mapStateToProps)(
  translate("translations")(PageResService)
);
