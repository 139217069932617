export default {
  register: "Đăng ký",
  "infor-basic": "Thông tin cơ bản",
  "res-service": "Đăng ký dịch vụ",
  "sign-contract": "Ký hợp đồng",

  "infor-acc": "Thông tin tài khoản",
  "select-acc": "Chọn số tài khoản",
  "res-docs": "Xác thực giấy tờ",
  "add-infor": "Bổ sung thông tin",
  "auth-face": "Xác thực khuôn mặt",

  "titleStep-1": "Tiến trình thông tin cơ bản",
  "detail-title-1":
    "Vui lòng cung cấp các thông tin sau để được hỗ trợ Mở tài khoản trực tuyến",
  "detail-title-2":
    "Chọn một số tài khoản ở bên dưới hoặc chọn ngẫu nhiên theo hệ thống",

  "number-phone": "Số điện thoại",
  "number-phone-input": "Nhập số điện thoại",
  email: "Email",
  "sale-id": "Mã người giới thiệu",
  "sale-id-t": "Nhập mã người giới thiệu",
  "sale-invalid": "Mã người giới thiệu không tồn tại, vui lòng thử lại",
  optional: "Không bắt buộc",
  "sale-name": "Tên người giới thiệu",
  "detailStep-1":
    "  Quý khách đồng ý cho IRS sử dụng thông tin cá nhân theo quy định để cải thiện chất lượng dịch vụ và trải nghiệm",
  "detailStep-2":
    "Vui lòng đồng ý với quy định để IRS hoàn tất thủ tục xác thực",

  "acc-normal": "Số tài khoản thường",
  "acc-special": "Số tài khoản đẹp",
  "acc-suggest": "Số tài khoản gợi ý",
  "acc-type": "Loại tài khoản",
  "acc-tt-kq": "Tài khoản thông thường và ký quỹ",
  "acc-tt": "Tài khoản thông thường",

  "cccd-front": "CCCD mặt trước",
  "cccd-back": "CCCD mặt sau",
  "err-cam": "Kiểm tra lại quyền truy cập thiết bị của bạn",
  "err-check-img": "Ảnh không rõ ràng, yêu cầu đưa rõ vào giữa khung hình",
  "suggest-take-img": "Đưa vào chính diện, rõ nét tất cả các thông tin",
  "or-input": "hoặc ấn phím",
  "to-take-img": "để chụp ảnh",

  "take-img": "Chụp ảnh",
  "take-img-l": "Chụp lại",
  "continue-step": "Tiếp tục",
  pre: "Quay lại",

  "detail-title-3":
    "Vui lòng kiểm tra kỹ và chỉnh sửa thông tin chưa chính xác",
  "full-name": "Họ và tên",
  "gender-choise": "Chọn giới tính",
  dob: "Ngày sinh",
  gender: "Giới tính",
  address: "Địa chỉ",
  city: "Thành phố",
  province: "Tỉnh/Thành phố",
  "card-id": "Số giấy tờ tùy thân",
  "issue-date": "Ngày cấp",
  "expiry-date": "Ngày hết hạn",
  nationality: "Quốc tịch",
  "place-issued": "Nơi cấp",
  "re-address": "Địa chỉ thường trú",
  "con-address": "Địa chỉ liên hệ",

  "title-face": "Quý khách lưu ý đảm bảo",
  "list-title-face": "Xác thực khuôn mặt theo hướng dẫn",
  "list-title-face-1": "Khuôn mặt không lệch tâm vòng tròn",
  "list-title-face-2": " Giữ khuôn mặt cố định không rung lắc",

  "check-device": "Kiểm tra lại thiết bị của bạn",
  "face-center": "Vui lòng đưa khuôn mặt vào chính giữa khung hình",
  "turn-left": "Quay mặt sang bên trái",
  "turn-right": "Quay mặt sang bên phải",

  center: "Chính giữa",
  "left-corner": "Góc trái",
  "right-corner": "Góc phải",
  "front-angle": "Góc trước",
  "too-dark-or-bright": "Quá tối hoặc quá sáng",
  "face-covered": "Khuôn mặt bị che",
  "more-than-one-person": "Nhiều hơn một người",

  "basic-information-process": "Tiến trình thông tin cơ bản",
  "register-service": "Đăng ký dịch vụ",

  "advance-cash-auto": "Ứng trước tiền bán tự động",
  "register-beneficiary-account": "Đăng ký tài khoản thụ hưởng",
  "beneficiary-name": "Tên người thụ hưởng",
  "beneficiary-bank": "Ngân hàng thụ hưởng",
  "beneficiary-account-number": "Số tài khoản thụ hưởng",
  "register-sms": "Đăng ký nhận SMS",
  "online-transactions": "Giao dịch trực tuyến qua Internet, ứng dụng di động",
  "telephone-transactions": "Giao dịch qua tổng đài điện thoại",
  "transaction-password": "Mật khẩu giao dịch",
  "email-information-service": "Dịch vụ nhận thông tin qua Email",
  "branch-city-optional": "Chi nhánh, Tỉnh/Thành Phố (Tuỳ chọn)",

  yes: "Có",
  no: "Không",

  "select-bank": "Chọn ngân hàng thụ hưởng",
  "select-city": "Chọn tỉnh, thành phố",
  "select-branch": "Chọn chi nhánh",
  "input-acc-num-ben": "Nhập số tài khoản thụ hưởng",
  "input-password": "Nhập mật khẩu",
  "email-or-sms":
    "Quý khách cần đăng ký dịch vụ nhận thông tin qua Email hoặc SMS. Vui lòng chọn một trong hai dịch vụ nhận thông tin.",
  "name-mismatch":
    "Tên người thụ hưởng của tài khoản không khớp với tên đã định danh trên CCCD. Vui lòng thử lại.",
  "check-info":
    "Vui lòng kiểm tra kỹ thông tin cá nhân dưới đây. Thông tin này sẽ được dùng để mở hợp đồng & tài khoản giao dịch.",

  "check-registration-info": "Kiểm tra thông tin đăng ký",
  "sign-contract": "Ký hợp đồng",

  "personal-info": "Thông tin cá nhân",
  "phone-number": "Số điện thoại",
  email: "Email",
  "id-number": "Số CCCD",
  "permanent-address": "Địa chỉ thường trú",
  "contact-address": "Địa chỉ liên hệ",

  "account-number": "Số tài khoản",
  bank: "Ngân hàng",
  branch: "Chi nhánh",
  "city-province": "Tỉnh/Thành phố",
  "registration-service": "Dịch vụ đăng ký",
  "account-type": "Loại tài khoản",
  "normal-margin-account": "Tài khoản Thông thường và Ký quỹ",
  "normal-account": "Tài khoản Thông thường",
  "transaction-service-package": "Gói dịch vụ giao dịch",
  advisor: "Có chuyên viên tư vấn",
  "no-advisor": "Không có chuyên viên tư vấn",
  "sms-registration": "Đăng ký nhận SMS",
  "email-info-service": "Dịch vụ nhận thông tin qua Email",
  "online-transactions": "Giao dịch trực tuyến qua Internet, ứng dụng di động",
  "phone-transactions": "Giao dịch qua tổng đài điện thoại",
  "auto-sale-advance": "Ứng trước tiền bán tự động",
  "investment-experience-survey": "Câu hỏi khảo sát kinh nghiệm đầu tư",
  "additional-info-fatca":
    "Thông tin bổ sung cho mục đích tuân thủ FATCA (bắt buộc đối với đối tượng cần khai báo)",

  "survey-question": "Câu hỏi khảo sát",
  "investment-questionnaire": "Bộ câu hỏi đầu tư",
  "investment-goal": "Mục tiêu đầu tư",
  "risk-tolerance": "Mức độ chấp nhận rủi ro",
  "investment-experience": "Kinh nghiệm đầu tư",
  "investment-knowledge": "Kiến thức đầu tư",

  "short-term": "Ngắn hạn",
  "medium-term": "Trung hạn",
  "long-term": "Dài hạn",
  other: "Khác",
  low: "Thấp",
  medium: "Trung bình",
  high: "Cao",
  none: "Không có",
  stocks: "Cổ phiếu",
  bonds: "Trái phiếu",
  "mutual-funds": "Chứng chỉ quỹ",
  limited: "Hạn chế",
  fair: "Khá",
  good: "Tốt",

  "investment-questionnaire-fatca":
    "Bộ câu hỏi đầu tư / Thông tin liên quan đến Hoa Kỳ (cho mục đích tuân thủ FATCA)",

  "fatca-description":
    "FATCA là viết tắt của Đạo luật Tuân thủ Thuế đối với Tài khoản Nước ngoài (tiếng Anh: Foreign Account Tax Compliance Act). Đây là luật do Hoa Kỳ ban hành nhằm mục đích ngăn chặn việc trốn thuế của công dân và tổ chức Mỹ thông qua việc sử dụng các tài khoản tài chính ở nước ngoài.",
  "fatca-compliance":
    "Tuân thủ FATCA để bảo vệ tài sản của bạn và quyền lợi của bạn trong thị trường tài chính quốc tế.",
  "not-us-citizen":
    "Khách hàng không phải là công dân Hoa Kỳ/Đối tượng cư trú tại Hoa Kỳ và không có dấu hiệu nhận biết Hoa Kỳ",

  "us-citizen": "Khách hàng là công dân Hoa Kỳ/Đối tượng cư trú tại Hoa Kỳ",
  "us-citizen-definition":
    "Công dân Hoa Kỳ hoặc đối tượng cư trú Hoa Kỳ bao gồm đối tượng có thẻ xanh hoặc cá nhân lưu trú tại Hoa Kỳ ít nhất 31 ngày trong năm hiện tại và 183 ngày trong giai đoạn 3 năm, bao gồm năm hiện tại và 2 năm liền kề trước đó.",
  country: "Quốc gia",
  "address-details": "Địa chỉ chi tiết (Số/Đường)",
  "postal-code": "Mã bưu chính",
  "social-security-number": "Mã an sinh xã hội",
  "state-province-region": "Bang/Tỉnh/Vùng",
  "taxpayer-id":
    "Mã số thuế thu nhập cá nhân theo Internal Revenue Service (IRS)",
  "us-indicators-not-citizen":
    "Khách hàng có dấu hiệu nhận biết Hoa Kỳ nhưng không phải công dân Hoa Kỳ hoặc đối tượng cư trú Hoa Kỳ",
  "us-indicators":
    " KH được xác định là có dấu hiệu nhận biết Hoa Kỳ nếu có một trong các dấu hiệu sau: nơi sinh tại Hoa Kỳ, địa chỉ nhận thư hoặc địa chỉ thường trú tại Hoa Kỳ, số điện thoại liên lạc tại Hoa Kỳ, có chỉ định định kỳ chuyển khoản vào một/nhiều tài khoản tại Hoa Kỳ hay định kỳ nhận tiền từ một/nhiều tài khoản mở tại Hoa Kỳ, có ủy quyền còn hiệu lực cho một đối tượng có địa chỉ tại Hoa Kỳ, có địa chỉ nhận thư hộ hoặc giữ thư tại Hoa Kỳ.",

  name: "Nguyễn Văn A",
  "country-example": "VD: Mỹ",
  "city-example": "VD: Houston",
  state: "Washington",
  "address-example": "VD: 100st RoadWay",
  "postal-code-t": "Nhập mã",
  "social-security-number-t": "Nhập mã",
  "tax-id-t": "Nhập mã",
  declaration:
    "Bạn cam đoan các thông tin được kê khai là chính xác và cam kết sẽ thông báo cho CTCK ngay khi có sự thay đổi về các thông tin đã khai như trên.",
  "save-infor": "Lưu thông tin",

  "agreement-signature":
    "Bằng việc ký tên dưới đây, Tôi đồng ý và xác nhận rằng:",
  "terms-acceptance":
    "Đã xem xét, đọc kỹ và chấp thuận Các Điều khoản và Điều kiện Mở tài khoản giao dịch chứng khoán và/hoặc Các Điều khoản và Điều kiện Mở tài khoản giao dịch ký quỹ.",
  "view-terms": "Quý khách xem các Điều khoản và Điều kiện đầy đủ",
  here: "Tại đây",
  "processing-error": "Lỗi trong quá trình xử lý",

  "please-wait-face":
    "Vui lòng chờ trong giây lát để hệ thống nhận diện khuôn mặt",
};
