export default {
  register: "Register",
  "infor-basic": "Basic Information",
  "res-service": "Register Service",
  "sign-contract": "Sign Contract",

  "infor-acc": "Account Information",
  "select-acc": "Select Account Number",
  "res-docs": "Verify Documents",
  "add-infor": "Add Information",
  "auth-face": "Face Verification",

  "titleStep-1": "Basic Information Process",
  "detail-title-1":
    "Please provide the following information to assist with online account opening.",
  "detail-title-2":
    "Select an account number below or choose randomly by the system.",

  "number-phone": "Phone Number",
  "number-phone-input": "Enter phone number",
  email: "Email",
  "sale-id": "Referral Code",
  "sale-id-t": "Enter reference code",
  "sale-invalid": "Referral code does not exist, please try again",
  optional: "Optional",
  "sale-name": "Referrer's Name",
  "detailStep-1":
    "You agree to allow IRS to use personal information as per regulations to improve service quality and experience.",
  "detailStep-2":
    "Please agree to the regulations for IRS to complete the verification process.",

  "acc-normal": "Normal Account",
  "acc-special": "Special Account",
  "acc-suggest": "Suggested Account",
  "acc-type": "Account Type",
  "acc-tt-kq": "Regular and Margin Accounts",
  "acc-tt": "Regular Account",

  "cccd-front": "ID Card Front",
  "cccd-back": "ID Card Back",
  "err-cam": "Check your device's access permissions",
  "err-check-img": "Image is unclear, please center it within the frame",
  "suggest-take-img":
    "Position directly in front, clearly showing all information",
  "or-input": "or press the key",
  "to-take-img": "to take a photo",

  "take-img": "Take Photo",
  "take-img-l": "Retake",
  "continue-step": "Continue",
  pre: "Back",

  "detail-title-3":
    "Please carefully review and correct any inaccurate information",
  "full-name": "Full Name",
  "gender-choise": "Select Gender",
  dob: "Date of Birth",
  gender: "Gender",
  address: "Address",
  city: "City",
  province: "Province/City",
  "card-id": "ID Number",
  "issue-date": "Issue Date",
  "expiry-date": "Expiry Date",
  nationality: "Nationality",
  "place-issued": "Place of Issue",
  "re-address": "Permanent Address",
  "con-address": "Contact Address",

  "title-face": "Please ensure the following",
  "list-title-face": "Face verification according to instructions",
  "list-title-face-1": "Face should be centered within the circle",
  "list-title-face-2": "Keep face steady without shaking",

  "check-device": "Check your device",
  "face-center": "Please center your face within the frame",
  "turn-left": "Turn your face to the left",
  "turn-right": "Turn your face to the right",

  center: "Center",
  "left-corner": "Left Corner",
  "right-corner": "Right Corner",
  "too-dark-or-bright": "Too Dark or Too Bright",
  "face-covered": "Face Covered",
  "more-than-one-person": "More Than One Person",

  "basic-information-process": "Basic Information Process",
  "register-service": "Register Service",

  "advance-cash-auto": "Advance automatic sale payment",
  "register-beneficiary-account": "Register beneficiary account",
  "beneficiary-name": "Beneficiary name",
  "beneficiary-bank": "Beneficiary bank",
  "beneficiary-account-number": "Beneficiary account number",
  "register-sms": "Register for SMS",
  "online-transactions":
    "Online transactions via Internet, mobile applications",
  "telephone-transactions": "Transactions via telephone",
  "transaction-password": "Transaction password",
  "email-information-service": "Email information service",
  "branch-city-optional": "Branch, Province/City (Optional)",

  yes: "Yes",
  no: "No",

  "select-bank": "Select Beneficiary Bank",
  "select-city": "Select Province/City",
  "select-branch": "Select Branch",
  "input-acc-num-ben": "Enter beneficiary account number",
  "input-password": "Enter password",
  "email-or-sms":
    "You need to register for information services via Email or SMS. Please choose one of the two information services.",
  "check-info":
    "Please carefully check the personal information below. This information will be used to open the contract and trading account.",

  "check-registration-info": "Check Registration Information",
  "sign-contract": "Sign Contract",

  "personal-info": "Personal Information",
  "phone-number": "Phone Number",
  email: "Email",
  "id-number": "ID Number",
  "permanent-address": "Permanent Address",
  "contact-address": "Contact Address",

  "account-number": "Account Number",
  bank: "Bank",
  branch: "Branch",
  "city-province": "City/Province",
  "registration-service": "Registration Service",
  "account-type": "Account Type",
  "normal-margin-account": "Normal and Margin Account",
  "normal-account": "Normal Account",
  "transaction-service-package": "Transaction Service Package",
  advisor: "With Advisor",
  "no-advisor": "Without Advisor",
  "sms-registration": "Register to Receive SMS",
  "email-info-service": "Email Information Service",
  "online-transactions":
    "Online Transactions via Internet, mobile applications",
  "phone-transactions": "Transactions via Phone",
  "auto-sale-advance": "Auto-Sale Advance",
  "investment-experience-survey": "Investment Experience Survey",
  "additional-info-fatca":
    "Additional information for FATCA compliance (required for reporting entities)",

  "survey-question": "Survey Questions",
  "investment-questionnaire": "Investment Questionnaire",
  "investment-goal": "Investment Goal",
  "risk-tolerance": "Risk Tolerance",
  "investment-experience": "Investment Experience",
  "investment-knowledge": "Investment Knowledge",

  "short-term": "Short-term",
  "medium-term": "Medium-term",
  "long-term": "Long-term",
  other: "Other",
  low: "Low",
  medium: "Medium",
  high: "High",
  none: "None",
  stocks: "Stocks",
  bonds: "Bonds",
  "mutual-funds": "Mutual Funds",
  limited: "Limited",
  fair: "Fair",
  good: "Good",

  "investment-questionnaire-fatca":
    "Investment Questionnaire / Information Related to the United States (for FATCA Compliance)",
  "fatca-description":
    "FATCA stands for the Foreign Account Tax Compliance Act. This is a U.S. law aimed at preventing U.S. citizens and organizations from evading taxes through the use of financial accounts held abroad.",
  "fatca-compliance":
    "Complying with FATCA helps protect your assets and your interests in the international financial market.",
  "not-us-citizen":
    "The customer is not a U.S. citizen/resident and does not have any U.S. indicators.",
  "us-citizen": "The customer is a U.S. citizen/resident.",
  "us-citizen-definition":
    "U.S. citizens or U.S. residents include individuals with a green card or those who stay in the U.S. for at least 31 days in the current year and 183 days over a 3-year period, including the current year and the 2 preceding years.",

  country: "Country",
  "address-details": "Address Details (Number/Street)",
  "postal-code": "Postal Code",
  "social-security-number": "Social Security Number",
  "state-province-region": "State/Province/Region",
  "taxpayer-id":
    "Taxpayer Identification Number according to the Internal Revenue Service (IRS)",
  "us-indicators-not-citizen":
    "Customer has U.S. indicators but is not a U.S. citizen or resident",
  "us-indicators":
    "A customer is identified as having U.S. indicators if they have any of the following: place of birth in the U.S., mailing or residential address in the U.S., contact telephone number in the U.S., periodic transfers to one or more accounts in the U.S. or periodic receipts of funds from one or more accounts held in the U.S., a valid power of attorney for an individual with an address in the U.S., or a mailing address or mailbox in the U.S.",

  name: "John Doe",
  "country-example": "e.g., USA",
  "city-example": "e.g., Houston",
  state: "Washington",
  "address-example": "e.g., 100st RoadWay",
  "postal-code-t": "Enter code",
  "social-security-number-t": "Enter code",
  "tax-id-t": "Enter code",
  declaration:
    "You confirm that the information provided is accurate and commit to notifying the securities company immediately if there are any changes to the information provided above.",
  "save-infor": "Save Infor",

  "agreement-signature": "By signing below, I agree and acknowledge that:",
  "terms-acceptance":
    "I have reviewed, read carefully, and accepted the Terms and Conditions for opening a securities trading account and/or the Terms and Conditions for opening a margin trading account.",
  "view-terms": "You can view the full Terms and Conditions",
  here: "Here",
  "processing-error": "Error during processing",
  "front-angle": "Front angle",

  "please-wait-face": "Please wait a moment for the system to detect the face",
};
