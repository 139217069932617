import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import { makeGetDataAccount } from "../../../lib/selector";
import { RiInformationLine } from "react-icons/ri";

import _ from "lodash";
import RenderRadio from "../../../components/input/renderRadio";
import RenderInput from "../../../components/input/renderInputLogin";
import RenderCheckbox from "../../../components/input/renderCheckbox";
import RenderFieldCheckbox from "../../../components/input/renderCheckbox";

import { Tooltip, OverlayTrigger, Button } from "react-bootstrap";

import { setDataAccount } from "../../client/actions";

import { translate } from "react-i18next";

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function FormQuestion(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    handlePreSubStep,
    formQuest,
    formCheckUsa,
    formCheckUsaBut,
    dataAccount,
    handleNextSubStep,
    formCheckNotUsa,
    isAgree,
    closeFormQuestion,
    t,
  } = props;

  const [nextTab, setNextTab] = useState(false);
  const [dimensions, setDimensions] = useState(window.innerWidth);
  const [validCheck, setValidCheck] = useState(false);
  const [validAgree, setValidAgree] = useState(true);

  useEffect(() => {
    function handleResize() {
      setDimensions(window.innerWidth);
    }

    innitForm();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!nextTab) return;
    handleNextStep();
  }, [nextTab]);

  useEffect(() => {
    if (formCheckNotUsa || formCheckUsa || formCheckUsaBut || formQuest)
      setValidCheck(true);
    else setValidCheck(false);
  }, [formCheckNotUsa, formCheckUsa, formCheckUsaBut, formQuest]);

  useEffect(() => {
    if (formQuest && !dataAccount?.QUEST_INVEST) {
      dispatch(change("formResStep22", "formQuest1", "0"));
      dispatch(change("formResStep22", "formQuest2", "0"));
      dispatch(change("formResStep22", "formQuest3", "0"));
      dispatch(change("formResStep22", "formQuest4", "0"));
    } else if (dataAccount?.QUEST_INVEST) {
      dispatch(change("formResStep22", "formQuest1", dataAccount.INVEST_SHORT));
      dispatch(change("formResStep22", "formQuest2", dataAccount.RISK_LOW));
      dispatch(change("formResStep22", "formQuest3", dataAccount.INVEST_NONE));
      dispatch(change("formResStep22", "formQuest4", dataAccount.INVEST_NO));
    }
  }, [formQuest, dataAccount]);

  function innitForm() {
    if (!dataAccount) return;
    dispatch(
      change("formResStep22", "formFullNameUsa", dataAccount?.C_FULL_NAME)
    );
    dispatch(
      change("formResStep22", "formFullNameUsaBut", dataAccount?.C_FULL_NAME)
    );
    if (dataAccount?.QUEST_CHECK_NOT_USA) {
      dispatch(
        change(
          "formResStep22",
          "formCheckNotUsa",
          dataAccount.QUEST_CHECK_NOT_USA
        )
      );
    }
    if (dataAccount?.QUEST_INVEST) {
      dispatch(change("formResStep22", "formQuest", dataAccount.QUEST_INVEST));
    }

    if (dataAccount?.CHECK_USA) {
      dispatch(change("formResStep22", "formCheckUsa", dataAccount.CHECK_USA));
      dispatch(
        change(
          "formResStep22",
          "formFullNameUsa",
          dataAccount.FULL_NAME_USA_FACT
        )
      );
      dispatch(
        change("formResStep22", "formNationUsa", dataAccount.NATION_USA_FACT)
      );
      dispatch(
        change(
          "formResStep22",
          "formProvinceUsa",
          dataAccount.PROVINCE_USA_FACT
        )
      );
      dispatch(
        change("formResStep22", "formCityUsa", dataAccount.CITY_USA_FACT)
      );
      dispatch(
        change("formResStep22", "formAddressUsa", dataAccount.ADDRESS_USA_FACT)
      );
      dispatch(
        change("formResStep22", "formPostalUsa", dataAccount.POSTAL_USA_FACT)
      );
      dispatch(
        change("formResStep22", "formSocialUsa", dataAccount.SOCIAL_USA_FACT)
      );
      dispatch(
        change("formResStep22", "formTaxCodeUsa", dataAccount.TAX_CODE_USA_FACT)
      );
    }

    if (dataAccount?.CHECK_USA_BUT) {
      dispatch(
        change("formResStep22", "formCheckUsaBut", dataAccount.CHECK_USA_BUT)
      );
      dispatch(
        change(
          "formResStep22",
          "formFullNameUsaBut",
          dataAccount.FULL_NAME_USA_BUT_FACT
        )
      );
      dispatch(
        change(
          "formResStep22",
          "formNationUsaBut",
          dataAccount.NATION_USA_BUT_FACT
        )
      );
      dispatch(
        change(
          "formResStep22",
          "formProvinceUsaBut",
          dataAccount.PROVINCE_USA_BUT_FACT
        )
      );
      dispatch(
        change("formResStep22", "formCityUsaBut", dataAccount.CITY_USA_BUT_FACT)
      );
      dispatch(
        change(
          "formResStep22",
          "formAddressUsaBut",
          dataAccount.ADDRESS_USA_BUT_FACT
        )
      );
      dispatch(
        change(
          "formResStep22",
          "formPostalUsaBut",
          dataAccount.POSTAL_USA_BUT_FACT
        )
      );
      dispatch(
        change(
          "formResStep22",
          "formSocialUsaBut",
          dataAccount.SOCIAL_USA_BUT_FACT
        )
      );
      dispatch(
        change(
          "formResStep22",
          "formTaxCodeUsaBut",
          dataAccount.TAX_CODE_USA_BUT_FACT
        )
      );
    }
  }

  const handleNextStep = () => {
    history.push("/open-account/sign-contract");
    handleNextSubStep();
    setNextTab(false);
  };

  function submit(values) {
    if (
      (validCheck && !validAgree) ||
      (!values?.formCheckNotUsa &&
        !values?.formCheckUsa &&
        !values?.formCheckUsaBut &&
        !values?.formQuest)
    )
      return;

    let _dataAcc = Object.assign({}, dataAccount);

    _dataAcc.QUEST_CHECK_NOT_USA = values?.formCheckNotUsa || "";
    _dataAcc.CHECK_USA = values?.formCheckUsa || "";
    _dataAcc.CHECK_USA_BUT = values?.formCheckUsaBut || "";
    _dataAcc.QUEST_INVEST = values?.formQuest || "";
    if (values?.formQuest) {
      _dataAcc.INVEST_SHORT = values?.formQuest1;
      _dataAcc.RISK_LOW = values?.formQuest2;
      _dataAcc.INVEST_NONE = values?.formQuest3;
      _dataAcc.INVEST_NO = values?.formQuest4;
    }
    if (values?.formCheckUsa) {
      _dataAcc.FULL_NAME_USA_FACT = values?.formFullNameUsa;
      _dataAcc.NATION_USA_FACT = values?.formNationUsa;
      _dataAcc.PROVINCE_USA_FACT = values?.formProvinceUsa;
      _dataAcc.CITY_USA_FACT = values?.formCityUsa;
      _dataAcc.ADDRESS_USA_FACT = values?.formAddressUsa;
      _dataAcc.POSTAL_USA_FACT = values?.formPostalUsa;
      _dataAcc.SOCIAL_USA_FACT = values?.formSocialUsa;
      _dataAcc.TAX_CODE_USA_FACT = values?.formTaxCodeUsa;
    }
    if (values?.formCheckUsaBut) {
      _dataAcc.FULL_NAME_USA_BUT_FACT = values?.formFullNameUsaBut;
      _dataAcc.NATION_USA_BUT_FACT = values?.formNationUsaBut;
      _dataAcc.PROVINCE_USA_BUT_FACT = values?.formProvinceUsaBut;
      _dataAcc.CITY_USA_BUT_FACT = values?.formCityUsaBut;
      _dataAcc.ADDRESS_USA_BUT_FACT = values?.formAddressUsaBut;
      _dataAcc.POSTAL_USA_BUT_FACT = values?.formPostalUsaBut;
      _dataAcc.SOCIAL_USA_BUT_FACT = values?.formSocialUsaBut;
      _dataAcc.TAX_CODE_USA_BUT_FACT = values?.formTaxCodeUsaBut;
    }

    dispatch(setDataAccount(_dataAcc));
    closeFormQuestion();
  }

  return (
    <div>
      <form onSubmit={handleSubmit(submit)} className="flex flex-column gap-6">
        <div className="d-flex flex-column gap-4">
          <div className="col-span-5 style-bg-header-ques">
            <span className="text-base font-semibold text-skin-title">
              {t("survey-question")}
            </span>
          </div>
          <div
            className="rounded-xl border border-skin-weak flex flex-column gap-1 form-group"
            style={{
              padding: "12px",
            }}
          >
            <div className="flex flex-row items-center gap-1">
              <div
                className="relative "
                style={{
                  top: "-13px",
                  width: "20px",
                }}
              >
                <Field name="formQuest" component={RenderCheckbox} />
              </div>
              <span className="text-sm font-medium text-skin-title">
                {t("investment-questionnaire")}
              </span>
            </div>
            {formQuest && (
              <div className="flex flex-column gap-r-6">
                <div
                  className="rounded-2xl flex flex-column gap-3"
                  style={{
                    padding: "8px",
                  }}
                >
                  <ul
                    className="list-square"
                    style={{
                      paddingLeft: "16px",
                    }}
                  >
                    <li className="text-xs text-skin-yellow">
                      {t("investment-goal")}
                    </li>
                  </ul>
                  <div className="grid grid-cl-4 gap-radio">
                    <Field
                      name="formQuest1"
                      component={RenderRadio}
                      type="radio"
                      value="0"
                      labelName={t("short-term")}
                    />
                    <Field
                      name="formQuest1"
                      component={RenderRadio}
                      type="radio"
                      value="1"
                      labelName={t("medium-term")}
                    />
                    <Field
                      name="formQuest1"
                      component={RenderRadio}
                      type="radio"
                      value="2"
                      labelName={t("long-term")}
                    />
                    <Field
                      name="formQuest1"
                      component={RenderRadio}
                      type="radio"
                      value="3"
                      labelName={t("other")}
                    />
                  </div>
                </div>
                <div
                  className="rounded-2xl flex flex-column gap-3"
                  style={{
                    padding: "12px",
                  }}
                >
                  <ul
                    className="list-square"
                    style={{
                      paddingLeft: "16px",
                    }}
                  >
                    <li className="text-xs text-skin-yellow">
                      {t("risk-tolerance")}
                    </li>
                  </ul>
                  <div className="grid grid-cl-4 gap-radio">
                    <Field
                      name="formQuest2"
                      component={RenderRadio}
                      type="radio"
                      value="0"
                      labelName={t("low")}
                    />
                    <Field
                      name="formQuest2"
                      component={RenderRadio}
                      type="radio"
                      value="1"
                      labelName={t("medium")}
                    />
                    <Field
                      name="formQuest2"
                      component={RenderRadio}
                      type="radio"
                      value="2"
                      labelName={t("high")}
                    />
                  </div>
                </div>
                <div
                  className="rounded-2xl flex flex-column gap-3"
                  style={{
                    padding: "12px",
                  }}
                >
                  <ul
                    className="list-square"
                    style={{
                      paddingLeft: "16px",
                    }}
                  >
                    <li className="text-xs text-skin-yellow">
                      {t("investment-experience")}
                    </li>
                  </ul>
                  <div className="grid grid-cl-4 gap-radio">
                    <Field
                      name="formQuest3"
                      component={RenderRadio}
                      type="radio"
                      value="0"
                      labelName={t("none")}
                    />
                    <Field
                      name="formQuest3"
                      component={RenderRadio}
                      type="radio"
                      value="1"
                      labelName={t("stocks")}
                    />
                    <Field
                      name="formQuest3"
                      component={RenderRadio}
                      type="radio"
                      value="2"
                      labelName={t("bonds")}
                    />
                    <Field
                      name="formQuest3"
                      component={RenderRadio}
                      type="radio"
                      value="3"
                      labelName={t("mutual-funds")}
                    />
                  </div>
                </div>
                <div
                  className="rounded-2xl flex flex-column gap-3"
                  style={{
                    padding: "12px",
                  }}
                >
                  <ul
                    className="list-square"
                    style={{
                      paddingLeft: "16px",
                    }}
                  >
                    <li className="text-xs text-skin-yellow">
                      {t("investment-knowledge")}
                    </li>
                  </ul>
                  <div className="grid grid-cl-4 gap-radio">
                    <Field
                      name="formQuest4"
                      component={RenderRadio}
                      type="radio"
                      value="0"
                      labelName={t("no")}
                    />
                    <Field
                      name="formQuest4"
                      component={RenderRadio}
                      type="radio"
                      value="1"
                      labelName={t("limited")}
                    />
                    <Field
                      name="formQuest4"
                      component={RenderRadio}
                      type="radio"
                      value="2"
                      labelName={t("fair")}
                    />
                    <Field
                      name="formQuest4"
                      component={RenderRadio}
                      type="radio"
                      value="3"
                      labelName={t("good")}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex flex-column gap-4">
          <div className="flex flex-column gap-4">
            <div className="col-span-5 style-bg-header-ques">
              <span className="d-flex flex-row align-items-center text-base	font-semibold text-skin-title">
                {t("investment-questionnaire-fatca")}
                <OverlayTrigger
                  delay={{ hide: 0, show: 0 }}
                  overlay={(props) => (
                    <Tooltip {...props}>{t("fatca-description")}</Tooltip>
                  )}
                  placement={dimensions >= 1400 ? "right" : "top"}
                  style={{
                    maxWidth: "300px",
                  }}
                >
                  <RiInformationLine className="cursor-pointer text-skin-gray ic-tooltip" />
                </OverlayTrigger>
              </span>
            </div>

            <span className="text-xs font-normal text-skin-body">
              * {t("fatca-compliance")}
            </span>
          </div>
          <div className="flex flex-column gap-r-6">
            <div
              className="rounded-xl border border-skin-weak flex flex-row items-center gap-1 form-group"
              style={{
                padding: "12px",
              }}
            >
              <div
                className="relative"
                style={{
                  top: "-13px",
                  width: "20px",
                }}
              >
                <Field name="formCheckNotUsa" component={RenderCheckbox} />
              </div>
              <span className="text-sm font-medium text-skin-title">
                {t("not-us-citizen")}
              </span>
            </div>
            <div
              className="rounded-xl border border-skin-weak d-flex flex-column gap-r-6 form-group"
              style={{
                padding: "12px",
              }}
            >
              <div className="flex flex-row gap-1">
                {" "}
                <div
                  className="relative"
                  style={{
                    top: "-13px",
                    width: "20px",
                  }}
                >
                  <Field name="formCheckUsa" component={RenderCheckbox} />
                </div>
                <span className="d-flex flex-row align-items-center text-sm font-medium text-skin-title">
                  {t("us-citizen")}
                  <OverlayTrigger
                    delay={{ hide: 0, show: 0 }}
                    overlay={(props) => (
                      <Tooltip {...props}>{t("us-citizen-definition")}</Tooltip>
                    )}
                    placement="top"
                    style={{
                      maxWidth: "300px",
                    }}
                  >
                    <RiInformationLine className="cursor-pointer text-skin-gray ic-tooltip" />
                  </OverlayTrigger>
                </span>
              </div>
              {formCheckUsa && (
                <div className="grid grid-cols-6 gap-r-6">
                  <div className=" col-span-6">
                    <label className="text-xs	text-skin-title">
                      {t("full-name")}
                    </label>
                    <Field
                      name="formFullNameUsa"
                      type="text"
                      placeholder={t("name")}
                      component={RenderInput}
                      className="py-2 px-4 mt-2 w-100"
                    />
                  </div>
                  <div className=" grid-res-26">
                    <label className="text-xs	text-skin-title">
                      {t("country")}
                    </label>
                    <Field
                      name="formNationUsa"
                      type="text"
                      placeholder={t("country-example")}
                      component={RenderInput}
                      className="py-2 px-4 mt-2 w-100"
                    />
                  </div>
                  <div className=" grid-res-26">
                    <label className="text-xs	text-skin-title">
                      {t("state-province-region")}
                    </label>
                    <Field
                      name="formProvinceUsa"
                      type="text"
                      placeholder={t("city-example")}
                      component={RenderInput}
                      className="py-2 px-4 mt-2 w-100"
                    />
                  </div>
                  <div className=" grid-res-26">
                    <label className="text-xs	text-skin-title">
                      {t("city")}
                    </label>
                    <Field
                      name="formCityUsa"
                      type="text"
                      placeholder={t("state")}
                      component={RenderInput}
                      className="py-2 px-4 mt-2 w-100"
                    />
                  </div>
                  <div className=" grid-res-36">
                    <label className="text-xs	text-skin-title">
                      {t("address-details")}
                    </label>
                    <Field
                      name="formAddressUsa"
                      type="text"
                      placeholder={t("address-example")}
                      component={RenderInput}
                      className="py-2 px-4 mt-2 w-100"
                    />
                  </div>
                  <div className=" grid-res-36">
                    <label className="text-xs	text-skin-title">
                      {t("postal-code")}
                    </label>
                    <Field
                      name="formPostalUsa"
                      type="text"
                      placeholder={t("postal-code-t")}
                      component={RenderInput}
                      className="py-2 px-4 mt-2 w-100"
                    />
                  </div>
                  <div className=" grid-res-36">
                    <label className="text-xs	text-skin-title">
                      {t("social-security-number")}
                    </label>
                    <Field
                      name="formSocialUsa"
                      type="text"
                      placeholder={t("social-security-number-t")}
                      component={RenderInput}
                      className="py-2 px-4 mt-2 w-100"
                    />
                  </div>
                  <div className=" grid-res-36">
                    <label className="text-xs	text-skin-title">
                      {t("taxpayer-id")}
                    </label>
                    <Field
                      name="formTaxCodeUsa"
                      type="text"
                      placeholder={t("tax-id-t")}
                      component={RenderInput}
                      className="py-2 px-4 mt-2 w-100"
                    />
                  </div>
                </div>
              )}
            </div>
            <div
              className="rounded-xl border border-skin-weak d-flex flex-column gap-r-6 form-group "
              style={{
                padding: "12px",
              }}
            >
              <div className=" flex flex-row gap-1">
                <div
                  className="relative"
                  style={{
                    top: "-13px",
                    width: "20px",
                  }}
                >
                  <Field name="formCheckUsaBut" component={RenderCheckbox} />
                </div>
                <span className="d-flex flex-row align-items-center text-sm font-medium text-skin-title ">
                  {t("us-indicators-not-citizen")}
                  <OverlayTrigger
                    delay={{ hide: 0, show: 0 }}
                    overlay={(props) => (
                      <Tooltip {...props}>{t("us-indicators")}</Tooltip>
                    )}
                    placement="top"
                    style={{
                      maxWidth: "300px",
                    }}
                  >
                    <RiInformationLine className="cursor-pointer text-skin-gray ic-tooltip" />
                  </OverlayTrigger>
                </span>
              </div>
              {formCheckUsaBut && (
                <div className="grid grid-cols-6 gap-r-6">
                  <div className=" col-span-6">
                    <label className="text-xs	text-skin-title">
                      {t("full-name")}
                    </label>
                    <Field
                      name="formFullNameUsaBut"
                      type="text"
                      placeholder={t("name")}
                      component={RenderInput}
                      className="py-2 px-4 mt-2 w-100"
                    />
                  </div>
                  <div className=" grid-res-26">
                    <label className="text-xs	text-skin-title">
                      {t("country")}
                    </label>
                    <Field
                      name="formNationUsaBut"
                      type="text"
                      placeholder={t("country-example")}
                      component={RenderInput}
                      className="py-2 px-4 mt-2 w-100"
                    />
                  </div>
                  <div className=" grid-res-26">
                    <label className="text-xs	text-skin-title">
                      {t("state-province-region")}
                    </label>
                    <Field
                      name="formProvinceUsaBut"
                      type="text"
                      placeholder={t("city-example")}
                      component={RenderInput}
                      className="py-2 px-4 mt-2 w-100"
                    />
                  </div>
                  <div className=" grid-res-26">
                    <label className="text-xs	text-skin-title">
                      {t("city")}
                    </label>
                    <Field
                      name="formCityUsaBut"
                      type="text"
                      placeholder={t("state")}
                      component={RenderInput}
                      className="py-2 px-4 mt-2 w-100"
                    />
                  </div>
                  <div className=" grid-res-36">
                    <label className="text-xs	text-skin-title">
                      {t("address-details")}
                    </label>
                    <Field
                      name="formAddressUsaBut"
                      type="text"
                      placeholder={t("address-example")}
                      component={RenderInput}
                      className="py-2 px-4 mt-2 w-100"
                    />
                  </div>
                  <div className=" grid-res-36">
                    <label className="text-xs	text-skin-title">
                      {t("postal-code")}
                    </label>
                    <Field
                      name="formPostalUsaBut"
                      type="text"
                      placeholder={t("postal-code-t")}
                      component={RenderInput}
                      className="py-2 px-4 mt-2 w-100"
                    />
                  </div>
                  <div className=" grid-res-36">
                    <label className="text-xs	text-skin-title">
                      {t("social-security-number")}
                    </label>
                    <Field
                      name="formSocialUsaBut"
                      type="text"
                      placeholder={t("social-security-number-t")}
                      component={RenderInput}
                      className="py-2 px-4 mt-2 w-100"
                    />
                  </div>
                  <div className=" grid-res-36">
                    <label className="text-xs	text-skin-title">
                      {t("taxpayer-id")}
                    </label>
                    <Field
                      name="formTaxCodeUsaBut"
                      type="text"
                      placeholder={t("tax-id-t")}
                      component={RenderInput}
                      className="py-2 px-4 mt-2 w-100"
                    />
                  </div>
                </div>
              )}
            </div>

            {validCheck && (
              <div className="d-flex flex-row">
                <div className="d-flex align-items-baseline mt-1">
                  <Field
                    name="isAgree"
                    component={RenderFieldCheckbox}
                    checked={validAgree}
                    onChange={() => setValidAgree(!validAgree)}
                  />
                </div>

                <span className="text-skin-subdued text-sm">
                  {" "}
                  {t("declaration")}
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-row-reverse justify-content-start gap-2 w-100">
          <button
            type="submit"
            className={`btn-submit  ${
              validCheck && !validAgree ? "disabled" : "success"
            } `}
          >
            {t("save-infor")}
          </button>{" "}
          <button className="btn-close  " onClick={() => closeFormQuestion()}>
            {t("pre")}
          </button>
        </div>
      </form>
    </div>
  );
}

FormQuestion = reduxForm({
  form: "formResStep22",
  enableReinitialize: true,
})(FormQuestion);

const selector = formValueSelector("formResStep22");

const mapStateToProps = (state) => {
  const getDataAccount = makeGetDataAccount();

  const {
    formCheckNotUsa,
    formCheckUsa,
    formCheckUsaBut,
    formQuest,

    formFullNameUsa,
    formNationUsa,
    formProvinceUsa,
    formCityUsa,
    formAddressUsa,
    formPostalUsa,
    formSocialUsa,
    formTaxCodeUsa,
    formFullNameUsaBut,
    formNationUsaBut,
    formProvinceUsaBut,
    formCityUsaBut,
    formAddressUsaBut,
    formPostalUsaBut,
    formSocialUsaBut,
    formTaxCodeUsaBut,

    formQuest1,
    formQuest2,
    formQuest3,
    formQuest4,
    isAgree,
  } = selector(
    state,
    "formCheckNotUsa",
    "formCheckUsa",
    "formCheckUsaBut",
    "formQuest",
    "formFullNameUsa",
    "formNationUsa",
    "formProvinceUsa",
    "formCityUsa",
    "formAddressUsa",
    "formPostalUsa",
    "formSocialUsa",
    "formTaxCodeUsa",
    "formFullNameUsaBut",
    "formNationUsaBut",
    "formProvinceUsaBut",
    "formCityUsaBut",
    "formAddressUsaBut",
    "formPostalUsaBut",
    "formSocialUsaBut",
    "formTaxCodeUsaBut",
    "formQuest1",
    "formQuest2",
    "formQuest3",
    "formQuest4",
    "isAgree"
  );

  return {
    dataAccount: getDataAccount(state),

    formCheckNotUsa,
    formCheckUsa,
    formCheckUsaBut,
    formQuest,

    formFullNameUsa,
    formNationUsa,
    formProvinceUsa,
    formCityUsa,
    formAddressUsa,
    formPostalUsa,
    formSocialUsa,
    formTaxCodeUsa,
    formFullNameUsaBut,
    formNationUsaBut,
    formProvinceUsaBut,
    formCityUsaBut,
    formAddressUsaBut,
    formPostalUsaBut,
    formSocialUsaBut,
    formTaxCodeUsaBut,

    formQuest1,
    formQuest2,
    formQuest3,
    formQuest4,
    isAgree,
  };
};

export default connect(mapStateToProps)(
  translate("translations")(FormQuestion)
);
