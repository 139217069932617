import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Portal } from "react-overlays";
import { range } from "lodash";
import { stringToDate } from "../../util";
import PerfectScrollBar from "react-perfect-scrollbar";
import TextMask from "react-text-mask";

const vnf_regex =
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

const CalendarContainer = ({ children }) => {
  const el = document.getElementById("calendar-portal");

  return <Portal container={el}>{children}</Portal>;
};

const RenderSelectDate = ({
  input,
  placeholder,
  className,
  startDate,
  minDate,
  maxDate,
  disabled,
  meta: { touched, error },
  onCalendarOpen,
  onCalendarClose,
  border,
}) => {
  const years = range(1950, new Date().getFullYear() + 40, 1);
  const months = [
    "Tháng 1",
    "Tháng 2",
    "Tháng 3",
    "Tháng 4",
    "Tháng 5",
    "Tháng 6",
    "Tháng 7",
    "Tháng 8",
    "Tháng 9",
    "Tháng 10",
    "Tháng 11",
    "Tháng 12",
  ];
  const [showSelect, setShowSelect] = React.useState(false);

  const listRef = React.useRef(null);
  const selectedYearRef = React.useRef(null);

  React.useEffect(() => {
    if (showSelect && selectedYearRef.current) {
      // Scroll into view the selected year
      selectedYearRef.current.scrollIntoView({
        behavior: "instant",
        block: "center",
      });
    }
  }, [showSelect]);

  function handleCalendarOpen() {
    // console.log('Calendar open')
    onCalendarOpen && onCalendarOpen();
    setShowSelect(false);
  }
  function handleCalendarClose() {
    // console.log('Calendar close')
    onCalendarClose && onCalendarClose();
    setShowSelect(false);
  }

  function _handleShow() {
    if (disabled) return;
    // inputRef && inputRef.current.focus();
    setShowSelect(!showSelect);
  }

  return (
    <div>
      <DatePicker
        onCalendarOpen={handleCalendarOpen}
        onCalendarClose={handleCalendarClose}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              className="middle none font-sans center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none  hover:opacity-75 active:opacity-[0.85] icon-white bg-transparent"
              style={{
                border: "none",
                position: "relative",
                left: "-92px",
              }}
            >
              {"<"}
            </button>
            <div>
              <div
                className="cursor-pointer text-skin-title text-right text-sm border-none block w-full pl-1.5 py-1 pr-0 focus:ring-0"
                value={months[date.getMonth()]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
                style={{
                  background: "var(--color-surface-soild-primary)",
                }}
                onClick={() => _handleShow()}
              >
                {months[date.getMonth()]}
              </div>
            </div>
            <span className="text-skin-title mx-1 mt-1">{","}</span>
            <div className="">
              <div
                className="text-skin-title text-right text-sm py-1 cursor-pointer"
                onClick={() => _handleShow()}
              >
                {date.getFullYear()}
              </div>
              <ul
                className={`rounded-3xl text-skin-title text-sm  w-100 bg-skin-sur-primary ${
                  showSelect ? "" : "hidden"
                }`}
                style={{
                  position: "absolute",
                  left: "0",
                  top: "64px",
                  padding: "4px 0 4px 6px",
                }}
                ref={listRef}
              >
                <PerfectScrollBar
                  className="grid grid-cols-5 gap-1 "
                  style={{
                    maxHeight: "235px",
                  }}
                >
                  {years.map((option) => (
                    <li
                      key={option}
                      className={`flex items-center justify-center text-sm cursor-pointer ${
                        date.getFullYear() === option
                          ? " font-semibold text-skin-green"
                          : "font-medium text-skin-body"
                      }`}
                      onClick={() => {
                        changeYear(option);
                        setShowSelect(false);
                      }}
                      style={{
                        height: "36px",
                      }}
                      ref={
                        date.getFullYear() === option ? selectedYearRef : null
                      }
                    >
                      {option}
                    </li>
                  ))}
                </PerfectScrollBar>
              </ul>
            </div>
            <button
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              className="middle none font-sans center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none  hover:opacity-75 active:opacity-[0.85] icon-white bg-transparent"
              style={{
                border: "none",
                position: "relative",
                right: "-92px",
              }}
            >
              {">"}
            </button>
          </div>
        )}
        className={
          "text-skin-title text-input-trading bg-skin-sur-secondary rounded-3xl relative disabled:bg-skin-natural-2 disabled:text-skin-subdued block input-date " +
          (touched && error
            ? "border border-skin-sell "
            : border
            ? "border-skin-weak "
            : "border-none ") +
          className
        }
        selected={
          input.value && vnf_regex.test(input?.value)
            ? stringToDate(input.value, "dmy") || stringToDate(startDate, "dmy")
            : input.onChange(null)
        }
        dateFormat="dd-MM-yyyy"
        onChange={(date) => {
          console.log("date", date);
          date
            ? input.onChange(moment(date).format("DD/MM/YYYY"))
            : input.onChange(null);
        }}
        selectsStart
        // startDate={stringToDate(startDate, 'dmy')}
        // endDate={stringToDate(endDate, 'dmy')}
        placeholderText={placeholder || "dd-mm-yyyy"}
        minDate={minDate || null}
        maxDate={maxDate || null}
        popperContainer={CalendarContainer}
        disabled={disabled}
      />
      {touched && error && (
        <div className="text-left text-sm text-skin-red">{error}</div>
      )}
    </div>
  );
};

export default RenderSelectDate;
