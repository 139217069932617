import ReactModal from "react-modal";
import { memo, useState, useEffect } from "react";
import { ReactComponent as IconModalWn } from "../../../assets/img/icon/ic-warning.svg";
import { Redirect, useHistory } from "react-router";

ReactModal.setAppElement("#root");

const styleLg = {
  content: {
    top: "50%",
    transform: "translateY(-50%)",
    bottom: "auto",
    left: "calc( 50% - 307px )",
    height: "auto",
    width: "614px",
    padding: "0",
    borderWidth: "0",
    overflow: "inherit",
    borderRadius: "24px",
    background: "transparent",
  },
};

const styleXs = {
  content: {
    top: "50%",
    transform: "translateY(-50%)",
    bottom: "auto",
    left: "calc( 50% - 179px )",
    height: "auto",
    width: "358px",
    padding: "0",
    borderWidth: "0",
    overflow: "inherit",
    borderRadius: "24px",
    background: "transparent",
  },
};

function ModalWarnResAcc(props) {
  const [dimensions, setDimensions] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setDimensions(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const { handleCloseModal, txtError } = props;

  return (
    <ReactModal
      isOpen={true}
      contentLabel="onRequestClose Example"
      shouldCloseOnOverlayClick={false}
      style={dimensions >= 768 ? styleLg : styleXs}
      className="relative"
    >
      <div
        className="flex flex-column gap-4 items-center justify-center rounded-3xl border border-skin-weak bg-skin-sur-primary"
        style={{
          padding: "0 24px",
        }}
      >
        <div
          className="flex gap-4"
          style={{
            padding: "16px 24px",
          }}
        >
          <div className="flex flex-column gap-4 items-center justify-center">
            <IconModalWn
              style={{
                width: "40px",
                height: "40px",
              }}
            />
            <span className="text-lg font-semibold text-skin-title">
              {txtError ||
                "Khuôn mặt không trùng khớp với CCCD, vui lòng thử lại"}
            </span>
          </div>
        </div>

        <div className="py-4 px-6 flex flex-row-reverse gap-2 items-center justify-center w-100">
          <button
            className=" w-100 btn-submit success"
            onClick={() => handleCloseModal()}
          >
            Xác thực lại
          </button>
          <button
            className="w-100 btn-close"
            onClick={() => handleCloseModal()}
          >
            Hủy
          </button>
        </div>
      </div>
    </ReactModal>
  );
}

export default memo(ModalWarnResAcc);
