import ReactModal from "react-modal";
import { ReactComponent as IconModalSc } from "../../../assets/img/icon/ic-modalsc.svg";
import { Redirect, useHistory } from "react-router";
import { memo, useState, useEffect } from "react";

ReactModal.setAppElement("#root");

const styleLg = {
  content: {
    top: "50%",
    transform: "translateY(-50%)",
    bottom: "auto",
    left: "calc( 50% - 210px )",
    height: "auto",
    width: "420px",
    padding: "0",
    borderWidth: "0",
    overflow: "inherit",
    borderRadius: "24px",
    background: "transparent",
  },
};

const styleXs = {
  content: {
    top: "50%",
    transform: "translateY(-50%)",
    bottom: "auto",
    left: "calc( 50% - 179px )",
    height: "auto",
    width: "358px",
    padding: "0",
    borderWidth: "0",
    overflow: "inherit",
    borderRadius: "24px",
    background: "transparent",
  },
};

function ModalSuccessResAcc(props) {
  const {} = props;
  const history = useHistory();
  const [dimensions, setDimensions] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setDimensions(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function handleClickNav() {
    const webTradding =
      "https://webtrade.upse.vn/#/price/bang-gia/vn30/mo-tk-to-bang-gia";
    window.open(webTradding, "_blank", "noopener,noreferrer");
  }

  const openGmailInbox = () => {
    const gmailInboxLink = "https://mail.google.com/mail/u/0/#inbox";
    window.open(gmailInboxLink, "_blank", "noopener,noreferrer");
  };

  return (
    <ReactModal
      isOpen={true}
      contentLabel="onRequestClose Example"
      shouldCloseOnOverlayClick={false}
      style={dimensions >= 768 ? styleLg : styleXs}
      className="relative"
    >
      <IconModalSc
        className="flex items-center justify-center w-100 relative "
        style={{
          top: "28px",
        }}
      />
      <div
        className="flex flex-column gap-4 items-center justify-center rounded-3xl border border-skin-weak bg-skin-sur-primary"
        style={{
          padding: "64px 16px 0px",
        }}
      >
        <span className="text-xl font-bold text-skin-title">Thành công</span>
        <span className="text-base font-normal text-center text-skin-body">
          Thông tin đăng nhập đã được gửi tới email bạn đã đăng ký, vui lòng
          kiểm tra hòm thư
        </span>
        <div
          className="pt-8 pb-6 flex flex-column gap-2 items-center justify-center w-100"
          style={{
            padding: "32px 0px 24px",
          }}
        >
          <button
            className="btn-submit w-100 success"
            onClick={() => handleClickNav()}
          >
            Đăng nhập
          </button>
          <button className="btn-close w-100" onClick={openGmailInbox}>
            Kiểm tra hòm thư
          </button>
        </div>
      </div>
    </ReactModal>
  );
}

export default memo(ModalSuccessResAcc);
