import React from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { makeGetDataAccount } from "../../../lib/selector";
import _ from "lodash";
import { removeAccent } from "../../../util";
import { ReactComponent as IconArrow } from "../../../assets/img/icon/ic_arrow.svg";
import { setDataAccount } from "../../client/actions";
import { translate } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

function FormCheckInforRes(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    handleNextSubStep,
    handlePreSubStep,
    dataAccount,
    orcData,
    openFormQuestion,
    t,
  } = props;

  const handlePreStep = () => {
    history.push("/open-account/register-service");
    handlePreSubStep();
  };

  const handleNextStep = () => {
    let _dataAcc = Object.assign({}, dataAccount);
    _dataAcc.REF_ID = uuidv4() + "";
    dispatch(setDataAccount(_dataAcc));
    handleNextSubStep();
  };

  return (
    <div>
      <form className=" flex flex-column gap-4 form-group">
        <div className="flex flex-column gap-r-6">
          <div
            className="bg-skin-sur-secondary border border-skin-weak rounded-xl grid grid-res-ser gap-2"
            style={{
              padding: "16px 24px",
            }}
          >
            <span className="truncate grid-res-41 text-sm font-semibold text-skin-title">
              {t("personal-info")}
            </span>
            <div className="flex flex-column gap-2">
              <span className="truncate text-xs font-normal text-skin-subdued">
                {t("full-name")}
              </span>
              <span className="truncate text-xs font-semibold text-skin-title">
                {dataAccount && dataAccount?.C_FULL_NAME}
              </span>
            </div>
            <div className="flex flex-column gap-2">
              <span className="truncate text-xs font-normal text-skin-subdued">
                {t("dob")}
              </span>
              <span className="truncate text-xs font-semibold text-skin-title">
                {dataAccount && dataAccount?.C_CUST_BIRTH_DAY}
              </span>
            </div>
            <div className="flex flex-column gap-2">
              <span className="truncate text-xs font-normal text-skin-subdued">
                {t("phone-number")}
              </span>
              <span className="truncate text-xs font-semibold text-skin-title">
                {dataAccount && dataAccount?.C_MOBILE}
              </span>
            </div>
            <span className="grid-res-41 border border-skin-weak border-dashed"></span>
            <div className="flex flex-column gap-2">
              <span className="truncate text-xs font-normal text-skin-subdued">
                {t("gender")}
              </span>
              <span className="truncate text-xs font-semibold text-skin-title">
                {dataAccount && dataAccount?.C_GENDER}
              </span>
            </div>

            <div className="flex flex-column gap-2">
              <span className="truncate text-xs font-normal text-skin-subdued">
                {t("nationality")}
              </span>
              <span className="truncate text-xs font-semibold text-skin-title">
                {orcData && orcData?.data?.nationality}
              </span>
            </div>
            <div className="flex flex-column gap-2">
              <span className="truncate text-xs font-normal text-skin-subdued">
                {t("email")}
              </span>
              <span className="truncate text-xs font-semibold text-skin-title">
                {dataAccount && dataAccount?.C_EMAIL}
              </span>
            </div>
            <span className="grid-res-41 border border-skin-weak border-dashed"></span>

            <div className="flex flex-column gap-2">
              <span className="truncate text-xs font-normal text-skin-subdued">
                {t("id-number")}
              </span>
              <span className="truncate text-xs font-semibold text-skin-title">
                {dataAccount && dataAccount?.C_CARD_ID}
              </span>
            </div>
            <div className="flex flex-column gap-2">
              <span className="truncate text-xs font-normal text-skin-subdued">
                {t("issue-date")}
              </span>
              <span className="truncate text-xs font-semibold text-skin-title">
                {dataAccount && dataAccount?.C_ISSUE_DATE}
              </span>
            </div>
            <div className="flex flex-column gap-2">
              <span className="truncate text-xs font-normal text-skin-subdued">
                {t("place-issued")}
              </span>
              <span className="truncate text-xs font-semibold text-skin-title ">
                {dataAccount && dataAccount?.C_ISSUE_PLACE}
              </span>
            </div>
            <span className="grid-res-41 border border-skin-weak border-dashed"></span>

            <div className="flex flex-column gap-2">
              <span className="truncate text-xs font-normal text-skin-subdued">
                {t("permanent-address")}
              </span>
              <span className="truncate text-xs font-semibold text-skin-title">
                {dataAccount && dataAccount?.C_ADDRESS}
              </span>
            </div>
            <div className="flex flex-column gap-2 ">
              <span className="truncate text-xs font-normal text-skin-subdued">
                {t("contact-address")}
              </span>
              <span className="truncate text-xs font-semibold text-skin-title">
                {dataAccount && dataAccount?.C_CONTACT_ADDRESS}
              </span>
            </div>
          </div>
          {dataAccount?.C_DKTK === "1" && (
            <div
              className="bg-skin-sur-secondary border border-skin-weak rounded-xl grid grid-res-ser gap-4"
              style={{
                padding: "16px 24px",
              }}
            >
              <span className="truncate grid-res-41 text-sm font-semibold text-skin-title">
                {t("beneficiary-account-number")}
              </span>
              <div className="flex flex-column gap-2">
                <span className="truncate text-xs font-normal text-skin-subdued">
                  {t("beneficiary-name")}
                </span>
                <span className="truncate text-xs font-semibold text-skin-title">
                  {dataAccount &&
                    dataAccount?.C_FULL_NAME &&
                    removeAccent(dataAccount?.C_FULL_NAME)}
                </span>
              </div>
              <div className="flex flex-column gap-2">
                <span className="truncate text-xs font-normal text-skin-subdued">
                  {t("account-number")}
                </span>
                <span className="truncate text-xs font-semibold text-skin-title">
                  {dataAccount && dataAccount?.C_BANK_ACCOUNT}
                </span>
              </div>
              <div className="flex flex-column gap-2">
                <span className="truncate text-xs font-normal text-skin-subdued">
                  {t("bank")}
                </span>
                <span className="truncate text-xs font-semibold text-skin-title">
                  {dataAccount && dataAccount?.C_BANK_NAME}
                </span>
              </div>
              <span className="grid-res-41 border border-skin-weak border-dashed"></span>

              <div className="flex flex-column gap-2">
                <span className="truncate text-xs font-normal text-skin-subdued">
                  {t("branch")}
                </span>
                <span className="truncate text-xs font-semibold text-skin-title">
                  {dataAccount && dataAccount?.C_BRANCH_NAME}
                </span>
              </div>
              <div className="flex flex-column gap-2">
                <span className="truncate text-xs font-normal text-skin-subdued">
                  {t("city-province")}
                </span>
                <span className="truncate text-xs font-semibold text-skin-title">
                  {dataAccount && dataAccount?.C_BANK_PROVINCE_NAME}
                </span>
              </div>
            </div>
          )}

          <div
            className="bg-skin-sur-secondary border border-skin-weak rounded-xl grid grid-res-ser gap-4"
            style={{
              padding: "16px 24px",
            }}
          >
            <span className="truncate grid-res-41 text-sm font-semibold text-skin-title">
              {t("registration-service")}
            </span>
            <div className="flex flex-column gap-2">
              <span className="truncate text-xs font-normal text-skin-subdued">
                {t("account-type")}
              </span>
              <span className="truncate text-xs font-semibold text-skin-title">
                {dataAccount && dataAccount?.C_ACCOUNT_TYPE === "1"
                  ? t("normal-margin-account")
                  : t("normal-account")}
              </span>
            </div>
            <div className="flex flex-column gap-2">
              <span className="truncate text-xs font-normal text-skin-subdued">
                {t("transaction-service-package")}
              </span>
              <span className="truncate text-xs font-semibold text-skin-title">
                {dataAccount && dataAccount?.C_SALE_ID
                  ? t("advisor")
                  : t("no-advisor")}
              </span>
            </div>
            <div className="flex flex-column gap-2">
              <span className="truncate text-xs font-normal text-skin-subdued">
                {t("sms-registration")}
              </span>
              <span className="truncate text-xs font-semibold text-skin-title">
                {dataAccount && dataAccount?.C_DKSMS === "1"
                  ? t("yes")
                  : t("no")}
              </span>
            </div>
            <span className="grid-res-41 border border-skin-weak border-dashed"></span>

            <div className="flex flex-column gap-2">
              <span className="truncate text-xs font-normal text-skin-subdued">
                {t("email-info-service")}
              </span>
              <span className="truncate text-xs font-semibold text-skin-title">
                {dataAccount && dataAccount?.C_GDE === "1" ? t("yes") : t("no")}
              </span>
            </div>
            <div className="flex flex-column gap-2">
              <span className="truncate text-xs font-normal text-skin-subdued">
                {t("online-transactions")}
              </span>
              <span className="truncate text-xs font-semibold text-skin-title">
                {dataAccount && dataAccount?.C_GDTT === "1"
                  ? t("yes")
                  : t("no")}
              </span>
            </div>
            <div className="flex flex-column gap-2">
              <span className="truncate text-xs font-normal text-skin-subdued">
                {t("phone-transactions")}
              </span>
              <span className="truncate text-xs font-semibold text-skin-title">
                {dataAccount && dataAccount?.C_GDTD === "1"
                  ? t("yes")
                  : t("no")}
              </span>
            </div>
            <span className="grid-res-41 border border-skin-weak border-dashed"></span>
            <div className="flex flex-column gap-2">
              <span className="truncate text-xs font-normal text-skin-subdued">
                {t("auto-sale-advance")}
              </span>
              <span className="truncate text-xs font-semibold text-skin-title">
                {dataAccount && dataAccount?.C_UTB === "1" ? t("yes") : t("no")}
              </span>
            </div>
          </div>
        </div>
        <div
          className="align-items-center gap-2 padding-3 rounded-3"
          style={{
            display: "grid",
            gridTemplateColumns: "auto 20px",
          }}
        >
          <div className="d-flex flex-column gap-2">
            <span
              className="text-sm font-medium text-skin-body d-flex flex-row align-items-center gap-2 cursor-pointer"
              onClick={() => openFormQuestion()}
              style={{
                width: "max-content",
              }}
            >
              {dataAccount?.QUEST_INVEST && (
                <div
                  className="rounded-circle"
                  style={{
                    width: "10px",
                    height: "10px",
                    background: "#00BF52",
                  }}
                ></div>
              )}
              {t("investment-experience-survey")}
            </span>
            <div className="border border-skin-weak"></div>
            <div
              className=" gap-2 align-items-center w-75"
              style={{
                display: "grid",
                gridTemplateColumns: "auto 95px",
              }}
            >
              <span
                className="d-flex flex-row text-sm font-medium text-skin-body gap-2 cursor-pointer"
                style={{
                  display: "grid",
                  gridTemplateColumns: "10px auto",
                }}
                onClick={() => openFormQuestion()}
              >
                {(dataAccount?.QUEST_CHECK_NOT_USA ||
                  dataAccount?.CHECK_USA ||
                  dataAccount?.CHECK_USA_BUT) && (
                  <div
                    className="rounded-circle"
                    style={{
                      minWidth: "10px",
                      height: "10px",
                      background: "#00BF52",
                    }}
                  ></div>
                )}
                {t("additional-info-fatca")}
              </span>
            </div>
          </div>
          <IconArrow
            style={{
              width: "20px",
              height: "20px",
              transform: "rotate(-90deg)",
            }}
            onClick={() => openFormQuestion()}
          />
        </div>
        <div
          className="flex flex-row-reverse justify-content-start gap-2 w-100"
          style={{
            paddingTop: "24px",
          }}
        >
          <button
            type="submit"
            className="success btn-submit "
            onClick={() => handleNextStep()}
          >
            {t("continue-step")}
          </button>
          <button className="btn-close " onClick={() => handlePreStep()}>
            {t("pre")}
          </button>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  const getDataAccount = makeGetDataAccount();

  return {
    dataAccount: getDataAccount(state),
  };
};

export default connect(mapStateToProps)(
  translate("translations")(FormCheckInforRes)
);
